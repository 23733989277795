import { HModules, HCF, HLink } from "@macashipo/mlib"
import MyUIOne from "./MyUI"
const ModuleSignalR = {
  _key: "signalR",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "signalR"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleSignalR, { MyUIOne })
  },
}

export default ModuleSignalR
