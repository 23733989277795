import { HModules, HCF, HLink, HUtils } from "@macashipo/mlib"
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook"
import React from "react"
import { fnListConfigApp } from "src/helpers/fnList"

const ModuleGTag = {
  _key: "gtag",
  _fnList: {},
  _config: {
    GTMKey: "GTMKeyPageGlobal",
    GTMIdDev: "GTM-W3HNM37",
  },
  getMyKey: () => {
    return "gtag"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleGTag, {})
    console.warn("init module gtag")
  },
}

export default ModuleGTag

const ProviderGTAG = props => {
  let _gtagID = HCF.getCF(ModuleGTag._config.GTMKey)
  if (_gtagID == null && fnListConfigApp.getIsDevMode()) {
    _gtagID = ModuleGTag._config.GTMIdDev
  }
  console.warn("render providergtag", _gtagID, props)
  if (_gtagID) {
    return <GTMProvider state={{ id: _gtagID }}>{props.children}</GTMProvider>
  }
  return <React.Fragment>{props.children}</React.Fragment>
}
export { ProviderGTAG }
