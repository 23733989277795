import { HUtils } from "@macashipo/mlib"
import throttle from "lodash/throttle"

const ListObserver = {
  sample: {
    allTrigger: {},
  },
  window_resize: {
    allTrigger: {},
  },
}
const HObserver = {
  create(key) {
    if (ListObserver[key] == null) {
      ListObserver[key] = {
        allTrigger: {},
      }
    } else {
      console.warn("HObserver exist:", key)
    }
  },
  register(key, type, fn) {
    if (ListObserver[key] == null) {
      HObserver.create(key)
    }
    if (type && ListObserver[key] != null && ListObserver[key].allTrigger) {
      ListObserver[key].allTrigger[type] = fn
    }
  },
  trigger(key, params = []) {
    // console.warn("trigger:", key, params, ListObserver)
    if (ListObserver[key] != null && ListObserver[key].allTrigger) {
      for (let k of Object.keys(ListObserver[key].allTrigger)) {
        HUtils.runFuntion(ListObserver[key].allTrigger, k, [...params])
      }
    } else {
      console.warn("HObserver not exist:", key)
    }
  },
}

const onResize = throttle(ev => {
  // console.log("onResize", ev)
  HObserver.trigger("window_resize", [window.innerWidth, window.innerHeight])
}, 300)

window.addEventListener("resize", onResize)
console.log("HObserver init")
export default HObserver
