import { HUtils } from "@macashipo/mlib"
import LodashGet from "lodash/get"
import LodashDebounce from "lodash/debounce"
import { logOne } from "./log"
import HConstant from "./constant"

export const isFunction = (v: any) => {
  return typeof v === "function"
}
export const isArray = (v: any) => {
  return Array.isArray(v)
}
export const isString = (v: any) => {
  return typeof v === "string"
}
export const isDateValue = (v: any) => {
  return new Date(v).getTime() > 0
}
export const mergeObjIfExist = (srcObj, mergeObj) => {
  if (srcObj && mergeObj) {
    let _keys = Object.keys(mergeObj)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        srcObj[k] = mergeObj[k]
      }
    }
  }
}
export const runFuntion = (fnList: any, name, args: any[] = []) => {
  if (fnList && isFunction(fnList[name])) {
    fnList[name].apply(null, args)
  } else {
    logOne("runFuntion failed:", fnList, name)
  }
}
export const safeInvoke = (fn, args: any[] = []) => {
  if (fn) {
    return fn.apply(null, args)
  }
}
export const initMCore = (key, obj) => {
  if (window) {
    if (window["MCore"] == null) {
      window["MCore"] = {}
    }
    window["MCore"][key] = obj
  }
}

export const addArray2Array = (arr, moreArr) => {
  if (arr && moreArr) {
    // arr = arr.concat(moreArr);//ko dung concat duoc, chua  biet tai sao
    arr.push(...moreArr)
  }
}

export const objHasKey = (obj, key) => {
  if (obj && key && obj.hasOwnProperty) {
    return obj.hasOwnProperty(key)
  }
}
// export const combineArray = (array1, array2) => {
//   return [...array1, ...array2]
// }

// export const combineArray = (array1, array2) => {
//   return [].concat(array1, array2)
// }

export const isLocalDev = () => {
  if (window.location.href.indexOf("localhost:") > -1) {
    return true
  } else if (window.location.href.indexOf("192.168") > -1) {
    return true
  }
  if (localStorage.getItem(HConstant.Dev.Key._DEBUG_) === "true") {
    return true
  }
  return false
}

export const onKeyDownEnter = (ev, fn) => {
  if (ev.key === "Enter") {
    fn()
  }
}

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const debounce = (fn, time, opts) => {
  return LodashDebounce(fn, time, opts)
}
export const get = LodashGet

export const getTextWithData = HUtils.getTextWithData

export const checkConditionShow = (value: any, conditionValue) => {
  // console.warn("checkConditionShow:", value, conditionValue)
  let _canShow = true
  if (isArray(conditionValue)) {
    _canShow = false
    for (let i = 0; i < conditionValue.length; i++) {
      let _check = checkConditionShow(value, conditionValue[i])
      if (_check === true) {
        //chi can 1 cai true thi se true
        return true
      }
    }
  } else {
    if (isArray(value)) {
      if (value.indexOf(conditionValue) === -1) {
        return false
      }
    } else if (value !== conditionValue) {
      return false
    }
  }
  return _canShow
}
export const checkConditionShowWithOperator = (
  value: any,
  conditionValue,
  operator,
  format: any
) => {
  console.warn("checkConditionShow:", value, conditionValue)
  let _valueNeedCmp = value
  let _canShow = false
  if (_valueNeedCmp !== null && _valueNeedCmp !== undefined) {
    if (format?.type) {
      switch (format.type) {
        case "year":
          if (
            format?.cmpWith === "now" &&
            new Date().getFullYear() > new Date(_valueNeedCmp).getFullYear()
          ) {
            _valueNeedCmp =
              new Date().getFullYear() - new Date(_valueNeedCmp).getFullYear()
          }
          break

        default:
          break
      }
    }
    switch (operator) {
      case ">=":
        if (_valueNeedCmp >= conditionValue) {
          _canShow = true
        }
        break
      case ">":
        if (_valueNeedCmp > conditionValue) {
          _canShow = true
        }
        break
      case "!=":
        if (_valueNeedCmp !== conditionValue) {
          _canShow = true
        }
        break
      case "=":
        if (_valueNeedCmp === conditionValue) {
          _canShow = true
        }
        break
      case "<":
        if (_valueNeedCmp < conditionValue) {
          _canShow = true
        }
        break
      case "<=":
        if (_valueNeedCmp <= conditionValue) {
          _canShow = true
        }
        break
      default:
        break
    }
  }
  return _canShow
}

export const isOnScreen = function (element) {
  if (element && element.offsetTop != null) {
    var curTop = element.offsetTop
    var heightOfWindow = window.innerHeight
    var offsetYOfWindow = window.scrollY
    if (curTop > offsetYOfWindow && curTop < heightOfWindow + offsetYOfWindow) {
      return true
    }
  }
  return false
}

export const xorCrypt = function (str: string = "", key: number = 7) {
  var output = ""
  if (!key) {
    key = 6
  }
  for (var i = 0; i < str.length; ++i) {
    output += String.fromCharCode(key ^ str.charCodeAt(i))
  }
  return output
}
export const isNotEmpty = (v: any) => {
  return v !== null && v !== undefined && v !== "" ? true : false
}
export const isNotEmptyIncludeZero = (v: any) => {
  return isNotEmpty(v) && v !== 0 ? true : false
}
export const squareBracket = {
  extract: function (s) {
    var matcher: any = (function () {
      var CSS_INTEGER = "[-\\+]?\\d+%?"
      var CSS_NUMBER = "[-\\+]?\\d*\\.\\d+%?"
      var CSS_UNIT = "(?:" + CSS_NUMBER + ")|(?:" + CSS_INTEGER + ")"
      var PERMISSIVE_MATCH3 =
        "[\\s|\\(]+(" +
        CSS_UNIT +
        ")[,|\\s]+(" +
        CSS_UNIT +
        ")[,|\\s]+(" +
        CSS_UNIT +
        ")\\s*\\)?"
      var PERMISSIVE_MATCH4 =
        "[\\s|\\(]+(" +
        CSS_UNIT +
        ")[,|\\s]+(" +
        CSS_UNIT +
        ")[,|\\s]+(" +
        CSS_UNIT +
        ")[,|\\s]+(" +
        CSS_UNIT +
        ")\\s*\\)?"

      return {
        CSS_UNIT: new RegExp(CSS_UNIT),
        rgb: new RegExp("rgb" + PERMISSIVE_MATCH3),
        rgba: new RegExp("rgba" + PERMISSIVE_MATCH4),
        hsl: new RegExp("hsl" + PERMISSIVE_MATCH3),
        hsla: new RegExp("hsla" + PERMISSIVE_MATCH4),
        hsv: new RegExp("hsv" + PERMISSIVE_MATCH3),
        hsva: new RegExp("hsva" + PERMISSIVE_MATCH4),
        hex3: /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
        hex6: /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
        hex4: /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
        hex8: /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
        extSquareBracket: /\[(.[^\]]*)\]/gm,
      }
    })()
    var _result: any = []
    if (s != null) {
      var _m
      while ((_m = matcher.extSquareBracket.exec(s)) !== null) {
        if (_m.index === matcher.lastIndex) {
          matcher.lastIndex++
        }
        if (_m.length > 1) {
          _result.push(_m[1])
        }
      }
    }
    return _result
  },
  replace: function (s, obj, { emptyWhenNull }: any = {}) {
    var extract = squareBracket.extract(s)
    var newS = s
    if (extract != null) {
      for (var _item of extract) {
        if (obj[_item] != null) {
          let rg = new RegExp(`\\[${_item}\\]`, "g")
          newS = newS.replace(rg, obj[_item])
        } else if (emptyWhenNull == true && obj[_item] == null) {
          ////Bo sung emptyWhenNull, de ko muon [] trong chuoi sau khi replace
          let rg = new RegExp(`\\[${_item}\\]`, "g")
          newS = newS.replace(rg, "")
        }
      }
    }
    return newS
  },
}

export const getVideoCover = (file, seekTo = 0.0) => {
  console.log("getting video cover for file: ", file)
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video")
    videoPlayer.setAttribute("src", URL.createObjectURL(file))
    videoPlayer.load()
    videoPlayer.addEventListener("error", ex => {
      reject("error when loading video file")
    })
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.")
        return
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo
      }, 200)
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo)
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas")
        canvas.width = videoPlayer.videoWidth
        canvas.height = videoPlayer.videoHeight
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d")
        if (ctx) {
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            blob => {
              resolve(blob)
            },
            "image/jpeg",
            0.75 /* quality */
          )
        }
      })
    })
  })
}
window["_xorCrypt"] = xorCrypt
