import { HModules, CSuppense, HUtils } from "@macashipo/mlib"
import MyPageOne from "./MyPage"
const ModuleAppDemo = {
  _key: "appdemo",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "appdemo"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleAppDemo, {
      MyPageOne,
    })
    CSuppense.LazyLoading = () => {
      return (
        <div className="mui-lazy-loading ani-fadeIn">
          <i className="fa fa fa-circle-o-notch fa-spin" />
        </div>
      )
    }
    HUtils.runFuntion(HConfig, "addPublicRoutes", [
      [
        {
          Id: "test_input_range",
          UIUrl: "/test/test_input_range",
          UIType: "test_input_range",
        },
        {
          Id: "test_camera",
          UIUrl: "/test/test_camera",
          UIType: "test_camera",
        },
      ],
    ])
    HUtils.runFuntion(HConfig, "addPrivateRoutes", [
      [
        {
          Id: "css_2react_style",
          UIUrl: "/2react-style",
          UIType: "css_2react_style",
        },
        {
          Id: "api_document",
          UIUrl: "/demo/api_document",
          UIType: "api_document",
        },
        {
          Id: "demo_buttonlist",
          UIUrl: "/demo/buttonlist",
          UIType: "demo_buttonlist",
        },
        {
          Id: "demo_formcontrol",
          UIUrl: "/demo/formcontrol",
          UIType: "demo_formcontrol",
        },
        {
          Id: "demo_form",
          UIUrl: "/demo/form",
          UIType: "demo_form",
        },
        {
          Id: "demo_tablecell",
          UIUrl: "/demo/tablecell",
          UIType: "demo_tablecell",
        },
        {
          Id: "demo_tabletotal",
          UIUrl: "/demo/tabletotal",
          UIType: "demo_tabletotal",
        },
        {
          Id: "demo_tablefilter",
          UIUrl: "/demo/tablefilter",
          UIType: "demo_tablefilter",
        },
        {
          Id: "demo_myui",
          UIUrl: "/demo/myui",
          UIType: "demo_myui",
        },
        {
          Id: "demo_libs",
          UIUrl: "/demo/libs",
          UIType: "demo_libs",
        },
        {
          Id: "demo_dashboard_one",
          UIUrl: "/demo/demo_dashboard_one",
          UIType: "demo_dashboard_one",
        },
        {
          Id: "demo_chart_chartjs",
          UIUrl: "/demo/demo_chartjs",
          UIType: "demo_chartjs",
        },
        {
          Id: "demo_chart_ggchart",
          UIUrl: "/demo/demo_ggchart",
          UIType: "demo_ggchart",
        },
        {
          Id: "demo_table",
          UIUrl: "/demo/table",
          UIType: "demo_table",
        },
        {
          Id: "demo_table",
          UIUrl: "/adoc/:name",
          UIType: "demo_docs",
        },
        {
          Id: "demo_survey",
          UIUrl: "/demo/survey",
          UIType: "survey",
        },
      ],
    ])
  },
}

export default ModuleAppDemo
