import React from "react"
import { MyLayoutHelpers } from "@macashipo/mlib"

const MUI_grid_autofit = React.lazy(() => import("./Types/grid_autofit"))
const MUI_grid = React.lazy(() => import("./Types/grid"))
const MUI_title_content = React.lazy(() => import("./Types/title_content"))
const MUI_flex = React.lazy(() => import("./Types/flex"))
const MUI_div = React.lazy(() => import("./Types/div"))
const List = {
  title_content: MUI_title_content,
  grid: MUI_grid,
  grid_autofit: MUI_grid_autofit,
  flex: MUI_flex,
  div: MUI_div,
}
const MyUIOne = {
  init() {
    MyLayoutHelpers.addTypesFromListWithoutLazy(List)
  },
}

export default MyUIOne
