import React from "react"
import { useHistory } from "react-router-dom"
import { fnListConfigApp } from "src/helpers/fnList"
import styled from "styled-components"
// import { useGoogleLogin } from "src/libs/ReactUseLoginGoogle"
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import i18next from "i18next"
import axios from "axios"
const Wrap = styled.div`
  background: #e0513a;
  color: white;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    background: #d53b22;
  }
  & > .fa {
    margin-right: 5px;
  }
`
const LoginSocial = props => {
  const history = useHistory()
  const { loginSocial } = props
  // const { signIn, googleUser } = useGoogleLogin({
  //   clientId: fnListConfigApp.getGoogleClientID(),
  // })
  const signIn = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: tokenResponse => {
      console.warn("tokenResponse:", tokenResponse)
      axios
        .get(
          `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`,
          {}
        )
        .then(res => {
          console.log(res.data)
          if (res.data) {
            const { email, name, picture } = res.data
            if (tokenResponse) {
              loginSocial({
                SocialId: "google",
                SocialToken: tokenResponse.access_token,
                SocialUsername: email,
              }).then(r => {
                console.warn("after login social", r)
              })
            } else {
              console.warn("login failed")
            }
          }
        })
    },
    onError: e => {
      console.warn("Login error:", e)
    },
  })
  return (
    <Wrap
      onClick={ev => {
        signIn()
        // .then(v => {
        //   console.warn("result:", v, loginSocial)
        //   if (v) {
        //     loginSocial({
        //       SocialId: "google",
        //       SocialToken: v.accessToken,
        //       SocialUsername: v.profileObj.email,
        //     }).then(r => {
        //       console.warn("after login social", r)
        //     })
        //   } else {
        //     console.warn("login failed")
        //   }
        // })
        // .catch(() => {
        //   console.warn("login failed")
        // })
      }}
    >
      <i className="fa fa-google" />
      {i18next.t("login.Sign in with Google")}
    </Wrap>
  )
}

const LoginSocialProvider = props => {
  return (
    <GoogleOAuthProvider clientId={fnListConfigApp.getGoogleClientID()}>
      <LoginSocial {...props} />
    </GoogleOAuthProvider>
  )
}

export default LoginSocialProvider
