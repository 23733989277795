import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"
import MUIGrid from "./Types/grid"
const MUIATHeader = React.lazy(() => import("./Types/admintable_header"))
const MUIATChart = React.lazy(() => import("./Types/admintable_chart"))
const MUIATHeaderDetail = React.lazy(() =>
  import("./Types/admintable_header_detail")
)
const MUIATHeaderMini = React.lazy(() =>
  import("./Types/admintable_header_mini")
)
const MUIATHeaderButtons = React.lazy(() =>
  import("./Types/admintable_header_buttons")
)
const MUIATFilter = React.lazy(() => import("./Types/admintable_filter"))
const MUIATFilterDetail = React.lazy(() =>
  import("./Types/admintable_filter_detail")
)
const MUIATTable = React.lazy(() => import("./Types/admintable_table"))
const MUIATTableDetails = React.lazy(() =>
  import("./Types/admintable_table").then(module => ({
    default: module.MUIATTableDetails,
  }))
)
const MUIATTableList = React.lazy(() => import("./Types/admintable_table_list"))
const MUIATTableListCard = React.lazy(() =>
  import("./Types/admintable_table_list_card")
)
const MUIATTableGridCard = React.lazy(() =>
  import("./Types/admintable_table_grid_card")
)
const MUIATTableCalendar = React.lazy(() =>
  import("./Types/admintable_table_calendar")
)
const MUISelectBox = React.lazy(() => import("./Types/select_box"))
const MUISelectBoxSearch = React.lazy(() => import("./Types/select_box_search"))
const MUI_select_box_search_paging = React.lazy(() =>
  import("./Types/select_box_search_paging")
)
const MUISelectBoxSearchV2 = React.lazy(() =>
  import("./Types/select_box_search_v2")
)
const MUISelectCheckBoxSearch = React.lazy(() =>
  import("./Types/selectcheck_box_search")
)
const MUIMsgBoxConfirm = React.lazy(() => import("./Types/msgbox_confirm"))
const MUIMsgBoxError = React.lazy(() => import("./Types/msgbox_error"))
const MUIAnimate = React.lazy(() => import("./Types/animate"))
const MUIFileManager = React.lazy(() => import("./Types/file_manager"))
const MUIFileManagerItem = React.lazy(() => import("./Types/file_manager_item"))
const MUIArrTags = React.lazy(() => import("./Types/arr_tags"))
const MUIActionMenu = React.lazy(() => import("./Types/actions_menu"))
const MUILayoutSquare = React.lazy(() => import("./Types/layout_square"))
const MUISearchBox = React.lazy(() => import("./Types/search_box"))
const MUIATFilterPopover = React.lazy(() =>
  import("./Types/admintable_filter_popover")
)
const MUIWrapCellOnModal = React.lazy(() => import("./Types/_wrapCellOnModal"))
const MUIWrapEditRowInCard = React.lazy(() =>
  import("./Types/_wrapEditRowInCard")
)
const MUI_avatar = React.lazy(() => import("./Types/avatar"))
const MUI_html_canedit = React.lazy(() => import("./Types/html_canedit"))
const MUI_Api_Client = React.lazy(() => import("./Types/api_client"))
const MUI_Select_Box_Search_Virtualized = React.lazy(() =>
  import("./Types/select_box_search_virtualized")
)
const MUI_Block_Mobile = React.lazy(() => import("./Types/block_mobile"))
const MUI_Obj_Editor = React.lazy(() => import("./Types/obj_editor"))
const MUI_Json_Viewer = React.lazy(() => import("./Types/json_viewer"))
const MUI_timeline = React.lazy(() => import("./Types/timeline"))
const MUI_list_check = React.lazy(() => import("./Types/list_check"))
const MUI_list_check_api = React.lazy(() => import("./Types/list_check_api"))
const MUI_table_check = React.lazy(() => import("./Types/table_check"))

const MUI_diffview = React.lazy(() => import("./Types/diffview"))
const MUI_modal_request_diffview = React.lazy(() =>
  import("./Types/modal_request_diffview")
)
const MUI_search_box_withdata = React.lazy(() =>
  import("./Types/search_box_withdata")
)
const MUI_btn_take_photo = React.lazy(() => import("./Types/btn_take_photo"))
const MUI_scanqrcode = React.lazy(() => import("./Types/scanqrcode"))
const MUI_admintable_flow = React.lazy(() => import("./Types/admintable_flow"))
const MUI_select_month = React.lazy(() => import("./Types/select_month"))
const MUI_print_bill = React.lazy(() => import("./Types/print_bill"))
const MUI_map_goong = React.lazy(() => import("./Types/map_goong"))

//Custom
const MUI_masu_addproduct = React.lazy(() => import("./Custom/masu_addproduct"))
const MUI_masu_cell_attribute = React.lazy(() =>
  import("./Custom/masu_cell_attribute")
)
const MUI_masu_cell_editproduct = React.lazy(() =>
  import("./Custom/masu_cell_editproduct")
)
const List = {
  _wrapEditRowInCard: MUIWrapEditRowInCard,
  _wrapCellOnModal: MUIWrapCellOnModal,
  //Custom
  masu_addproduct: MUI_masu_addproduct,
  masu_cell_attribute: MUI_masu_cell_attribute,
  masu_cell_editproduct: MUI_masu_cell_editproduct,
  //Types
  map_goong: MUI_map_goong,
  print_bill: MUI_print_bill,
  select_month: MUI_select_month,
  html_canedit: MUI_html_canedit,
  admintable_flow: MUI_admintable_flow,
  scanqrcode: MUI_scanqrcode,
  btn_take_photo: MUI_btn_take_photo,
  search_box_withdata: MUI_search_box_withdata,
  avatar: MUI_avatar,
  json_viewer: MUI_Json_Viewer,
  timeline: MUI_timeline,
  admintable_filter_popover: MUIATFilterPopover,
  admintable_chart: MUIATChart,
  search_box: MUISearchBox,
  layout_square: MUILayoutSquare,
  actions_menu: MUIActionMenu,
  arr_tags: MUIArrTags,
  selectcheck_box_search: MUISelectCheckBoxSearch,
  admintable_header: MUIATHeader,
  admintable_header_detail: MUIATHeaderDetail,
  admintable_header_mini: MUIATHeaderMini,
  admintable_header_buttons: MUIATHeaderButtons,
  admintable_filter: MUIATFilter,
  admintable_filter_detail: MUIATFilterDetail,
  admintable_table: MUIATTable,
  admintable_table_details: MUIATTableDetails,
  admintable_table_list: MUIATTableList,
  admintable_table_list_card: MUIATTableListCard,
  admintable_table_grid_card: MUIATTableGridCard,
  admintable_table_calendar: MUIATTableCalendar,
  select_box: MUISelectBox,
  select_box_search: MUISelectBoxSearch,
  select_box_search_paging: MUI_select_box_search_paging,
  select_box_search_v2: MUISelectBoxSearchV2,
  msgbox_confirm: MUIMsgBoxConfirm,
  msgbox_error: MUIMsgBoxError,
  animate: MUIAnimate,
  file_manager: MUIFileManager,
  file_manager_item: MUIFileManagerItem,
  api_client: MUI_Api_Client,
  select_box_search_virtualized: MUI_Select_Box_Search_Virtualized,
  block_mobile: MUI_Block_Mobile,
  obj_editor: MUI_Obj_Editor,
  list_check: MUI_list_check,
  list_check_api: MUI_list_check_api,
  table_check: MUI_table_check,
  diffview: MUI_diffview,
  modal_request_diffview: MUI_modal_request_diffview,
}

const ListNoLazy = {
  grid: MUIGrid,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
    MyUIHelpers.addTypesFromListWithoutLazy(ListNoLazy)
  },
}

export default MyUIOne
