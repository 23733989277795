const HelperMore = {
  getSourceData: (props = {}) => {
    const options =
      (props.fnList &&
        props.fnList.getSourceData &&
        props.fnList.getSourceData({ withEmpty: false })) ||
      []
    return options
  },
  getMoreFromProps: (props = {}) => {
    if (props.fnList && props.fnList.getMore) {
      return props.fnList.getMore() || {}
    }
    return {}
  },
  getClassCol: (more = {}) => {
    if (more.classCol) {
      return more.classCol
    }
    return ""
  },
  getIsCheckListYesNo: (more = {}) => {
    // console.warn("getIsCheckListYesNo:",more)
    if (more.isCheckListYesNo === true) {
      return true
    }
    return false
  },
  getIsSelectCheck: (more = {}) => {
    if (more.isSelectCheck === true) {
      return true
    }
    return false
  },
  getIsNumeral: (more = {}) => {
    if (more.isNumeral === true) {
      return true
    }
    return false
  },
  getInputType: (more = {}, defaultType = "text") => {
    if (more.inputType) {
      return more.inputType
    }
    return defaultType
  },
  getSuffix: (more = {}) => {
    if (more && more.suffix) {
      return more.suffix
    }
    return null
  },
  getPrefix: (more = {}) => {
    if (more && more.prefix) {
      return more.prefix
    }
    return null
  },
  getNoDecimal: (more = {}) => {
    if (more && more.noDecimal === true) {
      return true
    }
    return false
  },
  getOptionNegative: (more = {}, opts = {}) => {
    let _obj = opts.default || {
      Text: "NO",
      Value: false,
    }
    if (more.hasOwnProperty("negativeValue")) {
      _obj.Value = more.negativeValue
    }
    if (more.hasOwnProperty("negativeText")) {
      _obj.Text = more.negativeText
    }
    return _obj
  },
  getOptionPositive: (more = {}, opts = {}) => {
    let _obj = opts.default || {
      Text: "YES",
      Value: true,
    }
    if (more.hasOwnProperty("positiveValue")) {
      _obj.Value = more.positiveValue
    }
    if (more.hasOwnProperty("positiveText")) {
      _obj.Text = more.positiveText
    }
    return _obj
  },
  getOptionAll: (more = {}, opts = {}) => {
    let _obj = opts.default || {
      Text: "All",
      Value: null,
    }
    if (more.hasOwnProperty("allValue")) {
      _obj.Value = more.allValue
    }
    if (more.hasOwnProperty("allText")) {
      _obj.Text = more.allText
    }
    return _obj
  },
  getPropsValues: (
    props = {},
    {
      defaultValueWhenNull,
      keyValue = "value",
      keyDefaultValue = "defaultValue",
    } = {}
  ) => {
    let _propsValues = {}
    if (props.hasOwnProperty("value")) {
      //control value from parent
      _propsValues[keyValue] = props.value
      if (_propsValues[keyValue] === null && defaultValueWhenNull != null) {
        _propsValues[keyValue] = defaultValueWhenNull
      }
    }
    if (props.hasOwnProperty("defaultValue")) {
      //without control value
      _propsValues[keyDefaultValue] = props.defaultValue
    }
    return _propsValues
  },
}

export default HelperMore
