import { createStore, applyMiddleware, compose, combineReducers } from "redux"
import thunk from "redux-thunk"
import rootReducer from "src/reducer/reducer"
import { initialReducer } from "src/reducer/reducer"
import reducerRegistry from "../reducer/reducerRegistry"
const composeEnhancers =
  typeof window === "object" &&
  process.env.NODE_ENV === "development" &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose
const enhancer = composeEnhancers(applyMiddleware(thunk))
// export const store = createStore(rootReducer, enhancer)

const combine = (reducers, initialState = {}) => {
  Object.keys(initialState).forEach(item => {
    reducers[item] = (state = initialState[item]) => state
  })
  return combineReducers(reducers)
}
reducerRegistry.initReducer(initialReducer)
reducerRegistry.setChangeListener(reducers => {
  console.log("reducerRegistry setChangeListener", reducers)
  // @ts-ignore
  store.replaceReducer(combine(reducers))
})

// const reducer = combine(reducerRegistry.getReducers())
// export const store = createStore(reducer, enhancer)

export const store = createStore(
  combineReducers(reducerRegistry.getReducers()),
  enhancer
)
