import React from "react"
import {
  MyLib,
  MyPageHelpers,
  HCF,
  HUtils,
  HColor,
  HFakeData,
  MyLayout,
} from "@macashipo/mlib"
import styled from "styled-components"

class Demo extends React.Component {
  render() {
    return (
      <div>
        <MyLayout type="title_content" title="Demo 3 col fr" des="col: 3">
          <MyLayout type="grid" col={3} gap="5px">
            {new Array(10).fill(0).map((v, i) => {
              return (
                <div
                  key={i}
                  style={{
                    border: "1px solid #ccc",
                    borderTop: `4px solid ${HFakeData.getHextColor()}`,
                    background: "#fcfcfc",
                    padding: "1rem",
                  }}
                >
                  {HFakeData.getLoremWithRandom(10)}
                </div>
              )
            })}
          </MyLayout>
        </MyLayout>
      </div>
    )
  }
}

//add page dev
Demo.initPageDev = () => {
  MyPageHelpers.addTypesFromListLazy({
    dev_mylayout_grid: Demo,
  })
  HUtils.runFuntion(HCF.CF, "addPublicRoutes", [
    [
      {
        Id: "dev_mylayout_grid",
        UIUrl: "/dev/mylib/dev_mylayout_grid",
        UIType: "dev_mylayout_grid",
      },
    ],
  ])
}

export default Demo
