import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"
import MUI_html from "./Types/html"
import MUI_data_obj from "./Types/data_obj"
import MUI_data_array from "./Types/data_array"
import MUI_data_tag from "./Types/data_tag"
import MUI_data_span_autoresize from "./Types/data_span_autoresize"

import MUI_state_loading from "./Types/state_loading"
import MUI_state_error from "./Types/state_error"
import MUI_state_wrap from "./Types/state_wrap"

const ListNoLazy = {
  html: MUI_html,
  data_obj: MUI_data_obj,
  data_array: MUI_data_array,
  data_tag: MUI_data_tag,
  data_span_autoresize: MUI_data_span_autoresize,
  state_error: MUI_state_error,
  state_loading: MUI_state_loading,
  state_wrap: MUI_state_wrap,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListWithoutLazy(ListNoLazy)
  },
}

export default MyUIOne
