import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import { ToastContainer } from "react-toastify"
import "src/assets/scss/index.scss"
import App from "./App/App"
import AppConfig from "./App/AppConfig"
// eslint-disable-next-line
import * as HCore from "./core/helpers/index"
import * as serviceWorker from "./serviceWorker"
import { store } from "./store/store"
import MyModal from "src/core/components/MyModal/MyModal"
import MyLightbox from "src/core/components/MyModal/MyLightbox"
import MyModalWrap from "src/core/components/MyModal/MyModalWrap"
import "react-toastify/dist/ReactToastify.css"
import "font-awesome/css/font-awesome.min.css"
import * as MLib from "@macashipo/mlib"
import { KConfigApp } from "src/helpers/K"
import { ProviderGTAG } from "src/modules/gtag"

//Assign MLib
window["MLib"] = MLib

/** Init Config App */
HCore.HConfig.initConfigApp(AppConfig)

//Stop show overlay error when error in mode development
// if (process.env.NODE_ENV === "development") {
//   import("react-error-overlay").then(m => {
//     m.stopReportingRuntimeErrors()
//   })
// }

if (window["_MissingConfig"] === true) {
  ReactDOM.render(
    // <React.StrictMode>
    <div></div>,
    // </React.StrictMode>
    document.getElementById("root")
  )
} else {
  ReactDOM.render(
    // <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <ProviderGTAG>
        <Provider store={store}>
          <App />
          {/* <MyModal type={MyModal.Helpers.types.component} />
        <MyModal type={MyModal.Helpers.types.myform} />
        <MyModal type={MyModal.Helpers.types.mypage} />
        <MyModal type={MyModal.Helpers.types.myui} />
        <MyModal type={MyModal.Helpers.types.mydialog} />
        <MyModal type={MyModal.Helpers.types.cellinmodal} />
        <MyLightbox type={MyModal.Helpers.types.lightbox} /> */}
          <MyModalWrap />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            style={{ zIndex: 1999 }}
          />
          {MLib.HCF.getCF(KConfigApp.hasSignalR) === true && (
            <MLib.MyUI type="notifySignalR" />
          )}
        </Provider>
      </ProviderGTAG>
    </I18nextProvider>,
    // </React.StrictMode>
    document.getElementById("root")
  )
}

//an vung first loading sau khi render reactjs
var dom_firstLoading = document.getElementById("first_loading")
if (dom_firstLoading) {
  dom_firstLoading.style.visibility = "hidden"
}

//test
// console.warn(
//   "MLib.HCF.getCF(KConfigApp.hasSignalR)",
//   MLib.HCF.getCF(KConfigApp.hasSignalR)
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
