import React from "react"
import { MyLib, MyPageHelpers, HCF, HUtils, HColor } from "@macashipo/mlib"
import { MyModal } from "src/core/components"
import styled from "styled-components"

class Dev_Demo extends React.Component {
  render() {
    return (
      <div>
        <div
          onClick={() => {
            MyModal.showFromComponent(
              props => {
                return (
                  <div
                    onClick={() => {
                      MyModal.showFromComponent(
                        props => {
                          return <div>C</div>
                        },
                        {
                          nextLevel: true,
                          title: "Next Level",
                        }
                      )
                    }}
                  >
                    Next Level
                  </div>
                )
              },
              {
                dragable: true,
                title: "Show with dragable",
              }
            )
          }}
        >
          Show with dragable
        </div>
      </div>
    )
  }
}

//add page dev
Dev_Demo.initPageDev = () => {
  MyPageHelpers.addTypesFromListLazy({
    dev_mymodal: Dev_Demo,
  })
  HUtils.runFuntion(HCF.CF, "addPublicRoutes", [
    [
      {
        Id: "dev_mymodal",
        UIUrl: "/dev/mymodal",
        UIType: "dev_mymodal",
      },
    ],
  ])
}

export default Dev_Demo
