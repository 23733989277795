import styled from "styled-components"

export const Title = styled.h5`
  margin: 2rem 0 1.5rem;
  font-weight: 600;
`
export const InputGroup = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  margin-bottom: 1.5rem;
`
export const InputPrefix = styled.div`
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  font-size: 14px;
  font-weight: 600;
  color: #6f7782;
  background-color: #eff6ff;
  border-radius: 10px 0px 0px 10px;
`
export const InputSuffix = styled.div`
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  font-size: 14px;
  font-weight: 600;
  color: #6f7782;
  cursor: pointer;
  background-color: #fff;
  margin-left: -10px;
  border: 1px solid #e8f0fe;
  border-left: 0px;
  border-radius: 0px 10px 10px 0px;
`
export const Input = styled.input`
  border: 1px solid #eff6ff;
  font-size: 14px;
  background-color: #fff;
  height: 50px;
  margin-bottom: 0 !important;
  border-radius: 0px 10px 10px 0px;
  &:hover {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
    background-color: #fff;
    border: 1px solid #eff6ff;
  }
`
export const Form = styled.form`
  border-radius: 5px;
  text-align: left !important;
  // box-shadow: none !important;
  background-color: #fff;
`
export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`
export const Logo = styled.img`
  max-height: 60px;
  max-width: 100%;
  object-fit: contain;
`

export const SubmitSpan = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  transform: translate3d(-0.2rem, 0, 0);
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
`
export const Submit = styled.button`
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  height: 50px;
  &:hover,
  &:focus {
    color: #fff;
    box-shadow: none;
  }
  &:hover ${SubmitSpan} {
    transform: translate3d(0.2rem, 0, 0);
  }
`
export const ErrorMessage = styled.div`
  font-size: 13px;
`
export const LoginContainer = styled.div``
