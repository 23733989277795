import { MyLibHelpers } from "@macashipo/mlib"
import React from "react"

const MyLib_react_google_places_autocomplete = React.lazy(() =>
  import("./Types/react_google_places_autocomplete")
)
const MyLib_react_google_places_autocomplete_custom = React.lazy(() =>
  import("./Types/react_google_places_autocomplete_custom")
)
const List = {
  react_google_places_autocomplete: MyLib_react_google_places_autocomplete,
  react_google_places_autocomplete_custom: MyLib_react_google_places_autocomplete_custom
}
const MyLibOne = {
  init() {
    MyLibHelpers.addTypesFromListLazy(List)
  },
}

export default MyLibOne
