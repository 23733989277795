import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"

const MWContent = React.lazy(() => import("./Types/WContent"))
const MUnsupportedMedia = React.lazy(() => import("./Types/UnsupportedMedia"))
const List = {
  wcontent: MWContent,
  unsupportedmedia: MUnsupportedMedia,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
  },
}

export default MyUIOne
