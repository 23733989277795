import React from "react"
import SelectCheck from "./select_check"
import HelperMore from "./_helperMore"
import { HOptions } from "src/core/helpers"
const BaseSelect = props => {
  const more = HelperMore.getMoreFromProps(props)
  const isSelectCheck = HelperMore.getIsSelectCheck(more)
  if (isSelectCheck === true) {
    return SelectCheck(props)
  }
  const options =
    (props.fnList &&
      props.fnList.getSourceData &&
      props.fnList.getSourceData({ withEmpty: true })) ||
    []
  return (
    <select
      value={props.value}
      onChange={ev => {
        props.onChange &&
          props.onChange(ev.currentTarget.value, {
            item: HOptions.getOptionByValue(options, ev.currentTarget.value),
          })
      }}
      className={props.className}
      style={props.style}
      placeholder={props.placeholder}
      {...props.attrs}
    >
      {options &&
        options.length > 0 &&
        Array.isArray(options) &&
        options.map((v, i) => {
          return (
            <option key={i} value={v.Value}>
              {v.Text}
            </option>
          )
        })}
    </select>
  )
}

export default BaseSelect
