export const LOGOUT = "app/LOGOUT"
export const FETCH_FS_PENDING = "FETCH_FS_PENDING"
export const FETCH_FS_SUCCESS = "FETCH_FS_SUCCESS"
export const FETCH_FS_ERROR = "FETCH_FS_ERROR"

export const SIDE_NAV_CLOSE = "app/SIDE_NAV_CLOSE"
export const SIDE_NAV_UPDATE = "app/SIDE_NAV_UPDATE"
export const SIDE_NAV_REPLACE = "app/SIDE_NAV_REPLACE"

export const HEADER_TITLE_UPDATE = "app/HEADER_TITLE_UPDATE"
