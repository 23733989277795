import { HFormat } from "@macashipo/mlib"
import merge from "lodash/merge"
import { fnListMyForm, fnListMyFormControl } from "src/helpers/fnList"
import { KMC } from "./More"
import { FlowGetConfigControlFromServer } from "./Form"
import * as AHOptions from "src/core/helpers/options"
import { HUtils } from "src/core/components/MyUI/UIContentsTypes/_helpers"

export const KMore = KMC
export const KMoreOfFormControl = KMore

export const FlowGetConfigFormControlFromV1 = ({
  configFormControl,
  config = {},
} = {}) => {
  //for v5
  if (configFormControl) {
    return configFormControl
  }
  let _propsControl
  if (config.Props) {
    _propsControl = config.Props
  }
  let _more = {}
  if (config.Hint) {
    _more.hint = config.Hint
  }
  if (config.Source || config.SourceField) {
    _more.optionKey = config.Source || config.SourceField
  }
  if (_propsControl) {
    _more = {
      ..._more,
      ..._propsControl,
    }
  }

  let _configFormControl = {
    label: config.Label,
    fieldName: config.FieldName,
    type: config.Type,
    more: _more,
  }
  return _configFormControl
}

export const FlowGetMore = ({ configFormControl = {}, ...rest } = {}) => {
  let _more = {}
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  if (_configFormControl && _configFormControl.more) {
    return _configFormControl.more
  }
  return _more
}

export const FlowMergeWithCustomProps = ({ configFormControl, custom }) => {
  if (
    configFormControl &&
    custom &&
    configFormControl["_mergeCustom"] !== true
  ) {
    if (configFormControl.more && custom.more) {
      console.warn("more:", custom.more)
      for (let k of Object.keys(custom.more)) {
        if (!configFormControl.more.hasOwnProperty(k)) {
          configFormControl.more[k] = custom.more[k]
          console.warn("merge custom:", k, configFormControl)
        } else if (
          typeof configFormControl.more[k] == "object" &&
          typeof custom.more[k] == "object"
        ) {
          configFormControl.more[k] = merge(
            configFormControl.more[k],
            custom.more[k]
          )
          console.warn(
            "merge custom 2:",
            k,
            configFormControl.more[k],
            custom.more[k]
          )
        }
      }
    }
    configFormControl["_mergeCustom"] = true
  }
}

export const FlowGetMoreOfKey = ({
  configFormControl = {},
  key,
  defaultValue,
  ...rest
} = {}) => {
  let _more = FlowGetMore({ configFormControl, ...rest })
  if (_more && _more.hasOwnProperty(key)) {
    // console.warn("FlowGetMoreOfKey:", key, _more)
    return _more[key]
  }
  return defaultValue
}

export const FlowGetCanEdit = ({ configFormControl, ...rest } = {}) => {
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  let _more = FlowGetMore({ configFormControl: _configFormControl, ...rest })
  if (_more[KMore.disabled] == true) {
    return false
  }
  return true
}

export const FlowGetDisabledOfControl = ({ more, fnList } = {}) => {
  if (more && more.disabled) {
    return true
  }
  let _disabledForm = HUtils.runFnList(fnList, "fnGetDisabledForm")
  if (_disabledForm) {
    return true
  }
  return false
}

export const FlowGetCanShow = ({
  configFormControl,
  fnList,
  more,
  ...rest
} = {}) => {
  let _canShow = true
  let _more =
    more || FlowGetMore({ configFormControl: configFormControl, ...rest })
  // console.warn("FlowGetCanShow:", _more, configFormControl)
  let _currentValues = null
  if (_more) {
    if (
      _more.hasOwnProperty(KMore.conditionHide) ||
      _more.hasOwnProperty(KMore.hideCondition)
    ) {
      _currentValues =
        _currentValues || fnListMyForm.getCurrentValues(fnList) || {}
      let _conditionHide =
        _more[KMore.conditionHide] || _more[KMore.hideCondition]
      let _willHideByCondition = HFormat.format(_currentValues, {
        type: "hideCondition",
        configHideCondition: _conditionHide,
      })
      console.log("_willHideByCondition:", _willHideByCondition, _conditionHide)
      if (_willHideByCondition === true) {
        _canShow = false
      }
    }
    if (_more.hasOwnProperty(KMore.conditionShow)) {
      let _conditionShow = _more[KMore.conditionShow] || {}
      _currentValues =
        _currentValues || fnListMyForm.getCurrentValues(fnList) || {}
      if (_conditionShow.isCondition) {
        // let _valueRelative = HUtils.get(
        //   _currentValuesOfForm,
        //   _conditionShow.fieldRelative
        // )
        // _canShow = checkConditionShowWithOperator(
        //   _valueRelative,
        //   _conditionShow.whenValue,
        //   _conditionShow.operator,
        //   _conditionShow.formatDataToCmp
        // )
        // _canShow = HFormat.format(_currentValues,{..._conditionShow})
      } else if (_conditionShow.conditionFormat) {
        _canShow = HFormat.format(_currentValues, {
          ..._conditionShow.conditionFormat,
          fnList: {
            getValue: configFormat => {
              if (configFormat && configFormat.valueOfField) {
                return _currentValues[configFormat.valueOfField]
              }
            },
          },
        })
      } else {
        for (let k of Object.keys(_conditionShow)) {
          let _fieldName = k
          let _valueExpected = _conditionShow[_fieldName]
          let _valueOfField = _currentValues[_fieldName]
          if (_valueExpected !== _valueOfField) {
            return false
          }
        }
      }
    }
  }

  return _canShow
}

export const FlowGetPlaceHolder = ({ configFormControl, ...rest } = {}) => {
  let _more = FlowGetMore({ configFormControl: configFormControl, ...rest })
  if (_more[KMore.placeholder]) {
    return _more[KMore.placeholder]
  }
  if (rest.defaultValue) {
    return rest.defaultValue
  }
  return ""
}

export const FlowGetSameChange = ({
  configFormControl,
  optionItem,
  ...rest
} = {}) => {
  let _more = FlowGetMore({ configFormControl: configFormControl, ...rest })
  console.log("optionItem:", optionItem)
  if (
    optionItem != null &&
    _more[KMore.sameChange] &&
    typeof _more[KMore.sameChange] === "object"
  ) {
    let _obj = {}
    for (let k of Object.keys(_more[KMore.sameChange])) {
      //chi update khi co field trong optionItem
      if (optionItem.hasOwnProperty(_more[KMore.sameChange][k])) {
        _obj[k] = optionItem[_more[KMore.sameChange][k]]
      }
    }
    return _obj
  }
  return null
}

export const FlowGetDefaultValues = ({
  configFormControl,
  fnList,
  ...rest
} = {}) => {
  let _defaultValues = fnListMyForm.getDefaultValues(fnList)
  // console.warn("FlowGetDefaultValues",_defaultValues)
  return _defaultValues
}

export const FlowGetDefaultValueOfFieldName = ({
  configFormControl,
  fieldName,
  defaultValue,
  ...rest
} = {}) => {
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  let _fieldName = fieldName || _configFormControl.fieldName
  let _defaultValues = FlowGetDefaultValues({ configFormControl, ...rest })
  if (_defaultValues && _defaultValues.hasOwnProperty(_fieldName)) {
    return _defaultValues[_fieldName]
  }
  return defaultValue != null ? defaultValue : null
}

export const FlowUpdateValueForm = ({
  configFormControl,
  fnList,
  fieldName,
  newValue,
  fnForceUpdateCell,
  ...rest
} = {}) => {
  let _configFormControl = FlowGetConfigFormControlFromV1({
    configFormControl,
    ...rest,
  })
  let _fieldName = fieldName || _configFormControl.fieldName
  console.warn("FlowUpdateValueForm", newValue, fnList)
  if (fnList) {
    //v1,v3
    if (fnList.onUpdateValueOfField) {
      fnList.onUpdateValueOfField({
        fieldName: _fieldName,
        newValue: newValue,
      })
    }
  }
}

export const FlowUpdateRelativeControls = ({
  objRelatives,
  objEffectedBy,
  configFormControl,
  fieldName,
  more,
  ...rest
} = {}) => {
  console.warn("FlowUpdateRelativeControls:", fieldName, more)
  let _fieldName = fieldName || configFormControl.fieldName || ""
  if (_fieldName && objRelatives && objEffectedBy) {
    let _more = more || FlowGetMore({ configFormControl, ...rest })
    if (_more) {
      //update relatives
      if (_more[KMore.relativeTo] && _more[KMore.relativeTo].length > 0) {
        if (objRelatives[_fieldName] === undefined) {
          objRelatives[_fieldName] = []
        }
        //update objRelatives
        objRelatives[_fieldName] = objRelatives[_fieldName].concat(
          _more[KMore.relativeTo]
        )
      }

      //update relatives from more.relativeFrom
      if (
        _more[KMore.relativeFrom] &&
        Array.isArray(_more[KMore.relativeFrom]) &&
        _more[KMore.relativeFrom].length > 0
      ) {
        for (let k of _more[KMore.relativeFrom]) {
          let _fFrom = k
          if (objRelatives[_fFrom] === undefined) {
            objRelatives[_fFrom] = []
          }
          if (
            Array.isArray(objRelatives[_fFrom]) &&
            objRelatives[_fFrom].indexOf(_fieldName) == -1
          ) {
            objRelatives[_fFrom].push(_fieldName)
          }
        }
      }

      //update relative with conditionshow
      if (_more && _more[KMore.conditionShow]) {
        let _keys = Object.keys(_more[KMore.conditionShow])
        if (!_keys.includes("isCondition")) {
          for (let k of _keys) {
            if (objRelatives[k] === undefined) {
              objRelatives[k] = []
            }
            if (objRelatives[k].indexOf(_fieldName) === -1) {
              objRelatives[k].push(_fieldName)
            }
          }
        } else {
          // Khi dieu kien show của 1 control la 1 dieu kien khong phai 1 gia tri cu the
          const conditionShow = _more[KMore.conditionShow]
          const fieldRelative = conditionShow?.fieldRelative
          // Danh cho truong hop phu thuoc 1 control trong 1 group
          if (fieldRelative.indexOf(".") > -1) {
            //Chi lay field cua cha vi ben trong thay doi thi se thay doi luon ca group va se update control phu thuoc
            let _fieldParent = fieldRelative.split(".")?.[0]
            if (objRelatives[_fieldParent] === undefined) {
              objRelatives[_fieldParent] = []
            }
            if (objRelatives[_fieldParent].indexOf(_fieldName) === -1) {
              objRelatives[_fieldParent].push(_fieldName)
            }
          } else {
            if (objRelatives[fieldRelative] === undefined) {
              objRelatives[fieldRelative] = []
            }
            if (objRelatives[fieldRelative].indexOf(_fieldName) === -1) {
              objRelatives[fieldRelative].push(_fieldName)
            }
          }
        }
      }

      //update effected
      if (_more[KMore.effectedBy] && _more[KMore.effectedBy].length > 0) {
        for (let k of _more[KMore.effectedBy]) {
          if (objEffectedBy[k] === undefined) {
            objEffectedBy[k] = []
          }
          if (objEffectedBy[k].indexOf(_fieldName) === -1) {
            objEffectedBy[k].push(_fieldName)
          }
        }
      }
    }
  }
  //for child type layout
  if (more && more["items"] && Array.isArray(more["items"])) {
    for (let k of more["items"]) {
      let _configChild = FlowGetConfigControlFromServer(k)
      FlowUpdateRelativeControls({
        objRelatives,
        objEffectedBy,
        configFormControl: _configChild,
        fieldName: _configChild.fieldName,
        more: _configChild.more,
      })
    }
  }
}

export const FlowFnListFromExtControl = {
  keys: {
    resetFormControl: "resetFormControl",
    forceUpdate: "forceUpdate",
    updateValueEffect: "updateValueEffect",
    updateValue: "updateValue",
  },
  isHasFn: ({ fnListFromExtControl, fieldName, fnName } = {}) => {
    if (
      fnListFromExtControl &&
      fieldName &&
      fnListFromExtControl[fieldName] &&
      fnListFromExtControl[fieldName][fnName]
    ) {
      return true
    }
    return false
  },
  forceUpdate: ({ fnListFromExtControl, fieldName } = {}) => {
    console.warn(
      "ExtControl forceUpdate:",
      fieldName,
      fnListFromExtControl[fieldName]
    )
    if (
      FlowFnListFromExtControl.isHasFn({
        fnListFromExtControl,
        fieldName,
        fnName: FlowFnListFromExtControl.keys.forceUpdate,
      })
    ) {
      fnListFromExtControl[fieldName].forceUpdate()
    }
  },
  updateValueEffect: ({ fnListFromExtControl, fieldName } = {}) => {
    console.warn(
      "ExtControl updateValueEffect:",
      fieldName,
      fnListFromExtControl[fieldName]
    )
    if (
      FlowFnListFromExtControl.isHasFn({
        fnListFromExtControl,
        fieldName,
        fnName: FlowFnListFromExtControl.keys.updateValueEffect,
      })
    ) {
      fnListFromExtControl[fieldName].updateValueEffect()
    }
  },
  updateValue: ({ fnListFromExtControl, fieldName, newValue } = {}) => {
    console.warn(
      "ExtControl updateValue:",
      fieldName,
      fnListFromExtControl[fieldName]
    )
    if (
      FlowFnListFromExtControl.isHasFn({
        fnListFromExtControl,
        fieldName,
        fnName: FlowFnListFromExtControl.keys.updateValue,
      })
    ) {
      fnListFromExtControl[fieldName].updateValue(newValue)
    }
  },
}

export const FlowFCGetDefaultValueControlSelect = ({
  cfc,
  isReset,
  isMulti,
  sourceData,
  charSplit,
} = {}) => {
  if (cfc) {
    let _valueControl = cfc.valueControl
    if (isReset) {
      _valueControl = cfc.defaultValues[cfc.fieldName] || ""
    }
    //truong hop 0 hoac rong van tinh
    if (_valueControl != null) {
      if (isMulti && _valueControl.indexOf(charSplit || ",") > -1) {
        let _splitValue = _valueControl.split(charSplit || ",")
        return _splitValue.map((v, i) => {
          let _option = AHOptions.getOptionByValue(
            sourceData || fnListMyFormControl.getSourceData(cfc.fnList),
            v
          )
          if (_option) {
            return _option
          }
          return {
            Value: v,
            Text: v,
          }
        })
      } else {
        let _option = AHOptions.getOptionByValue(
          sourceData || fnListMyFormControl.getSourceData(cfc.fnList),
          _valueControl
        )
        if (_option) {
          return _option
        }
        if (_valueControl != "") {
          //tranh truong hop value = ""
          return {
            Value: _valueControl,
            Text: _valueControl,
          }
        }
      }
    }
  }
  return null
}

export const FlowFCGetSelectedControlSelect = ({
  valueSelect,
  itemOption,
} = {}) => {
  if (valueSelect && itemOption) {
    if (Array.isArray(valueSelect)) {
      for (let i = 0; i < valueSelect.length; i++) {
        if (valueSelect[i] && valueSelect[i].Value == itemOption.Value) {
          return true
        }
      }
    } else if (valueSelect.Value == itemOption.Value) {
      return true
    }
  }
  return false
}

export const FlowFCUpdateValueControlSelect = ({
  cfc,
  valueSelect,
  itemOption,
  checked,
  value,
  isMulti,
  charSplit,
  setValueControl,
} = {}) => {
  console.warn(
    "FlowFCUpdateValueControlSelect:",
    isMulti,
    checked,
    itemOption,
    valueSelect
  )
  if (cfc && setValueControl) {
    if (isMulti == true) {
      let _valueSelect = valueSelect || []
      let _charSplit = charSplit || ","
      if (checked) {
        _valueSelect.push(itemOption)
      } else {
        let _index = null
        for (let i = 0; i < _valueSelect.length; i++) {
          if (_valueSelect[i].Value == itemOption.Value) {
            _index = i
            break
          }
        }
        if (_index >= 0) {
          _valueSelect.splice(_index, 1)
        }
      }
      setValueControl(_valueSelect)
      cfc.updateValueControl(
        _valueSelect
          .map((v, i) => {
            return v.Value
          })
          .join(_charSplit)
      )
    } else {
      if (checked) {
        setValueControl(itemOption)
        cfc.updateValueControl(itemOption.Value)
      } else {
        setValueControl(null)
        cfc.updateValueControl("")
      }
    }
  }
}
