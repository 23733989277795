import React from "react"
import { MyLib, MyPageHelpers, HCF, HUtils, HColor } from "@macashipo/mlib"
import styled from "styled-components"

class ML_RR extends React.Component {
  render() {
    return (
      <div>
        <MyLib type="reactrater" total={5} rating={2} />
      </div>
    )
  }
}

//add page dev
ML_RR.initPageDev = () => {
  MyPageHelpers.addTypesFromListLazy({
    dev_mylib_reactrater: ML_RR,
  })
  HUtils.runFuntion(HCF.CF, "addPublicRoutes", [
    [
      {
        Id: "dev_mylib_reactrater",
        UIUrl: "/dev/mylib/dev_mylib_reactrater",
        UIType: "dev_mylib_reactrater",
      },
    ],
  ])
}

export default ML_RR
