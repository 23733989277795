import React from "react"
import { HUtils } from "@macashipo/mlib"
import BaseNumeral from "./numeral"
import HelperMore from "./_helperMore"
const BaseText = props => {
  const more = HUtils.runFuntion(props.fnList, "getMore", [])
  const isNumeral = HelperMore.getIsNumeral(more)
  if (isNumeral === true) {
    return BaseNumeral(props)
  }
  return (
    <input
      type={HelperMore.getInputType(more, props.inputType || "text")}
      // value={props.value}//hide because show on ui
      {...HelperMore.getPropsValues(props, { defaultValueWhenNull: "" })}
      onChange={ev => {
        props.onChange && props.onChange(ev.currentTarget.value)
      }}
      className={props.className}
      style={props.style}
      // onInvalid={e => {
      //   // e.target.setCustomValidity("No no no no")//set custom valid default
      //   //disabled check required default
      //   // e.preventDefault()
      // }}
      {...props.attrs}
      // autoComplete="new-password"
    />
  )
}

export default BaseText
