import React from "react"
import { MyLib, MyPageHelpers, HCF, HUtils, HColor } from "@macashipo/mlib"
import styled from "styled-components"

const WrapItem = styled.div`
  background: #cecece;
  padding: 1rem;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

class Item1 extends React.Component {
  render() {
    let _bg =
      "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")
    return (
      <WrapItem style={{ background: _bg }}>Item {this.props.index}</WrapItem>
    )
  }
}
class ML_RMC extends React.Component {
  render() {
    return (
      <div>
        <MyLib type="reactmulticarousel">
          {new Array(5).fill(0).map((v, i) => {
            return <Item1 key={i} index={i} />
          })}
        </MyLib>
      </div>
    )
  }
}

//add page dev
ML_RMC.initPageDev = () => {
  MyPageHelpers.addTypesFromListLazy({
    dev_mylib_reactmulticatousel: ML_RMC,
  })
  HUtils.runFuntion(HCF.CF, "addPublicRoutes", [
    [
      {
        Id: "dev_mylib_reactmulticatousel",
        UIUrl: "/dev/mylib/dev_mylib_reactmulticatousel",
        UIType: "dev_mylib_reactmulticatousel",
      },
    ],
  ])
}

export default ML_RMC
