import { HModules, CSuppense, HUtils } from "@macashipo/mlib"
import MyPageOne from "./MyPage"
import MyUIOne from "./MyUI"
import MyButtonOne from "./MyButton"
import MyLayoutOne from "./MyLayout"
import MyTableCellOne from "./MyTableCell"
import MyTableHeaderOne from "./MyTableHeader"
import MyFormControlOne from "./MyFormControl"
import MyBaseOne from "./MyBase"
import MyTableFilterControlOne from "./MyTableFilterControl"
import MyLibOne from "./MyLib"
import MyChartOne from "./MyChart"
import MyCardOne from "./MyCard"
import CategoryScreen from "./boot/categoryscreen"
import ProjectScreen from "./boot/projectscreen"
const ModuleAdminPanel = {
  _key: "adminpanel",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "adminpanel"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleAdminPanel, {
      MyBaseOne,
      MyUIOne,
      MyPageOne,
      MyLayoutOne,
      MyButtonOne,
      MyTableCellOne,
      MyFormControlOne,
      MyTableFilterControlOne,
      MyLibOne,
      MyChartOne,
      MyCardOne,
      MyTableHeaderOne,
    })
    CategoryScreen.init(HConfig)
    ProjectScreen.init(HConfig)
    CSuppense.LazyLoading = () => {
      return (
        <div className="mui-lazy-loading ani-fadeIn">
          <i className="fa fa fa-circle-o-notch fa-spin" />
        </div>
      )
    }
    HUtils.runFuntion(HConfig, "addPrivateRoutes", [
      [
        {
          Id: "myprofile",
          UIUrl: "/my-profile",
          UIType: "myprofile",
        },
      ],
    ])
  },
}

export default ModuleAdminPanel
