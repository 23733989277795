import { HUtils as MHUtils, HRegex } from "@macashipo/mlib"
import Tooltip from "src/core/components/Others/Tooltip"
import Popover from "src/core/components/Others/Popover"
import { squareBracket } from "src/core/helpers/utils"
const HUtils = {
  Obj: {
    get: (obj, key) => {
      return MHUtils.get(obj, key)
    },
  },
  SquareBracket: {
    replace: (string, data, opts) => {
      squareBracket.replace(string, data)
      // HRegex.re
    },
  },
  runFnList: (fnList, name, args) => {
    return MHUtils.runFuntion(fnList, name, args)
  },
}
const HConstant = {
  UIFnCore: {
    //fnFrom MyUI Core
    myData: "myData", //get data from this.props.data
    myChange: "myChange", //call function from onChange
    myBlur: "myBlur", //call function from onBlur
  },
  UIOnClick: {
    key: "onCl",
  },
  UIOnChange: {
    key: "onC",
    updateField: "updateField", //update field
  },
  UIOnBlur: {
    key: "onB",
    updateField: "updateField", //update field
  },
  UIFnPage: {
    //fn from MyPage
    fnRequestUpdate: "fnRequestUpdate", //fnRequestUpdate(row, fieldName, cellValue, opts)
    fnReloadList: "fnReloadList", //load lai list
    fnReloadTable: "fnReloadTable", //old: tuong tu fnReloadList
    fnReloadOptionsAndList: "fnReloadOptionsAndList",
    fnGetExpandList: "fnGetExpandList",
    fnForceUpdateTable: "fnForceUpdateTable", //forceupdate table, chi moi co ben category
    fnHeaderChangeExpand: "fnHeaderChangeExpand",
    fnGetCanEditOfField: "fnGetCanEditOfField",
    fnGetCanShowOfField: "fnGetCanShowOfField",
    fnGetCurrentProject: "fnGetCurrentProject", //get Current Project tu tren xuong
    fnGetCurrentProjectId: "fnGetCurrentProjectId", //get Current Project Id tu tren xuong, ko xai nua
    fnGetOptions: "fnGetOptions", //get Options cua page
    fnGetConfigFromScreenConfig: "fnGetConfigFromScreenConfig",
    fnGetListExtraData: "fnGetListExtraData", //get extradata
    fnGetExtraDataTable: "fnGetExtraDataTable", // tuong tu fnGetListExtraData
    fnGetListData: "fnGetListData", //get list data
    fnGetDataTable: "fnGetDataTable", //tuong tu get list data
    fnGetListObj: "fnGetListObj", //get list obj
  },
}
export { HUtils, HConstant, Tooltip, Popover }
