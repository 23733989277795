import RMC from "./Dev_MyLib_ReactMultiCarousel"
import RR from "./Dev_MyLib_ReactRater"
const MyLibsAll = {
  RMC,
  RR,
}
const DevMyLibs = {
  init: () => {
    console.log("init dev mylibs")
    for (let k of Object.keys(MyLibsAll)) {
      let _lib = MyLibsAll[k]
      if (_lib && _lib.initPageDev) {
        _lib.initPageDev()
      }
    }
  },
}

export default DevMyLibs
