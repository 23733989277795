import React from "react"
import {
  MyLib,
  MyPageHelpers,
  HCF,
  HUtils,
  HColor,
  MyLoading,
} from "@macashipo/mlib"
import { MyModal } from "src/core/components"
import styled from "styled-components"

const WrapItem = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
`
class Dev_Demo extends React.Component {
  render() {
    return (
      <div>
        <WrapItem className="card">
          <div>
            <MyLoading type="" />
          </div>
        </WrapItem>
        <WrapItem className="card">
          <div>
            <MyLoading type="arc" />
          </div>
        </WrapItem>
        <WrapItem className="card">
          <div style={{ height: 40 }}>
            <MyLoading type="threedotsvertical" />
          </div>
        </WrapItem>
        <WrapItem className="card">
          <div>
            <MyLoading type="lds_ellipsis" />
          </div>
        </WrapItem>
      </div>
    )
  }
}

//add page dev
Dev_Demo.initPageDev = () => {
  MyPageHelpers.addTypesFromListLazy({
    dev_myloading: Dev_Demo,
  })
  HUtils.runFuntion(HCF.CF, "addPublicRoutes", [
    [
      {
        Id: "dev_myloading",
        UIUrl: "/dev/myloading",
        UIType: "dev_myloading",
      },
    ],
  ])
}

export default Dev_Demo
