import { HIs, HLink, HUtils, MyUI } from "@macashipo/mlib"
import { KMTC } from "./More"
import i18next from "i18next"
//ham can bo sung tu mlib

export const addItemToArrayOrCreateNewArray = (currArr = [], newItem) => {
  let _arr = ["empty"]
  if (currArr) {
    if (HIs.isArray(currArr)) {
      if (currArr.length > 0) {
        _arr = currArr
      }
    } else {
      _arr = [currArr]
    }
  }
  // console.warn("addItemToArrayOrCreateNewArray",currArr,newItem)
  return [..._arr, newItem]
}

//Utils.mergeRecursive
export const mergeRecursive = (obj1, obj2) => {
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor == Object) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p])
      } else {
        obj1[p] = obj2[p]
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p]
    }
  }
  return obj1
}

//HUtils.getDataBySort
export const getDataBySort = (
  data,
  sortByField,
  sortType = "asc",
  sortDataType = "string"
) => {
  if (sortByField && data && data.length > 0) {
    let _sortType = sortType
    let _sortDataType = sortDataType
    if (sortType !== "asc") {
      _sortType = "desc"
    }
    if (sortDataType !== "string") {
      _sortDataType = "number"
    }
    return data.sort((a, b) => {
      if (a && b) {
        let _itemA = a[sortByField]
        let _itemB = b[sortByField]
        if (_sortDataType == "number") {
          let _compare = _itemA - _itemB
          return _compare * (_sortType == "desc" ? -1 : 1)
        } else {
          let _compare = 0
          if (_itemA > _itemB) {
            _compare = _sortType == "desc" ? -1 : 1
          } else if (_itemA < _itemB) {
            _compare = _sortType == "desc" ? 1 : -1
          }
          return _compare
        }
      }
      return false
    })
  }
  return data
}

//HUtils.PrintHtml
export const FlowPrintHtml = (htmlString = "", { autoPrint } = {}) => {
  window.onbeforeprint = function () {
    console.log("Printing prepare...")
  }
  window.onafterprint = function () {
    console.log("Printing completed...")
  }
  let _idPrintfGloabl = "printf_global"
  let _idPrintfGloablIframe = "printf_global_iframe"
  let _eContain = document.getElementById(_idPrintfGloabl)
  console.log("FlowPrintHtml:", _eContain)
  if (_eContain == null) {
    const el = document.createElement("div")
    el.setAttribute("id", _idPrintfGloabl)
    el.setAttribute("style", "display: none;")
    document.body.appendChild(el)
    _eContain = document.getElementById(_idPrintfGloabl)
  }
  if (_eContain) {
    _eContain.innerHTML = `<iframe id="printf_global_iframe" name="printf_global_iframe" scrolling='no'></iframe>`
  }
  //insert htmlString
  let _eIframe = document.getElementById(_idPrintfGloablIframe)
  if (_eIframe) {
    _eIframe.contentDocument.write(htmlString)
    window.frames[_idPrintfGloablIframe].focus()
    let _height = _eIframe.contentWindow.document.body.scrollHeight
    _eIframe.style.height = `${_height}px`
    if (autoPrint !== false) {
      setTimeout(() => {
        // if(document && document.body && document.body.style && document.documentElement && document.documentElement.clientWidth){
        //   document.body.style.width = `${document.documentElement.clientWidth}px`;
        // }//fix print change columns size

        if (window.frames[_idPrintfGloablIframe]) {
          if (window.frames[_idPrintfGloablIframe].document.execCommand) {
            window.frames[_idPrintfGloablIframe].document.execCommand(
              "print",
              false,
              null
            ) //chay duoc voi Coccoc
          } else {
            window.frames[_idPrintfGloablIframe].print() //sau khi gen iframe thì tự động in
          }
        } else {
          console.error("not found frames printf", window.frames)
        }
      }, 250)
    }
  }
}

//valueSelected can list string value with , or array value
//HOptions.getArrayOptionSelectedFromOptions
export const FlowConvertSelectedMultiToArrayOptions = (
  valueSelected = "",
  sourceData = [],
  { arraySplitChar } = {}
) => {
  let _arrSelected = []
  if (Array.isArray(valueSelected)) {
    _arrSelected = valueSelected
  } else if (valueSelected) {
    //bo sung truong hop loi neu valueSelected la number
    let _valueSelected = valueSelected
    if (typeof valueSelected !== "string") {
      _valueSelected = "" + valueSelected
    }
    _arrSelected = _valueSelected.split(arraySplitChar || ",")
  }
  if (sourceData) {
    return sourceData.filter((v, i) => {
      return _arrSelected.indexOf(v.Value) > -1
    })
  }
  return []
}

export const HOptionsGetArrayOptionFromArrayValue = (
  arrValue = [],
  options = [],
  { arraySplitChar } = { arraySplitChar: "," }
) => {
  let _arrOption = []
  let _arrValue = arrValue
  if (typeof _arrValue === "string") {
    _arrValue = _arrValue.split(arraySplitChar)
  }
  // console.warn("get arr:", _arrValue, options)
  if (_arrValue && Array.isArray(_arrValue) && _arrValue.length > 0) {
    for (let i = 0; i < _arrValue.length; i++) {
      for (let j = 0; j < options.length; j++) {
        if (options[j].Value == _arrValue[i]) {
          _arrOption.push(options[j])
          break
        }
      }
    }
  }
  return _arrOption
}

export const HOptionsGetItemOfValueFromOptions = (options, value) => {
  let _item = null
  if (options != null) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].Value == value) {
        _item = options[i]
        break
      }
    }
  }
  return _item
}

export const HOptionsGetNextItemOfValueFromOptions = (options, value) => {
  let _item = null
  if (options != null && options.length > 0) {
    if (value == null) {
      return options[0]
    }
    for (let i = 0; i < options.length; i++) {
      // console.warn('nextItem: ', i,options[i],options[i].Value, value);
      if (options[i].Value == value) {
        if (i == options.length - 1) {
          _item = options[0]
        } else {
          _item = options[i + 1]
        }
        break
      }
      //khong tim thay value trong options
      if (i == options.length - 1) {
        return options[0]
      }
    }
  }
  return _item
}

//bo sung fConfigForm
export const OneTableCell_getConfigForm = ({
  customMore,
  oneTableCell,
  row,
} = {}) => {
  let _configForm = {}
  if (oneTableCell && customMore && row) {
    console.log("OneTableCell_getConfigForm:", customMore, row)
    if (
      customMore[KMTC.fConfigForm] &&
      row.hasOwnProperty(customMore[KMTC.fConfigForm])
    ) {
      return row[customMore[KMTC.fConfigForm]]
    }
    return oneTableCell.getConfigForm({ customMore })
  }
  return _configForm
}

export const HLink_updateParamOnUrl = (
  name,
  value,
  url = window.location.href,
  opts = { isReplace: false }
) => {
  let _allPrams = HLink.getAllParamOnUrl(url)
  let _newUrl = url
  if (_allPrams) {
    _allPrams[name] = value
    return HLink_updateUrlWithAllParams(_allPrams, url, {
      isReplace: opts.isReplace,
    })
  }
  return _newUrl
}
export function HLink_updateUrlWithAllParams(
  allParams,
  url = window.location.href,
  opts = { updateUrlWithoutReload: true, isReplace: false }
) {
  let _newUrl = url
  if (allParams) {
    let _indexQuestionMark = url.lastIndexOf("?")
    if (_indexQuestionMark == -1) {
      _indexQuestionMark = url.length
    }
    _newUrl =
      url.substr(0, _indexQuestionMark) +
      "?" +
      Object.keys(allParams)
        .map(v => {
          return `${v}=${allParams[v]}`
        })
        .join("&")
    let _updateUrlWithoutReload = opts.updateUrlWithoutReload !== false
    if (_updateUrlWithoutReload) {
      HLink_setNewUrlWithoutReload(_newUrl, { isReplace: opts.isReplace })
    }
  }
  return _newUrl
}
export function HLink_setNewUrlWithoutReload(url, opts = { isReplace: false }) {
  if (url) {
    if (opts.isReplace === true) {
      window.history.replaceState({ path: url }, "", url)
    } else {
      window.history.pushState({ path: url }, "", url)
    }
  }
}

export function HUtils_getTextWithData(text = "", data = {}, opts) {
  try {
    if (text && typeof text == "string" && text.indexOf("[") > -1) {
      return HUtils.getTextWithData(text, data, opts)
    } else {
      return text
    }
  } catch (error) {
    console.warn("error parse html:", text)
    return text
  }
}

export function HUtils_getTextOrHTML(text = "", opts = {}) {
  if (typeof text === "string") {
    try {
      if (
        String(text).trim().startsWith("<") &&
        String(text).trim().endsWith(">")
      ) {
        return <MyUI key={opts.key} type="html" html={String(text).trim()} />
      } else {
        if (opts.usingI18n) {
          if (opts.from) {
            return i18next.t(`${opts.from}.${text}`, {
              ns: opts.ns || "common",
              defaultValue: i18next.t(text, {
                ns: opts.ns || "common",
                defaultValue: text,
              }),
            })
          }
          return i18next.t(text, {
            ns: opts.ns || "common",
            defaultValue: text,
          })
        }
        return text
      }
    } catch (error) {
      console.warn("error parse text:", text)
      return text
    }
  }
  return text
}
