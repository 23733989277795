import React from "react"
import { HUtils } from "@macashipo/mlib"
import NumeralJs from "src/libs/NumeralJs/numeral"
import HelperMore from "./_helperMore"
import { KMC } from "src/helpers/Flows/More"
const BaseText = props => {
  const more = HelperMore.getMoreFromProps(props) || {}
  const [isFocus, setIsFocus] = React.useState(false)
  const value = props.value
  const refValue = React.useRef(value)

  const getFormat = () => {
    return HUtils.runFuntion(props.fnList, "getFormat", []) || "0,0.[00]"
  }
  const getTypeInput = () => {
    return HUtils.runFuntion(props.fnList, "getTypeInput", []) || "text"
  }
  const fnFormatDefault = v => {
    return NumeralJs(v).format(getFormat())
  }
  const fnFormat =
    HUtils.runFuntion(props.fnList, "fnFormat", []) || fnFormatDefault
  let valueDisplay = fnFormat(value)
  // console.warn("render numeral valueDisplay:", valueDisplay)
  const checkCanNotPress = (key = "") => {
    if (key == "Enter" && more[KMC.allowEnterToSubmit] === true) {
      return false
    }
    if (value) {
      let _sValue = String(value)
      if (key === "-" && _sValue.indexOf("-") > -1) {
        return true
      }
      if (key === "." && _sValue.indexOf(".") > -1) {
        return true
      }
    }
    if (!/[0-9\-\.]/.test(key)) {
      return true
    }
    return false
  }
  const checkReplace = (newValue = "") => {
    //remove het cac ky tu ko mong muon khi copy vao
    newValue = newValue.replace(/[^0-9\-\.]/g, "")

    if (props.noDecimal) {
      newValue = newValue.replace(/[\.\,]/g, "")
    }
    //no negative
    if (props.noNegative === true && newValue.indexOf("-") > -1) {
      newValue = newValue.replace("-", "")
    }
    if (newValue.indexOf("-") > -1 && newValue.indexOf("-") > 0) {
      newValue = newValue.replace(/\-/g, "")
      newValue = `-${newValue}`
    }
    if (newValue.indexOf(".") > -1) {
      if (newValue.indexOf(".") == 0) {
        newValue = `0${newValue}`
      }
      if (newValue.length > 1 && newValue.indexOf(".") == newValue.length) {
        newValue = `0${newValue}`
      }
    }
    return newValue
  }

  let _prefix = HelperMore.getPrefix(more) || props.prefix
  if (_prefix) {
    valueDisplay = _prefix + valueDisplay
  }
  let _suffix = HelperMore.getSuffix(more) || props.suffix
  if (_suffix) {
    valueDisplay += _suffix
  }

  if (value === "") {
    //== se bi truong hop 0 == "" => valueDisplay se bi rong
    valueDisplay = ""
  }
  // if (more[KMC.hideZero] || more[KMC.hideNull]) {
  //   console.warn("hideZero", props, more, value, valueDisplay)
  // }
  if (more[KMC.hideZero] == true && value == 0) {
    valueDisplay = ""
  }
  if (more[KMC.hideNull] == true && value == null) {
    valueDisplay = ""
  }
  //bo sung check valueDisplay == 0 do truong hop value == null
  if (more[KMC.hideZero] == true && valueDisplay == 0) {
    valueDisplay = ""
  }
  let Tag = "input"
  if (getTypeInput() === "textarea") {
    Tag = "textarea"
  }
  // console.warn("render numeral", isFocus, props.value, valueDisplay, more)
  let _valueShowWhenFocus = value
  if (_valueShowWhenFocus === 0) {
    _valueShowWhenFocus = ""
  }
  // console.warn("render numeral:", props, _valueShowWhenFocus, valueDisplay)
  let _moreProps = {}
  return (
    <Tag
      type={getTypeInput()}
      value={isFocus ? _valueShowWhenFocus : valueDisplay}
      onChange={ev => {
        let _value = checkReplace(ev.currentTarget.value)
        refValue.current = _value
        console.warn("onChange:", _value)
        props.onChange && props.onChange(_value)
      }}
      onKeyPress={ev => {
        if (checkCanNotPress(ev.key)) {
          ev.preventDefault()
        }
      }}
      onFocus={ev => {
        //bo sung truong hop load list gia tri moi, khi bam vao bam ra thi luu gia tri null do onChange chua duoc goi
        refValue.current = _valueShowWhenFocus
        console.warn("onFocus:", _valueShowWhenFocus)
        setIsFocus(true)
      }}
      onBlur={() => {
        setIsFocus(false)
        if (more.min != null && parseFloat(refValue.current) < more.min) {
          refValue.current = more.min
          props.onChange && props.onChange(refValue.current)
        }
        if (more.max != null && parseFloat(refValue.current) > more.max) {
          console.log("max:", more.max)
          refValue.current = more.max
          props.onChange && props.onChange(refValue.current)
        }
        props.onBlur && props.onBlur(refValue.current)
      }}
      // onKeyDown={()=>{
      // }}
      className={props.className}
      style={props.style}
      {...props.attrs}
      {..._moreProps}
    />
  )
}

export default BaseText
