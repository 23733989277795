import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"
const MUI_notifySignalR = React.lazy(() => import("./Types/notifySignalR"))

const List = {
  notifySignalR: MUI_notifySignalR,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
  },
}

export default MyUIOne
