import React from "react"
import ReactDOM from "react-dom"
import { MyModal } from "src/core/components"
class DevMenu extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className="floating-container">
        <div className="floating-button">
          <i className="fa fa-bars" />
        </div>
        <div className="element-container">
          <span
            className="float-element"
            onClick={() => {
              MyModal.showFromMyUI(
                {
                  type: "dev_jsonconverter",
                },
                {
                  title: "JSON",
                }
              )
            }}
          >
            <i className="material-icons">Json</i>
          </span>
        </div>
      </div>
    )
  }
}

DevMenu.renderIntoId = divId => {
  if (divId && document.getElementById(divId)) {
    ReactDOM.render(<DevMenu />, document.getElementById(divId))
  }
}
export default DevMenu
