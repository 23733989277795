import React from "react"
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps,
} from "react-router-dom"
import { connect } from "react-redux"
import { HAuth } from "src/core/helpers"
interface ReduxProps {
  isAuthenticated: boolean
}
interface Props extends ReduxProps, RouteProps {
  component: React.ComponentType<RouteComponentProps>
  componentGuest?: React.ComponentType<RouteComponentProps>
}

function AuthenticatedGuard(props: Props) {
  const {
    isAuthenticated,
    component: Component,
    componentGuest: ComponentGuest,
    ...rest
  } = props
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated && !HAuth.authIsLogged()) {
          if (ComponentGuest) {
            return <ComponentGuest {...props} />
          }
          return <Redirect to="/login" from={window.location.href} />
        }
        return <Component {...props} />
      }}
    />
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.app.isAuthenticated,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedGuard)
