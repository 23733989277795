import { HUtils } from "@macashipo/mlib"
import apiGeneric from "src/services/apiGeneric"
const HUpload = {
  globalDropzone: null,
  onDrop: null,
  fnUpdateAccept: null,
  open: onDrop => {
    if (HUpload.globalDropzone && HUpload.globalDropzone.open) {
      HUpload.onDrop = onDrop
      HUpload.globalDropzone.open()
    }
  },
  openAndUpload: ({ beforeUpload, afterUpload, accept } = {}) => {
    if (HUpload.globalDropzone && HUpload.globalDropzone.open) {
      if (accept && HUpload.fnUpdateAccept) {
        HUpload.fnUpdateAccept(accept, () => {
          HUpload.onDrop = acceptedFiles => {
            beforeUpload && beforeUpload()
            var _formData = new FormData()
            if (acceptedFiles && acceptedFiles.length > 0) {
              for (let i = 0; i < acceptedFiles.length; i++) {
                _formData.append("file[]", acceptedFiles[i])
              }
              apiGeneric({
                url: "ProjectPhoto/FileUploadFile",
                method: "POST",
                data: _formData,
                silent: true,
                customContentType: "multipart/form-data",
              })
                .then(rs => {
                  console.warn("rs:", rs)
                  let _url = HUtils.get(rs, "Data.Photos.0.FileUrl")
                  afterUpload && afterUpload(rs, null, _url)
                })
                .catch((err, rs) => {
                  afterUpload && afterUpload(rs, err)
                })
            } else {
              console.warn("No acceptedFiles", acceptedFiles)
            }
          }
          HUpload.globalDropzone.open()
        })
      } else {
        console.warn("Missing accept")
      }
    }
  },
  dataURItoBlob(dataURI) {
    var byteString
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1])
    else byteString = unescape(dataURI.split(",")[1])

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], { type: mimeString })
  },
}

export default HUpload
