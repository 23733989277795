import React from "react"
import { Button, Form } from "reactstrap"
import { HValid, HUtils, HIs, HJson, OneButton } from "@macashipo/mlib"
import { fnListMyForm, kresetFormControl } from "src/helpers/fnList"
import MyFormControl from "./MyFormControl"
import { KMoreOfForm } from "src/helpers/Flows/Form"
import i18next from "i18next"
import styled from "styled-components"
import {
  FlowFnListFromExtControl,
  FlowUpdateRelativeControls,
} from "src/helpers/Flows/FormControl"
import { showToastWarning } from "src/core/helpers/ui"
import { HApi } from "src/core/helpers"

const WrapForm = styled.div`
  & form > .a-content {
    display: flex;
    flex-direction: column;
  }
  & form > .a-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f1f133;
  }
  & .aa-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > * {
      margin-right: 0.5rem !important;
    }
  }
  & .a-footer-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > * {
      margin-left: 0.5rem !important;
    }
  }
`
class MyForm extends React.Component<IPropsMyForm, any> {
  currentValues = {}
  toucheds: string[] = [] //danh sach field da tac dong
  errorsValid = {} //danh sach loi, check valid
  relativeControls: any = {} //{a:["b","c"]}//field a -> anh huong toi b c
  effectControls: any = {}
  controlsByFieldName = {}
  fieldsRequired: any = []
  fieldsInvalid: any = []
  controlsOptsByFieldName: any = {}
  fnListFromWrap: any = {}
  fnListFromExtControl: any = {}
  fnListFormControl: any = {}
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
    this._buildRelativeControls()
    this._initCurrentValuesByDefaultValue()
  }
  closeMyModal = () => {
    const { inMyModal } = this.props
    if (inMyModal) {
      inMyModal.hide()
    }
  }
  _initCurrentValuesByDefaultValue = () => {
    const { configForm } = this.props
    if (configForm.defaultValues) {
      for (let k of Object.keys(configForm.defaultValues)) {
        this.currentValues[k] = configForm.defaultValues[k]
      }
    }
  }
  _buildRelativeControls = () => {
    const { configForm } = this.props
    if (configForm && configForm.controls) {
      console.warn("_buildRelativeControls:", configForm.controls)
      for (let i = 0; i < configForm.controls.length; i++) {
        let _control = configForm.controls[i]
        let _fieldName = _control.fieldName || ""
        let _more = _control.more
        //bo sung tu type layout -> chuyen vao xu ly trong ham FlowUpdateRelativeControls
        // if (
        //   _control.type &&
        //   _control.type.startsWith("layout") &&
        //   _more &&
        //   _more["items"]
        // ) {
        //   for (let j = 0; j < _more["items"].length; j++) {
        //     const _controlInLayout = _more["items"][j]
        //     FlowUpdateRelativeControls({
        //       objRelatives: this.relativeControls,
        //       objEffectedBy: this.effectControls,
        //       configFormControl: _controlInLayout,
        //       fieldName: _controlInLayout.fieldName,
        //       more: _controlInLayout.more,
        //     })
        //   }
        // }
        //bo sung control tu groups
        if (_control.groups?.length && !_fieldName) {
          const groups = _control.groups
          for (let j = 0; j < groups.length; j++) {
            const _controlInGrp = groups[j]
            const _moreCtlInGrp = _controlInGrp.more
            const _fieldNameCtlInGrp = _controlInGrp.fieldName
            FlowUpdateRelativeControls({
              objRelatives: this.relativeControls,
              objEffectedBy: this.effectControls,
              configFormControl: _controlInGrp,
              fieldName: _fieldNameCtlInGrp,
              more: _moreCtlInGrp,
            })
            // if (
            //   _moreCtlInGrp &&
            //   _moreCtlInGrp.relativeTo &&
            //   _moreCtlInGrp.relativeTo.length > 0
            // ) {
            //   if (this.relativeControls[_fieldNameCtlInGrp] === undefined) {
            //     this.relativeControls[_fieldNameCtlInGrp] = []
            //   }
            //   this.relativeControls[_fieldNameCtlInGrp] = this.relativeControls[
            //     _fieldNameCtlInGrp
            //   ].concat(_moreCtlInGrp.relativeTo)
            // }
            // if (_moreCtlInGrp && _moreCtlInGrp.conditionShow) {
            //   let _keys = Object.keys(_moreCtlInGrp.conditionShow)
            //   if (!_keys.includes("isCondition")) {
            //     for (let k of _keys) {
            //       if (this.relativeControls[k] === undefined) {
            //         this.relativeControls[k] = []
            //       }
            //       if (
            //         this.relativeControls[k].indexOf(_fieldNameCtlInGrp) === -1
            //       ) {
            //         this.relativeControls[k].push(_fieldNameCtlInGrp)
            //       }
            //     }
            //   } else {
            //     // Khi dieu kien show của 1 control la 1 dieu kien khong phai 1 gia tri cu the
            //     const conditionShow = _moreCtlInGrp.conditionShow
            //     if (
            //       this.relativeControls[conditionShow.fieldRelative] ===
            //       undefined
            //     ) {
            //       this.relativeControls[conditionShow.fieldRelative] = []
            //     }
            //     if (
            //       this.relativeControls[conditionShow.fieldRelative].indexOf(
            //         _fieldNameCtlInGrp
            //       ) === -1
            //     ) {
            //       this.relativeControls[conditionShow.fieldRelative].push(
            //         _fieldNameCtlInGrp
            //       )
            //     }
            //   }
            // }
            // if (_more && _more.effectedBy && _more.effectedBy.length > 0) {
            //   for (let k of _more.effectedBy) {
            //     if (this.effectControls[k] === undefined) {
            //       this.effectControls[k] = []
            //     }
            //     if (this.effectControls[k].indexOf(_fieldNameCtlInGrp) === -1) {
            //       this.effectControls[k].push(_fieldNameCtlInGrp)
            //     }
            //   }
            // }
          }
        } else {
          FlowUpdateRelativeControls({
            objRelatives: this.relativeControls,
            objEffectedBy: this.effectControls,
            configFormControl: _control,
            fieldName: _fieldName,
            more: _more,
          })
          // if (_more && _more.relativeTo && _more.relativeTo.length > 0) {
          //   if (this.relativeControls[_fieldName] === undefined) {
          //     this.relativeControls[_fieldName] = []
          //   }
          //   this.relativeControls[_fieldName] = this.relativeControls[
          //     _fieldName
          //   ].concat(_more.relativeTo)
          // }
          // if (_more && _more.conditionShow) {
          //   let _keys = Object.keys(_more.conditionShow)
          //   if (!_keys.includes("isCondition")) {
          //     for (let k of _keys) {
          //       if (this.relativeControls[k] === undefined) {
          //         this.relativeControls[k] = []
          //       }
          //       if (this.relativeControls[k].indexOf(_fieldName) === -1) {
          //         this.relativeControls[k].push(_fieldName)
          //       }
          //     }
          //   } else {
          //     // Khi dieu kien show của 1 control la 1 dieu kien khong phai 1 gia tri cu the
          //     const conditionShow = _more.conditionShow
          //     const fieldRelative = conditionShow?.fieldRelative
          //     // Danh cho truong hop phu thuoc 1 control trong 1 group
          //     if (fieldRelative.indexOf(".") > -1) {
          //       //Chi lay field cua cha vi ben trong thay doi thi se thay doi luon ca group va se update control phu thuoc
          //       let _fieldParent = fieldRelative.split(".")?.[0]
          //       if (this.relativeControls[_fieldParent] === undefined) {
          //         this.relativeControls[_fieldParent] = []
          //       }
          //       if (
          //         this.relativeControls[_fieldParent].indexOf(_fieldName) === -1
          //       ) {
          //         this.relativeControls[_fieldParent].push(_fieldName)
          //       }
          //     } else {
          //       if (this.relativeControls[fieldRelative] === undefined) {
          //         this.relativeControls[fieldRelative] = []
          //       }
          //       if (
          //         this.relativeControls[fieldRelative].indexOf(_fieldName) ===
          //         -1
          //       ) {
          //         this.relativeControls[fieldRelative].push(_fieldName)
          //       }
          //     }
          //   }
          // }
          // if (_more && _more.effectedBy && _more.effectedBy.length > 0) {
          //   for (let k of _more.effectedBy) {
          //     if (this.effectControls[k] === undefined) {
          //       this.effectControls[k] = []
          //     }
          //     if (this.effectControls[k].indexOf(_fieldName) === -1) {
          //       this.effectControls[k].push(_fieldName)
          //     }
          //   }
          // }
        }
      }
    }
    console.warn("this.relativeControls", this.relativeControls)
  }
  _getMoreOfForm = () => {
    const { configForm } = this.props
    let _more = {}
    if (configForm.more) {
      if (HIs.isJsonString(configForm.more)) {
        _more = HJson.getObj(configForm.more)
      } else {
        _more = configForm.more
      }
    }
    return _more
  }
  _getMoreWithKey = (key = "") => {
    let _moreOfForm = this._getMoreOfForm()
    if (key && _moreOfForm.hasOwnProperty(key)) {
      return _moreOfForm[key]
    }
  }
  _onTouch = (fieldName, newValue) => {
    console.warn("onTouch", fieldName, newValue, this.relativeControls)
    if (this.toucheds.indexOf(fieldName) === -1) {
      this.toucheds.push(fieldName)
    }
    this.currentValues[fieldName] = newValue

    //reset invalid
    if (
      this.fieldsInvalid &&
      this.fieldsInvalid.length > 0 &&
      this.fieldsInvalid.indexOf(fieldName) > -1
    ) {
      if (newValue != null && newValue != "") {
        this.fieldsInvalid.splice(this.fieldsInvalid.indexOf(fieldName), 1)
        FlowFnListFromExtControl.forceUpdate({
          fnListFromExtControl: this.fnListFromExtControl,
          fieldName: fieldName,
        })
      }
    }

    if (this.relativeControls[fieldName]) {
      for (let k of this.relativeControls[fieldName]) {
        FlowFnListFromExtControl.forceUpdate({
          fnListFromExtControl: this.fnListFromExtControl,
          fieldName: k,
        })
      }
    }
    if (this.effectControls[fieldName]) {
      for (let k of this.effectControls[fieldName]) {
        FlowFnListFromExtControl.updateValueEffect({
          fnListFromExtControl: this.fnListFromExtControl,
          fieldName: k,
        })
      }
    }
  }
  _checkValidRequired = () => {
    let _valid = true
    this.fieldsInvalid = []
    if (
      this.currentValues &&
      this.fieldsRequired &&
      this.fieldsRequired.length > 0
    ) {
      for (let f of this.fieldsRequired) {
        if (this.currentValues[f] == null || this.currentValues[f] === "") {
          _valid = false
          this.fieldsInvalid.push(f)
        }
      }
      for (let f of this.fieldsInvalid) {
        FlowFnListFromExtControl.forceUpdate({
          fnListFromExtControl: this.fnListFromExtControl,
          fieldName: f,
        })
      }
    }
    return _valid
  }
  _onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    const { fnList, configForm, inMyModal } = this.props
    const me = this
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    console.warn("Submit", this.currentValues)
    if (this._checkValidRequired() == false) {
      showToastWarning("Please fill out all required fields.")
      return
    }
    if (fnList && fnList.onSubmitForm) {
      me.setState({
        isLoading: true,
      })
      const result = fnList.onSubmitForm(this.currentValues, {
        defaultValues: configForm.defaultValues,
        errorsValid: this.errorsValid,
        relativeControls: this.relativeControls,
        toucheds: this.toucheds,
        inMyModal,
        myForm: this,
      })
      if (result && result.then) {
        result
          .then((rs: any) => {
            console.warn("promise myform rs:", rs)
            me.setState({
              isLoading: false,
            })
            if (configForm.closeMyModalAfterSubmit === true && inMyModal) {
              inMyModal.hide()
            }
          })
          .catch(err => {
            console.warn("err:", err)
            me.setState({
              isLoading: false,
            })
          })
      }
    } else {
      console.warn("Missing fnList.onSubmitForm")
    }
  }
  getDisabledForm = () => {
    return this.props["disabled"] === true ? true : false
  }
  getCurrentValues = () => {
    return this.currentValues
  }
  getSourceList = () => {
    const { fnList } = this.props
    if (fnList && fnList.getSourceList) {
      return fnList.getSourceList()
    }
    return {}
  }
  getConfigControlByFieldName = (fieldName: string) => {
    const { configForm } = this.props
    if (this.controlsByFieldName[fieldName]) {
      return this.controlsByFieldName[fieldName]
    }
    if (configForm && configForm.controls && configForm.controls.length > 0) {
      for (let i = 0; i < configForm.controls.length; i++) {
        let _control = configForm.controls[i]
        if (_control.fieldName === fieldName) {
          this.controlsByFieldName[fieldName] = _control
          return _control
        }
      }
    }
  }
  getConfigInMoreOfControl = (
    fieldName: string,
    key: string,
    configControl?: any
  ) => {
    let _configControl: any =
      configControl || this.getConfigControlByFieldName(fieldName)
    if (_configControl && _configControl.more && _configControl.more[key]) {
      return _configControl.more[key]
    }
  }
  getRulesOfControl = (fieldName: string) => {
    return this.getConfigInMoreOfControl(fieldName, "rules")
  }
  checkValidAll = () => {
    const { configForm } = this.props
    let _result: any = true
    if (configForm && configForm.controls) {
      for (let i = 0; i < configForm.controls.length; i++) {
        let _control = configForm.controls[i]
        let _resultOfControl = this.checkValidOfField(
          _control.fieldName,
          _control
        )
        if (_resultOfControl !== true) {
          if (_result === true) {
            _result = {}
          }
          if (_control.fieldName) {
            _result[_control.fieldName] = _resultOfControl
          }
        }
      }
    }
    console.warn("checkValidAll:", _result)
    if (_result !== true) {
      this.errorsValid = _result
    } else {
      this.errorsValid = {}
    }
    this.forceUpdate()
    return _result
  }
  checkValidOfField = (fieldName: string = "", configControl?: any) => {
    let _configControl: any =
      configControl || this.getConfigControlByFieldName(fieldName)
    let _result = true
    if (_configControl) {
      let _rules = this.getRulesOfControl(fieldName)
      let _value = this.currentValues[fieldName]
      if (_rules) {
        _result = HValid.validAllRulesOfValue(_rules, _value)
        console.warn("checkValidOfField:", fieldName, _rules, _value, _result)
      }
    }
    return _result
  }
  _renderBtnSubmit = () => {
    let _configBtnSubmit = this._getMoreWithKey(KMoreOfForm.btnSubmit) || {}
    return (
      <Button
        type="submit"
        style={_configBtnSubmit.style}
        color={_configBtnSubmit.color || "primary"}
        disabled={this._getIsLoading()}
      >
        {this._getIsLoading() && (
          <i
            style={{ marginRight: "0.5rem" }}
            className="fa fa-spin fa-spinner"
          />
        )}
        {_configBtnSubmit.title || i18next.t("Save")}
      </Button>
    )
  }
  _renderBtnReset = () => {
    const { configForm } = this.props
    let _configBtnReset = this._getMoreWithKey(KMoreOfForm.btnReset) || {}
    if (_configBtnReset.hide == true) {
      return null
    }
    return (
      <Button
        type="reset"
        style={_configBtnReset.style}
        color={_configBtnReset.color || "secondary"}
        disabled={this._getIsLoading()}
        onClick={() => {
          this.currentValues = { ...configForm.defaultValues }
          if (configForm && configForm.controls) {
            for (let i = 0; i < configForm.controls.length; i++) {
              let _control = configForm.controls[i]
              let _fieldName = _control.fieldName || ""
              console.warn("fnListFromExtControl", this.fnListFromExtControl)
              fnListMyForm[kresetFormControl](
                this.fnListFromExtControl[_fieldName],
                []
              )
            }
          }
          // this.forceUpdate()
        }}
      >
        {_configBtnReset.title || i18next.t("Reset")}
      </Button>
    )
  }
  _getIsLoading = () => {
    return this.state.isLoading || false
  }
  _renderMoreButtons = () => {
    let _moreButtons = this._getMoreWithKey(KMoreOfForm.moreButtons)
    if (_moreButtons && _moreButtons.length > 0) {
      let _oneButtons = _moreButtons.map((v, i) => {
        return new OneButton({
          configButton: v,
          onePage: {
            //fn bat buoc
            getApiController: () => {
              return "apicontroller"
            },
            getQueryShared: () => {
              return {}
            },
          },
        })
      })
      return _oneButtons.map((v, i) => {
        return v.renderMyButton({
          key: i,
          fnList: {},
        })
      })
    }
    return null
  }
  _renderFooter = () => {
    const { configForm } = this.props
    let _hideFooter = this._getMoreWithKey(KMoreOfForm.hideFooter)
    if (_hideFooter === true) {
      return <div className="a-footer-hide"></div>
    }
    if (this.props.renderFooter) {
      return this.props.renderFooter(this)
    }
    if (this.getDisabledForm()) {
      return <div className="aa-footer-disabled"></div>
    }
    let _position =
      this._getMoreWithKey(KMoreOfForm.positionButtonsBottom) || "left"
    if (_position === "right") {
      return (
        <div className="a-footer-right">
          {this._renderMoreButtons()}
          {this._renderBtnReset()}
          {this._renderBtnSubmit()}
        </div>
      )
    }
    return (
      <div className="aa-footer">
        {this._renderBtnSubmit()}
        {this._renderBtnReset()}
        {this._renderMoreButtons()}
      </div>
    )
  }
  _renderLoadingOverlay = () => {
    const { configForm } = this.props
    if (this.props.renderLoadingOverlay) {
      return this.props.renderLoadingOverlay(this)
    }
    return (
      <div className="a-loading">
        <i className="fa fa-spin fa-spinner" />
      </div>
    )
  }
  _renderFormControl = (configControl, i) => {
    const { configForm } = this.props
    const v = configControl || {}
    // console.warn("_renderFormControl", configControl)
    let _fnList = this.getFnListOfFormControl()
    // console.warn("canshow:", _canShow, configControl)
    return (
      <MyFormControl
        key={i}
        type={v.type}
        configFormControl={{
          ...v,
          defaultValues: configForm.defaultValues,
          formErrorsValid: this.errorsValid,
        }}
        fnList={{
          ..._fnList,
        }}
      />
    )
  }
  requestUpdateAfterChange = (opts: any = {}) => {
    // console.warn("requestUpdateAfterChange:", opts)
    const { configForm } = this.props
    const configApiAfterChange = this._getMoreWithKey(
      KMoreOfForm.configApiAfterChange
    )
    if (
      configApiAfterChange &&
      configApiAfterChange.url &&
      Array.isArray(configApiAfterChange.fields) &&
      opts.fieldName &&
      configApiAfterChange.fields.indexOf(opts.fieldName) > -1
    ) {
      HApi.apiGeneric({
        method: configApiAfterChange.method || "POST",
        url: configApiAfterChange.url, // || "RentalService_SelfdriveCarRental_ListView_MoreInfo/UpdateCreateOrderForm",
        data: {
          DefaultValues: configForm?.defaultValues,
          DataForm: this.currentValues,
        },
      }).then(res => {
        console.warn("requestUpdateAfterChange:", res)
        if (res && res.Data) {
          for (let k of Object.keys(res.Data)) {
            // this.currentValues[k] = res.Data[k]
            this._onTouch(k, res.Data[k])
            FlowFnListFromExtControl.updateValue({
              fnListFromExtControl: this.fnListFromExtControl,
              fieldName: k,
              newValue: res.Data[k],
            })
          }
          // this.forceUpdate()
        }
      })
    }
  }
  getFnListOfFormControl = () => {
    const { fnList } = this.props
    if (
      this.fnListFormControl &&
      Object.keys(this.fnListFormControl).length > 0
    ) {
      return this.fnListFormControl
    }
    this.fnListFormControl = {
      ...fnList,
      initWrapControl: (fieldName: string, fnFromWrap: any) => {
        if (this.fnListFromWrap[fieldName] === undefined) {
          this.fnListFromWrap[fieldName] = fnFromWrap
        }
      },
      initExtControl: (fieldName: string, fnFromExtControl: any) => {
        if (this.fnListFromExtControl[fieldName] === undefined) {
          this.fnListFromExtControl[fieldName] = fnFromExtControl
        }
      },
      addFnListFromControl: (fnName, fn, fieldName) => {
        if (!this.fnListFromExtControl[fieldName]) {
          this.fnListFromExtControl[fieldName] = {}
        }
        if (this.fnListFromExtControl[fieldName]) {
          this.fnListFromExtControl[fieldName][fnName] = fn
        }
      },
      getSourceList: this.getSourceList,
      getCurrentValues: () => {
        return this.currentValues
      },
      getDefaultValues: () => {
        return this.props.configForm.defaultValues || {} //tranh truong hop defaultValues la null
      },
      getFieldsInvalid: () => {
        return this.fieldsInvalid
      },
      getIsInvalid: fieldName => {
        if (
          fieldName &&
          this.fieldsInvalid &&
          this.fieldsInvalid.indexOf(fieldName) > -1
        ) {
          return true
        }
        return false
      },
      updateRequired: fieldName => {
        if (fieldName && this.fieldsRequired.indexOf(fieldName) == -1) {
          this.fieldsRequired.push(fieldName)
        }
        console.warn("updateRequired:", this.fieldsRequired)
      },
      onSubmitFormFromControl: () => {
        this._onSubmit()
      },
      onUpdateValue: ({ fieldName, newValue }) => {
        console.warn("onUpdateValue:", fieldName, newValue)
        HUtils.runFuntion(this.props.fnList, "afterUpdateValueControl", [
          {
            fieldName,
            newValue,
            control: this.getConfigControlByFieldName(fieldName),
          },
        ])
        this._onTouch(fieldName, newValue)
        //update api
        this.requestUpdateAfterChange({ fieldName })
      },
      onJustUpdateValueMultiCurrentValuesForm: (list: any) => {
        let _keys = Object.keys(list)
        for (let k of _keys) {
          this.currentValues[k] = list[k]
        }
      },
      onUpdateControlWithNewValue: (fieldName, newValue) => {
        HUtils.runFuntion(this.props.fnList, "afterUpdateValueControl", [
          {
            fieldName,
            newValue,
            control: this.getConfigControlByFieldName(fieldName),
          },
        ])
        this._onTouch(fieldName, newValue)
        FlowFnListFromExtControl.updateValue({
          fnListFromExtControl: this.fnListFromExtControl,
          fieldName: fieldName,
          newValue: newValue,
        })
      },
      onUpdateValueMulti: (list: any) => {
        console.warn("onUpdateValueMulti:", list)
        let _keys = Object.keys(list)
        if (_keys && _keys.length > 0) {
          for (let k of _keys) {
            HUtils.runFuntion(this.props.fnList, "afterUpdateValueControl", [
              {
                fieldName: k,
                newValue: list[k],
                control: this.getConfigControlByFieldName(k),
              },
            ])
            this._onTouch(k, list[k])
          }
        }
      },
      registerControl: (fieldName: string, opts: any = {}) => {
        this.controlsOptsByFieldName[fieldName] = {
          component: opts.component,
          forceUpdate: opts.forceUpdate,
        }
      },
      onTouch: ({ fieldName }: { fieldName: string }) => {
        console.warn("onTouch", fieldName)
        if (this.toucheds.indexOf(fieldName) === -1) {
          this.toucheds.push(fieldName)
        }
      },
      fnGetTypeWrapControl: () => {
        return this._getMoreWithKey(KMoreOfForm.typeWrapControl)
      },
      fnGetDefaultConfigWrapControl: () => {
        return this._getMoreWithKey(KMoreOfForm.defaultConfigWrapControl)
      },
      fnRenderFormControl: this._renderFormControl,
      fnGetDisabledForm: this.getDisabledForm,
    }
    return this.fnListFormControl
  }
  render() {
    const { configForm, style, className, fnList, autoComplete } = this.props
    console.warn("render MyForm:", configForm, this.props)
    let _moreProps = {}
    if (this._getMoreWithKey(KMoreOfForm.noValidateDefault) === true) {
      _moreProps["noValidate"] = true
    }
    let _fnList = this.getFnListOfFormControl()
    let _usingI18n = this._getMoreWithKey(KMoreOfForm.usingI18n)
    let _screenCode = HUtils.runFuntion(_fnList, "fnGetScreenCode")
    return (
      <WrapForm>
        <Form
          onSubmit={this._onSubmit}
          style={style}
          className={className}
          autoComplete={autoComplete}
          {..._moreProps}
        >
          <div className="a-content">
            {configForm &&
              configForm.controls &&
              configForm.controls?.map((v, i) => {
                return (
                  <MyFormControl
                    key={i}
                    type={v.type}
                    configFormControl={{
                      ...v,
                      defaultValues: configForm.defaultValues,
                      formErrorsValid: this.errorsValid,
                    }}
                    usingI18n={_usingI18n}
                    screenCode={_screenCode}
                    fnList={{
                      ..._fnList,
                    }}
                  />
                )
              })}
          </div>
          {this._renderFooter()}
          {this._getIsLoading() && this._renderLoadingOverlay()}
        </Form>
      </WrapForm>
    )
  }
}
export default MyForm
