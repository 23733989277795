import { useEffect, useRef, useState } from "react"
const useCountdown = (targetDate, { stopWhenFinish, forceStop } = {}) => {
  // const [stop, setStop] = useState(false)
  const countDownDate = new Date(targetDate).getTime()
  const config = useRef({
    needStopInterval: false,
  }).current

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  )
  // console.log("countDownDate:", countDownDate, countDown)
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("interval tick", stopWhenFinish, config.needStopInterval)
      if (stopWhenFinish && config.needStopInterval) {
        clearInterval(interval)
      } else if (forceStop) {
        config.needStopInterval = true
        setCountDown(0)
      } else {
        let _countDown = countDownDate - new Date().getTime()
        if (_countDown <= 0) {
          config.needStopInterval = true
        }
        setCountDown(_countDown)
      }
    }, 500)
    return () => clearInterval(interval)
  }, [countDownDate, stopWhenFinish, forceStop, config])

  return getReturnValues(countDown)
}

const getReturnValues = countDown => {
  // calculate time left
  if (countDown > 0) {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000)
    const totalSeconds = Math.floor(countDown / 1000)
    const finish = countDown <= 0 ? true : false
    return [days, hours, minutes, seconds, totalSeconds, finish]
  } else {
    const days = 0
    const hours = 0
    const minutes = 0
    const seconds = 0
    const totalSeconds = 0
    const finish = countDown <= 0 ? true : false
    return [days, hours, minutes, seconds, totalSeconds, finish]
  }
}

export { useCountdown }
