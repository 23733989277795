import { HIs, HJson } from "@macashipo/mlib"
import { KMF } from "./More"
export const KMoreOfForm = KMF

export const FlowGetConfigFormFromConfigServer = (
  configV1,
  { defaultValues, fnList, moreDefaultValues }
) => {
  if (
    configV1 === null ||
    configV1 === undefined ||
    (configV1 && Object.keys(configV1).length === 0)
  ) {
    return null
  }
  let _controls = []
  // console.warn("FlowGetConfigFormFromConfigServer:", configV1)
  let _moreOfForm = configV1.More || {}
  if (configV1.More && HIs.isString(configV1.More)) {
    _moreOfForm = HJson.getObj(configV1.More)
  } else if (configV1.Direction && configV1.Direction.startsWith("{")) {
    //bo sung su dung Direction nhu la More vi dang chua the bo sung field More
    let _objDirection = HJson.getObj(configV1.Direction)
    if (_objDirection) {
      _moreOfForm = _objDirection
    }
  }
  let _defaultValues = {
    ...defaultValues,
    ...configV1.DefaultValues,
    ...moreDefaultValues,
  }
  // console.warn("FlowGetConfigFormFromConfigServer:", _defaultValues)
  if (configV1.Data && configV1.Data.length > 0) {
    _controls = configV1.Data.map((v, i) => {
      let _more = {}
      let _configProps = v.Props || v.More
      if (_configProps) {
        if (HIs.isString(_configProps)) {
          _more = HJson.getObj(_configProps)
        } else if (typeof _configProps == "object") {
          _more = { ..._configProps }
        }
      }
      return {
        label: v.Label,
        fieldName: v.FieldName,
        type: v.Type,
        more: {
          ..._more,
          hint: v.Hint,
          required: v.Required,
          disabled: v.Disabled || false,
          optionKey: v.Source || v.SourceField,
        },
      }
    })
  }
  return {
    title: configV1.Title,
    closeMyModalAfterSubmit: true,
    defaultValues: _defaultValues,
    controls: _controls,
    fnList: fnList,
    more: {
      ..._moreOfForm,
    },
  }
}

export const FlowGetConfigControlFromServer = v => {
  if (v && v.hasOwnProperty("Type")) {
    //is v1 server
    let _more = {}
    if (v.Props) {
      if (HIs.isString(v.Props)) {
        _more = HJson.getObj(v.Props)
      } else if (typeof v.Props == "object") {
        _more = { ...v.Props }
      }
    }
    return {
      label: v.Label,
      fieldName: v.FieldName,
      type: v.Type,
      more: {
        ..._more,
        hint: v.Hint,
        required: v.Required,
        disabled: v.Disabled || false,
        optionKey: v.Source || v.SourceField,
      },
    }
  }
  return v
}
