import React from "react"
import { MyLoadingHelpers, CSuppense } from "@macashipo/mlib"

const ML_threedotsvertical = React.lazy(() =>
  import("./Types/threedotsvertical")
)
const ML_arc = React.lazy(() => import("./Types/arc"))
const ML_lds_ellipsis = React.lazy(() => import("./Types/lds_ellipsis"))
const List = {
  arc: ML_arc,
  threedotsvertical: ML_threedotsvertical,
  lds_ellipsis: ML_lds_ellipsis,
}
const MyPageOne = {
  init() {
    if (List) {
      let _keys = Object.keys(List)
      if (_keys && _keys.length > 0) {
        for (let k of Object.keys(List)) {
          MyLoadingHelpers.addTypes(k, props => {
            return CSuppense(props, List[k])
          })
        }
      }
    }
  },
}

export default MyPageOne
