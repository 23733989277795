import React from "react"
import { MyPageHelpers } from "@macashipo/mlib"
const MP_prj_manager = React.lazy(() => import("./prj_manager"))
const MP_prj_info = React.lazy(() => import("./prj_info"))
const MP_prj_api = React.lazy(() => import("./prj_api"))
const MP_prj_task = React.lazy(() => import("./prj_task"))
const MP_prj_dailytask = React.lazy(() => import("./prj_dailytask"))
const MP_prj_dailytask_detail = React.lazy(() =>
  import("./prj_dailytask_detail")
)
const MP_prj_schedule = React.lazy(() => import("./prj_schedule"))
const MP_prj_users = React.lazy(() => import("./prj_users"))
const MP_prj_releases = React.lazy(() => import("./prj_releases"))
const MP_prj_infoenv = React.lazy(() => import("./prj_infoenv"))
const MP_prj_flow = React.lazy(() => import("./prj_flow"))
const List = {
  prj_flow: MP_prj_flow,
  prj_info: MP_prj_info,
  prj_api: MP_prj_api,
  prj_task: MP_prj_task,
  prj_dailytask: MP_prj_dailytask,
  prj_dailytask_detail: MP_prj_dailytask_detail,
  prjmanager: MP_prj_manager,
  prj_users: MP_prj_users,
  prj_releases: MP_prj_releases,
  prj_infoenv: MP_prj_infoenv,
  prj_schedule: MP_prj_schedule,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
