import { createBrowserHistory, createHashHistory } from "history"
import { HLink } from "@macashipo/mlib"
// import { mergeObjIfExist } from "./utils"
// export const getParameterByName = HLink.getParameterByName

// const LinkInfo: any = {
//   usingHash: false,
//   history: null,
// }

// export const initLink = (info: any) => {
//   mergeObjIfExist(LinkInfo, info)
//   if (LinkInfo.usingHash) {
//     LinkInfo.history = createHashHistory()
//   } else {
//     LinkInfo.history = createBrowserHistory()
//   }
//   console.warn("initLink:", LinkInfo)
// }

// export const getHistory = () => {
//   return LinkInfo.history
// }

// export const push = (link: string) => {
//   if (LinkInfo.history) {
//     LinkInfo.history.push(link)
//   } else {
//     window.location.href = link
//   }
// }

// export const openUrl = function (link: string, target: string = "_blank") {
//   if (link) {
//     if (link.startsWith("http")) {
//       return window.open(link, target)
//     } else if (link.indexOf("://") > -1) {
//       return window.open(link, target)
//     }
//   }
//   if (target === "_blank") {
//     window.open(link, target)
//   } else {
//     push(link)
//   }
// }

export const initLink = (info: any) => {
  if (info.usingHash) {
    info.history = createHashHistory()
  } else {
    info.history = createBrowserHistory({
      basename: process.env.PUBLIC_URL,
    })
  }
  HLink.initLink(info)
}
export const openUrl = HLink.openUrl
export const push = HLink.push
export default HLink
