import React, { useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Input, InputGroup, InputGroupText } from "reactstrap"
import { MyIcon } from "src/core/components"
import { PATH } from "src/helpers/paths"
import ApiGeneric from "src/services/apiGeneric"
import { ErrorMessage, Form, Title } from "./Login.styles"
import { login, loginSuccessWithResponse } from "./Login.thunks"
import Container from "./LoginContainer"
import i18next from "i18next"

const mapStateToProps = state => ({
  loading: state.loading,
  isAuthenticated: state.app.isAuthenticated,
})

const mapDispatchToProps = {
  login,
  loginSuccessWithResponse,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

const Login = (props: Props) => {
  const { loading } = props
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")

  const requestForgot = (data = {}) => {
    setLoadingRequest(true)
    ApiGeneric({
      method: "POST",
      url: "Account/ForgotPassword",
      data: data,
    })
      .then(r => {
        setLoadingRequest(false)
        console.warn("r:", r)
        if (r.Msg) {
          setMsgSuccess(r.Msg)
        }
      })
      .catch(err => {
        setLoadingRequest(false)
        console.warn("err:", err)
        if (err && err.msg) {
          setError(err.msg)
        }
      })
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!loading) {
      const payload = {
        Email: email,
      }
      requestForgot(payload)
    }
  }

  if (msgSuccess) {
    return (
      <Container loading={loadingRequest}>
        <Form className="p-5 rounded-sm shadow text-center" onSubmit={submit}>
          <Title style={{ marginTop: "0px" }}>
            <div>{i18next.t("Check your email")}</div>
          </Title>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              marginTop: "1rem",
            }}
          >
            {msgSuccess}
          </div>
        </Form>
      </Container>
    )
  }
  return (
    <Container loading={loadingRequest}>
      <Form className="p-5 rounded-sm shadow text-center" onSubmit={submit}>
        <Title style={{ marginTop: "0px" }}>
          <div>{i18next.t("Forgot password")}</div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              marginTop: "1rem",
            }}
          >
            {i18next.t(
              "Enter your email address and we'll send you a link to reset your password."
            )}
          </div>
        </Title>
        <InputGroup style={{ marginBottom: "1rem" }}>
          <InputGroupText>
            <MyIcon data="type:HiOutlineMail" />
          </InputGroupText>
          <Input
            placeholder={i18next.t("Email address")}
            autoComplete="on"
            disabled={loadingRequest}
            value={email}
            autoFocus
            onChange={ev => {
              setEmail(ev.currentTarget.value)
            }}
          />
        </InputGroup>
        {error && (
          <ErrorMessage className="mb-3 text-danger text-xl-center">
            {error}
          </ErrorMessage>
        )}
        <Button
          type="submit"
          color="primary"
          style={{ width: "100%" }}
          disabled={loadingRequest}
        >
          {i18next.t("Submit")}
        </Button>
        <div
          style={{
            marginTop: "0.5rem",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Link to={PATH.LOGIN}>{i18next.t("Back to Sign in")}</Link>
        </div>
      </Form>
    </Container>
  )
}

export default connector(Login)
