import { HAuth, HConstant, HConfig } from "src/core/helpers"
import { HRegex, HLocalStorage, HUtils, HCF, HJson } from "@macashipo/mlib"
import ApiGeneric from "src/services/apiGeneric"
import { FlowForceUpdateRoutes } from "src/helpers/Flows/Routes"

const kCategoryScreen = "CategoryScreen"
const kFnConvertUIType = "fnConvertUIType"
const kfnUpdateRouteFromLocalCategoryScreen =
  "fnUpdateRouteFromLocalCategoryScreen"
const ConvertType = {
  table: "admin_table",
  tab: "admin_tab",
  dashboard: "admin_dashboard",
  panel: "admin_panel",
  table_list: "admin_table_list",
  table_list_card: "admin_table_list_card",
  table_grid_card: "admin_table_grid_card",
}
const ModuleCategoryScreen = {
  init: HConfig => {
    if (HAuth.authIsLogged()) {
      ModuleCategoryScreen.updateRoutes()
      ModuleCategoryScreen.requestCategoryScreen()
    } else {
      HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.registerAfterLogin, [
        () => {
          console.warn("call request when after login ")
          ModuleCategoryScreen.requestCategoryScreen({ callAfterLogin: true })
        },
      ])
    }
    HCF.setCF(kFnConvertUIType, ModuleCategoryScreen.convertUIType)
    HCF.setCF(
      kfnUpdateRouteFromLocalCategoryScreen,
      ModuleCategoryScreen.updateRoutes
    )
  },
  requestCategoryScreen: ({ callAfterLogin } = {}) => {
    ApiGeneric({
      method: "POST",
      path: "ConfigMenu",
      name: "CategoryScreenList",
      data: {},
    })
      .then(response => {
        if (response.Data) {
          if (callAfterLogin == true) {
            ModuleCategoryScreen.parseData(response.Data, { forceChange: true })
            let _just404 = HCF.getCF("just404")
            console.warn("_just404:", _just404)
            if (_just404 != null) {
              //render route again
              FlowForceUpdateRoutes()
            } else {
              HCF.setCF("needUpdateRoutesIn404", true)
            }
          } else {
            ModuleCategoryScreen.parseData(response.Data)
          }
        }
      })
      .catch(error => {})
  },
  updateRoutes: data => {
    let _newData = data || HLocalStorage.getObj(kCategoryScreen, [])
    HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.addPublicRoutes, [
      _newData,
    ])
  },
  parseData: (data, { forceChange } = {}) => {
    let _newData = []
    if (data && data.length > 0) {
      _newData = data.map((v, i) => {
        if (v.UIUrl && v.UIUrl.indexOf("[") > -1) {
          v.UIUrl = v.UIUrl.replace(HRegex.RGSquareBracket, ":$1")
        }
        ModuleCategoryScreen.convertUIType(v)
        return v
      })
    }
    let _isDifference = true
    if (
      HJson.getString(_newData) == HLocalStorage.getString(kCategoryScreen, "")
    ) {
      _isDifference = false
      console.warn("category screen is not difference")
    }
    if (_isDifference || forceChange === true) {
      HLocalStorage.saveObj(kCategoryScreen, _newData)
      ModuleCategoryScreen.updateRoutes(_newData)
    }
  },
  convertUIType: (configPage = {}) => {
    if (configPage.UIType === null || configPage.UIType === "") {
      configPage.UIType = "Table"
    }
    if (configPage.UIType && ConvertType[configPage.UIType.toLowerCase()]) {
      configPage.UIType = ConvertType[configPage.UIType.toLowerCase()]
    }
    // console.warn("convertUIType", configPage)
  },
}

export default ModuleCategoryScreen
