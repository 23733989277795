import React from "react"
import { MyControlHelpers } from "@macashipo/mlib"
const MCInputCurrency = React.lazy(() => import("./Types/input_currency"))
const MCInputPercentage = React.lazy(() => import("./Types/input_percentage"))
const MCInputText = React.lazy(() => import("./Types/input_text"))
const MCInputNumber = React.lazy(() => import("./Types/input_number"))
const MCGroupControls = React.lazy(() => import("./Types/group_controls"))
const MCInputDate = React.lazy(() => import("./Types/input_date"))
const MCInputDateString = React.lazy(() => import("./Types/input_date_string"))
const MCInputAddress = React.lazy(() => import("./Types/input_address"))
const MCInputCheckbox = React.lazy(() => import("./Types/checkbox_custom"))
const MCReadonly = React.lazy(() => import("./Types/readonly_text"))
const MCButton = React.lazy(() => import("./Types/button"))
const MCCheckInbox = React.lazy(() => import("./Types/check_inbox"))
const MCCheckYesNo = React.lazy(() => import("./Types/check_yes_no"))
const MCRadioOption = React.lazy(() => import("./Types/check_option_radio"))
const MCSelect2IconOption = React.lazy(
  () => import("./Types/select2_icon_option")
)
const MCLink = React.lazy(() => import("./Types/link"))
const MC_input_textarea = React.lazy(() => import("./Types/input_textarea"))
const MC_readonly_html = React.lazy(() => import("./Types/readonly_html"))
const MC_input_textarea_autosize = React.lazy(
  () => import("./Types/input_textarea_autosize")
)
const MC_files = React.lazy(() => import("./Types/mc_files"))
/**v1 page module*/
const v1_mfc_select2 = React.lazy(
  () => import("src/core/modules/v1page/MyControl/Types/select2")
)
/** */
const List = {
  input_currency: MCInputCurrency,
  input_percentage: MCInputPercentage,
  input_text: MCInputText,
  input_number: MCInputNumber,
  group_controls: MCGroupControls,
  input_date: MCInputDate,
  input_date_string: MCInputDateString,
  input_address: MCInputAddress,
  checkbox_custom: MCInputCheckbox,
  readonly_text: MCReadonly,
  button: MCButton,
  check_inbox: MCCheckInbox,
  check_yes_no: MCCheckYesNo,
  check_option_radio: MCRadioOption,
  select2_icon_option: MCSelect2IconOption,
  link: MCLink,
  input_textarea: MC_input_textarea,
  readonly_html2: MC_readonly_html,
  input_textarea_autosize: MC_input_textarea_autosize,
  mc_files: MC_files,
  /**v1 page module*/
  v1_mfc_select2: v1_mfc_select2,
  /** */
}
const MyControlSMSFOne = {
  init() {
    MyControlHelpers.addTypesFromListLazy(List)
  },
}

export default MyControlSMSFOne
