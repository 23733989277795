import React from "react"
import { MyLayoutHelpers } from "@macashipo/mlib"

const MyLayoutArchitect = React.lazy(() => import("./Types/architect/index"))
const ML_wfc_inline = React.lazy(() => import("./Types/wfc_inline"))
const List = {
  admin_panel: MyLayoutArchitect,
  wfc_inline: ML_wfc_inline,
}
const MyLayoutOne = {
  init() {
    MyLayoutHelpers.addTypesFromListLazy(List)
  },
}

export default MyLayoutOne
