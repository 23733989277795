import { HFormat } from "@macashipo/mlib"
import HW from "./W"
//year-month-day
export const isStringDate = str => {
  if (
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z?/.test(str) ||
    /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/.test(str) ||
    /\d{4}-\d{2}-\d{2}/.test(str)
  ) {
    var d = new Date(str)
    return d.toString() !== "Invalid Date"
  }
  return false
}

export const isIsoDate = str => {
  if (
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z?/.test(str) ||
    /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/.test(str)
  ) {
    var d = new Date(str)
    return d.toString() !== "Invalid Date"
  }
  return false
}

export const isHexColorString = str => {
  if (str && typeof str == "string" && str.startsWith("#") && str.length == 7) {
    return true
  }
  return false
}

export const isIphone = () => {
  if (navigator.platform) {
    return /iPad|iPhone|iPod/.test(navigator.platform)
  }
  if (navigator.userAgentData && navigator.userAgentData.platform) {
    return /iPad|iPhone|iPod/.test(navigator.userAgentData.platform)
  }
  if (navigator.userAgent) {
    return /iPad|iPhone|iPod/.test(navigator.userAgent)
  }
  return false
}

export const isMobile = () => {
  return /Mobi|Android/i.test(navigator.userAgent)
}

export const isObject = function (value) {
  return Object(value) === value
}

export const isArray =
  Array.isArray ||
  function (value) {
    // check native isArray first
    return toString.call(value) === "[object Array]"
  }

export const isArguments = function (value) {
  // fallback check is for IE
  return (
    toString.call(value) === "[object Arguments]" ||
    (value != null && typeof value === "object" && "callee" in value)
  )
}
export const isEmpty = function (value) {
  if (isObject(value)) {
    var length = Object.getOwnPropertyNames(value).length
    if (
      length === 0 ||
      (length === 1 && isArray(value)) ||
      (length === 2 && isArguments(value))
    ) {
      return true
    }
    return false
  }
  return value === ""
}

export const getDateWithString = str => {
  var _date = new Date(str)
  return _date
}

//12:05, 01:10
export const isStringHourMinute = str => {
  if (/\d{1,2}:\d{1,2}/.test(str)) {
    var arr = str.split(":")
    if (Number(arr[0]) < 24 && Number(arr[1]) < 60) {
      return true
    }
  }
  return false
}

export const getDateWithHourMinute = str => {
  var _date = new Date()
  if (/\d{1,2}:\d{1,2}/.test(str)) {
    var arr = str.split(":")
    if (Number(arr[0]) < 24 && Number(arr[1]) < 60) {
      _date.setHours(Number(arr[0]))
      _date.setMinutes(Number(arr[1]))
    }
  }
  return _date
}

export const getStringHourMinuteFromDate = date => {
  let _str = ""
  if (date) {
    _str = `${padString(2, String(date.getHours()), "0")}:${padString(
      2,
      String(date.getMinutes()),
      "0"
    )}`
  }
  return _str
}

export const getStringDateFromDate = date => {
  let _str = ""
  if (date) {
    _str = HFormat.format(date.getTime(), {
      type: "date",
      format: "YYYY-MM-DD",
    })
  }
  return _str
}

export const padString = (width, string, padding) => {
  return width <= string.length
    ? string
    : padString(width, padding + string, padding)
}

export const checkPushArrayInObjIfNotExist = (obj, key, value) => {
  if (obj) {
    if (obj[key] == null) {
      obj[key] = []
    }
    if (obj[key] && obj[key].indexOf(value) == -1) {
      obj[key].push(value)
    }
  }
}

export const downloadFile = (fileUrl = "", name) => {
  //Set the File URL.
  var url = fileUrl
  var fileName = name || url.split("/").pop()
  //Create XMLHTTP Request.
  var req = new XMLHttpRequest()
  req.open("GET", url, true)
  req.responseType = "blob"
  req.onload = function () {
    //Convert the Byte Data to BLOB object.
    var blob = new Blob([req.response], { type: "application/octetstream" })
    //Check the Browser type and download the File.
    var isIE = false || !!document.documentMode
    if (isIE) {
      window.navigator.msSaveBlob(blob, fileName)
    } else {
      var url = window.URL || window.webkitURL
      var link = url.createObjectURL(blob)
      var a = document.createElement("a")
      a.setAttribute("download", fileName)
      a.setAttribute("href", link)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
  req.send()
}

export const removeObjectFromArrayByFieldValue = (
  arr = [],
  fieldName,
  fieldValue
) => {
  if (arr && arr.length > 0 && fieldName) {
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i]
      if (obj[fieldName] == fieldValue) {
        arr.splice(i, 1)
        i--
      }
    }
    return arr
  }
  return []
}

export const getUUID = () => {
  return Math.random().toString(36).slice(2, 7)
}

export const parseJwt = token => {
  var base64Url = token.split(".")[1]
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

export const mergeDeepObjects = (obj1, obj2) => {
  const merged = { ...obj1 }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (
        typeof obj2[key] === "object" &&
        !Array.isArray(obj2[key]) &&
        obj1.hasOwnProperty(key) &&
        typeof obj1[key] === "object"
      ) {
        merged[key] = mergeDeepObjects(obj1[key], obj2[key])
      } else {
        merged[key] = obj2[key]
      }
    }
  }

  return merged
}

export const isHasDoubleByte = str => {
  for (var i = 0, n = str.length; i < n; i++) {
    if (str.charCodeAt(i) > 255) {
      return true
    }
  }
  return false
}

export const addClassBody = cls => {
  if (cls) {
    window.document.body.classList.add(cls)
  }
}

export const removeClassBody = cls => {
  if (cls) {
    window.document.body.classList.remove(cls)
  }
}

export const addScript = url => {
  var script = document.createElement("script")
  script.type = "application/javascript"
  script.src = url
  document.head.appendChild(script)
}
//addScript('https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js');

export const getParentNodeHasClass = (node, classParent, deep = 5) => {
  if (node && node.parentNode) {
    if (node.parentNode.classList.contains(classParent)) {
      return node.parentNode
    } else if (deep > 0) {
      return getParentNodeHasClass(node.parentNode, classParent, deep - 1)
    }
  }
  return null
}

export const getQueryFromData = (query, data) => {
  let _queryMore = query || {}
  let _query = {}
  let _obj = Object.keys(_queryMore)
  if (_obj && _obj.length > 0) {
    for (let i of _obj) {
      let _value = _queryMore[i]
      if (data.hasOwnProperty(_value) == true) {
        if (data[_value] != null) {
          _query[i] = data[_value]
        }
      } else {
        _query[i] = _queryMore[i]
      }
    }
  }
  return _query
}

export const addDataToObj = (sourceObj, addedObj) => {
  if (sourceObj && addedObj) {
    let _keys = Object.keys(addedObj)
    for (let k of _keys) {
      if (Array.isArray(addedObj[k])) {
        sourceObj[k] = addedObj[k]
      } else if (typeof addedObj[k] == "object") {
        if (sourceObj[k] == null) {
          //kiem tra neu chua co source[k] thi khoi tao object rong
          sourceObj[k] = {}
        }
        addDataToObj(sourceObj[k], addedObj[k])
      } else {
        sourceObj[k] = addedObj[k]
      }
    }
  }
  return sourceObj
}

export const evalInScope = (js, contextAsScope) => {
  try {
    // eslint-disable-next-line
    return new Function(`with (this) { return (${js}); }`).call(contextAsScope)
    // eslint-disable-next-line
    // return eval(js)
  } catch (error) {
    console.error("evalInScope:", js, contextAsScope, error)
  }
}

export const cloneObj = (obj = {}) => {
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch (error) {
    console.warn("error:", error)
  }
  return null
}
