import React from "react"
import classnames from "classnames"
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap"
import { logW } from "src/core/helpers/log"
import { isFunction, objHasKey } from "src/core/helpers/utils"
import MyForm from "src/core/components/MyForm/MyForm"
import { MyUI, MyLoading, MyPage, HUtils } from "@macashipo/mlib"
import { kfnGetIsInModal } from "src/helpers/fnList"
import Draggable from "react-draggable"
import styled from "styled-components"
interface IProps {
  className?: any
  style?: any
  type: string
  level?: number
}

interface IState {
  isShow?: boolean
  opts?: IConfigModal
  showLoading?: boolean
  size?: any
  customData?: any
}

interface IConfigModal {
  component?: any
  showFooter?: boolean
  showHeader?: boolean
  modalClassName?: string
  modalCentered?: boolean
  modalScrollInside?: boolean
  title?: string
  usingModalBody?: boolean
  usingModalHeader?: boolean
  size?: string
  backdrop?: any | "static"
  fnList?: any
  msg?: any
  msgHtml?: any
  classNameBody?: string
  styleBody?: any
  onClickClose?: any
  onClickOK?: any
  showFooterClose?: boolean
  showFooterOK?: boolean
  titleBtnClose?: string
  titleBtnOK?: string
  colorBtnOK?: string
  configMyPage?: any
  wrapComponentBody?: any //wrap component thay the modalBody, khi usingModalBody = false
  level?: number //level modal,
  nextLevel?: boolean //without level, auto increate
  dragable?: boolean
  keepDragablePosition?: boolean
  onClose?: any
  showChangeSize?: boolean
  showMiniClose?: boolean
  morePropsModal?: any
}

const ListSize = ["xm", "lg", "xl", "full"]
const WrapResize = styled.div`
  position: absolute;
  right: 15px;
  top: -15px;
  z-index: 1;
  & button {
    min-width: 28px;
    padding: 5px;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

class MyModal extends React.Component<IProps, IState> {
  state: any = {
    isShow: false,
    showLoading: false,
    size: null,
    opts: {},
    customData: null,
  }
  _fnList: any = {}
  _cDragable: any = null
  componentDidMount() {
    const { type, level } = this.props
    MyModal.Helpers.initComponent(type, this, level)
    this._fnList = {
      [kfnGetIsInModal]: () => {
        return true
      },
      getModal: () => {
        return this
      },
      hideModal: () => {
        this.hide()
      },
      showLoadingModal: v => {
        this.showLoading(v)
      },
      hideLoadingModal: () => {
        this.hideLoading()
      },
      setCustomData: this.setCustomData,
    }
  }
  show = (opts: any) => {
    this.setState(
      {
        isShow: true,
        opts: { ...MyModal.Helpers.defaultConfigModal, ...opts },
      },
      () => {
        let paddingRight = document.body.style.getPropertyValue("padding-right")
        if (paddingRight == "17px") {
          document.body.style.paddingRight = "8px"
        }
      }
    )
  }
  showLoading = (v = true) => {
    this.setState({
      showLoading: v,
    })
  }
  hideLoading = () => {
    this.setState({
      showLoading: false,
    })
  }
  hide = () => {
    this.setState(
      {
        isShow: false,
        showLoading: false,
        opts: {},
        size: null,
      },
      () => {
        this.onClose()
      }
    )
  }
  toggle = () => {
    this.setState(
      {
        isShow: !this.state.isShow,
      },
      () => {
        const { opts, isShow } = this.state
        if (opts.keepDragablePosition !== true) {
          //reset position dragable
          // console.warn("this._cDragable:", this._cDragable)
          if (this._cDragable && this._cDragable.state) {
            try {
              this._cDragable.state.x = 0
              this._cDragable.state.y = 0
            } catch (err) {
              // Fail silently
            }
          }
        }
        this.onClose()
      }
    )
  }
  onClose = () => {
    const { opts, isShow } = this.state
    if (isShow === false) {
      if (opts?.onClose) {
        opts.onClose()
      }
    }
  }
  setCustomData = data => {
    this.setState({
      customData: data,
    })
  }
  getCustomData = () => {
    return this.state.customData
  }
  _getConfig = key => {
    const { opts } = this.state
    if (objHasKey(opts, key)) {
      return opts[key]
    }
  }
  _renderContent = () => {
    let _ui = null
    const { type } = this.props
    const { opts, isShow } = this.state
    if (isShow !== true) {
      return <div></div>
    }
    switch (type) {
      case MyModal.Helpers.types.myui:
        console.log("mymodal:", opts)
        return (
          <MyUI
            {...opts?.configMyUI}
            {...opts?.modalProps} //bo sung cho giong voi v1
            fnListFromModal={this._fnList} //bo sung cho giong voi v1
            fnList={{ ...this._fnList, ...opts?.configMyUI?.fnList }}
          />
        )
      case MyModal.Helpers.types.mydialog:
        if (opts.component) {
          if (isFunction(opts.component)) {
            return opts.component({
              inMyModal: this,
              fnList: { ...this._fnList, ...opts.fnList },
              configFromModal: opts,
            })
          } else {
            return opts.component
          }
        } else {
          if (opts.msgHtml) {
            return <MyUI type="html" html={opts.msgHtml} />
          } else if (opts.msg) {
            return opts.msg
          }
          return ""
        }
      case MyModal.Helpers.types.component:
        if (opts.component) {
          if (isFunction(opts.component)) {
            return opts.component({
              inMyModal: this,
              fnList: { ...this._fnList, ...opts.fnList },
              configFromModal: opts,
            })
          } else {
            return opts.component
          }
        } else {
          logW("Missing component")
          return <div></div>
        }
      case MyModal.Helpers.types.mypage:
        return (
          <MyPage
            configPage={opts?.configMyPage}
            fnList={{
              ...this._fnList,
              ...opts.fnList,
              ...opts.configMyPage.fnList,
            }}
          />
        )
      case MyModal.Helpers.types.cellinmodal:
        return <MyUI {...opts?.configMyUI} />
      case MyModal.Helpers.types.myform:
        if (opts.configForm) {
          return (
            <MyForm
              inMyModal={this}
              configFromModal={opts}
              configForm={opts.configForm}
              fnList={{
                ...this._fnList,
                ...opts.fnList,
                ...opts.configForm.fnList,
              }}
            />
          )
        } else {
          logW("Missing configForm")
          return <div></div>
        }
    }
    return _ui
  }
  _renderBody = () => {
    const { opts } = this.state
    if (opts.usingModalBody === true) {
      return (
        <ModalBody className={opts.classNameBody || ""} style={opts.styleBody}>
          {this._renderContent()}
        </ModalBody>
      )
    }
    if (opts.wrapComponentBody) {
      let TagBody = opts.wrapComponentBody
      return <TagBody>{this._renderContent()}</TagBody>
    }
    return this._renderContent()
  }
  _renderHeaderContent = () => {
    const { opts } = this.state
    return opts.title
  }
  _renderHeader = () => {
    const { opts } = this.state
    if (opts.showHeader === true) {
      return (
        <ModalHeader
          toggle={this.toggle}
          className={classnames({
            "a-dragable-handle": opts.dragable === true,
          })}
        >
          {this._renderHeaderContent()}
        </ModalHeader>
      )
    }
    return null
  }
  _renderFooter = () => {
    const { opts } = this.state
    if (opts.showFooter === true) {
      return (
        <ModalFooter>
          {opts.showFooterClose && (
            <Button
              color="secondary"
              onClick={() => {
                if (opts.onClickClose) {
                  opts.onClickClose(this)
                } else {
                  this.hide()
                }
              }}
            >
              {opts.titleBtnClose || "Close"}
            </Button>
          )}
          {opts.showFooterOK && (
            <Button
              color={opts.colorBtnOK || "primary"}
              onClick={() => {
                if (opts.onClickOK) {
                  opts.onClickOK(this)
                }
              }}
            >
              {opts.titleBtnOK || "OK"}
            </Button>
          )}
        </ModalFooter>
      )
    }
    return null
  }
  _getClassname = () => {
    const { opts, size } = this.state
    return classnames(opts.modalClassName, {
      "modal-dialog-centered": opts.modalCentered,
      [`modal-${size || opts.size}`]: true, //sm,lg,xl,full,overscreen
      "modal-dialog-scrollable": opts.modalScrollInside,
      [`modal-level-${opts.level}`]: opts.level != null,
      [`modal-dragable`]: opts.dragable === true,
    })
  }
  _renderOverlay = () => {
    if (this.state.showLoading === true) {
      return (
        <div className="a-overlay-center">
          <MyLoading />
        </div>
      )
    }
  }
  _renderResize = () => {
    const { opts, size } = this.state
    if (opts.showChangeSize === true) {
      return (
        <WrapResize className="btn-group">
          <Button
            title="Change size to small"
            color="primary"
            onClick={() => {
              this.setState({
                size: "xm",
              })
            }}
          >
            <i className="fa fa-window-maximize" style={{ fontSize: "8px" }} />
          </Button>
          <Button
            title="Change size to large"
            color="primary"
            onClick={() => {
              this.setState({
                size: "lg",
              })
            }}
          >
            <i className="fa fa-window-maximize" style={{ fontSize: "12px" }} />
          </Button>
          <Button
            title="Change size to extra large"
            color="primary"
            onClick={() => {
              this.setState({
                size: "full",
              })
            }}
          >
            <i className="fa fa-window-maximize" style={{ fontSize: "16px" }} />
          </Button>
          {opts.showMiniClose === true && (
            <Button
              title="Close"
              color="danger"
              onClick={() => {
                this.hide()
              }}
            >
              <i className="fa fa-times" style={{ fontSize: "14px" }} />
            </Button>
          )}
        </WrapResize>
      )
    }
    return null
  }
  render() {
    const { isShow, opts } = this.state
    const { backdrop } = opts
    const WrapModal = opts.dragable === true ? Draggable : React.Fragment
    const PropsWrapModal =
      opts.dragable === true
        ? {
            handle: ".a-dragable-handle",
            ref: r => {
              this._cDragable = r
            },
          }
        : {}
    return (
      <WrapModal {...PropsWrapModal}>
        <Modal
          isOpen={isShow === true}
          fade
          backdrop={backdrop != null ? backdrop : true}
          toggle={this.toggle}
          className={this._getClassname()}
          // modalTransition={{
          //   baseClass: "aaa",
          // }}
          // keyboard={false}//chan viec press esc to hide modal
          {...opts.morePropsModal}
        >
          {this._renderResize()}
          {this._renderHeader()}
          {this._renderBody()}
          {this._renderFooter()}
          {this._renderOverlay()}
        </Modal>
      </WrapModal>
    )
  }
  static Helpers = {
    defaultConfigModal: {
      usingModalBody: true,
      showHeader: true,
      size: "lg",
    },
    types: {
      component: "component",
      myui: "myui",
      myform: "myform",
      mypage: "mypage",
      lightbox: "lightbox",
      mydialog: "mydialog",
      cellinmodal: "cellinmodal",
      globalloading: "globalloading",
    },
    initComponent: (type, component, level: any = null) => {
      if (type && component) {
        if (level != null) {
          MyModal.Helpers.components[`${type}_${level}`] = component
        } else {
          MyModal.Helpers.components[type] = component
        }
      }
    },
    components: {},
  }
  static showWithType = (type, opts) => {
    let _type = type
    if (opts) {
      if (opts.level != null) {
        _type = `${_type}_${opts.level}`
      } else if (opts.nextLevel === true) {
        let _level = 1
        let _listModal = document.querySelectorAll("body>div>div>.modal")
        if (_listModal && _listModal.length > 0) {
          _level = _listModal.length + 1
          opts.level = _level
          _type = `${_type}_${_level}`
        }
      }
    }
    if (MyModal.Helpers.components[_type]) {
      MyModal.Helpers.components[_type].show(opts)
    }
  }
  static showFromMyUI = (configMyUI, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.myui, {
      configMyUI: configMyUI,
      ...configModal,
    })
  }
  static showFromMyPage = (configMyPage, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.mypage, {
      configMyPage: configMyPage,
      ...configModal,
    })
  }
  static showFromMyForm = (configForm, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.myform, {
      configForm: configForm,
      backdrop: "static",
      ...configModal,
    })
  }
  static showFromComponent = (component, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.component, {
      component: component,
      ...configModal,
    })
  }
  static showMyDialogWithInput = (
    { placeHolder, defaultValue }: any = {},
    configModal: IConfigModal | null
  ) => {
    MyModal.showWithType(MyModal.Helpers.types.mydialog, {
      size: "xs",
      showFooter: true,
      showFooterOK: true,
      showFooterClose: true,
      backdrop: "static",
      component: ({ fnList }) => {
        return (
          <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
            <input
              type="text"
              className="form-control"
              placeholder={placeHolder}
              defaultValue={defaultValue}
              onChange={ev => {
                HUtils.runFuntion(fnList, "setCustomData", [
                  ev.currentTarget.value,
                ])
              }}
            />
          </div>
        )
      },
      ...configModal,
    })
  }

  static showMyDialogWithConfigModal = (configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.mydialog, {
      ...configModal,
    })
  }

  static showMyDialog = (configDialog, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.mydialog, {
      configDialog,
      ...configModal,
    })
  }
  static showCellInModal = (
    propsCell: any,
    configModal: IConfigModal | null
  ) => {
    MyModal.showWithType(MyModal.Helpers.types.cellinmodal, {
      configMyUI: {
        type: "_wrapCellOnModal",
        ...propsCell,
      },
      size: "xl",
      showHeader: true,
      showFooter: false,
      ...configModal,
    })
  }
  static showConfirm = (configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.mydialog, {
      size: "xs",
      showFooter: true,
      showFooterOK: true,
      showFooterClose: true,
      backdrop: "static",
      component: () => {
        if (configModal?.msg) {
          return (
            <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
              {configModal?.msg}
            </div>
          )
        } else if (configModal?.msgHtml) {
          return (
            <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
              <MyUI type="html" html={configModal?.msgHtml} />
            </div>
          )
        }
      },
      ...configModal,
    })
  }
  static showGlobalLoading = (configModal, ComponentClass) => {
    const GlobalLoading = () => {
      return (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MyLoading type="arc" color="#ffffff" />
        </div>
      )
    }
    MyModal.showWithType(MyModal.Helpers.types.globalloading, {
      size: "xs",
      showFooter: false,
      showHeader: false,
      backdrop: "static",
      modalClassName: "modal-transparent",
      usingModalBody: false,
      morePropsModal: {
        modalTransition: { baseClass: "", timeout: 0 },
      },
      wrapComponentBody: ComponentClass || GlobalLoading,
      ...configModal,
    })
  }
  static hideGlobalLoading = () => {
    if (MyModal.Helpers.components[MyModal.Helpers.types.globalloading]) {
      MyModal.Helpers.components[MyModal.Helpers.types.globalloading].hide()
    }
  }
  static hideModalComponent = () => {
    if (MyModal.Helpers.components[MyModal.Helpers.types.component]) {
      MyModal.Helpers.components[MyModal.Helpers.types.component].hide()
    }
  }
  static hideModalMyUI = () => {
    if (MyModal.Helpers.components[MyModal.Helpers.types.myui]) {
      MyModal.Helpers.components[MyModal.Helpers.types.myui].hide()
    }
  }
  static hideComponent = () => {
    if (MyModal.Helpers.components["component"]) {
      MyModal.Helpers.components["component"].hide()
    }
  }
  static hideMyPage = () => {
    if (MyModal.Helpers.components["mypage"]) {
      MyModal.Helpers.components["mypage"].hide()
    }
  }
  static hideModal = type => {
    if (MyModal.Helpers.components[type]) {
      MyModal.Helpers.components[type].hide()
    }
  }
  static renderHeader = ({
    cModal,
    styleModalHeader,
    title,
    hideClose,
  }: {
    cModal?: any
    styleModalHeader?: any
    title?: any
    hideClose?: boolean
  } = {}) => {
    let _moreProps: any = {}
    if (hideClose !== true) {
      _moreProps.toggle = () => {
        if (cModal && cModal.hide) {
          cModal.hide()
        }
      }
    }
    return (
      <ModalHeader
        {..._moreProps}
        style={{
          padding: "0px",
          paddingBottom: "1rem",
          marginBottom: "1rem",
          ...styleModalHeader,
        }}
      >
        {typeof title === "function" ? title({}) : title}
      </ModalHeader>
    )
  }
}

export default MyModal
