import React from "react"
import { MyPageHelpers } from "@macashipo/mlib"

const PageARowForm = React.lazy(() => import("./Types/PageARowForm"))

const List = {
  a_row_form: PageARowForm,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
