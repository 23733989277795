import React from "react"
import {
  MyLib,
  MyPageHelpers,
  HCF,
  HUtils,
  HColor,
  MyLoading,
  CSuppense,
} from "@macashipo/mlib"
import { MyModal } from "src/core/components"
import styled from "styled-components"

const Form_Basic = React.lazy(() => import("./DemoForms/basic"))
const Form_Modal = React.lazy(() => import("./DemoForms/modal"))
const Form_LocalStorage = React.lazy(() => import("./DemoForms/localstorage"))
const Form_HideCondttion = React.lazy(() => import("./DemoForms/hideCondition"))

const WrapItem = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  &.a-nocard {
    padding: 0px;
  }
`
class Dev_Demo extends React.Component {
  render() {
    return (
      <div>
        {/* <WrapItem className="a-nocard">{CSuppense({}, Form_Basic)}</WrapItem> */}
        {/* <WrapItem className="card">{CSuppense({}, Form_Modal)}</WrapItem> */}
        <WrapItem className="a-nocard">
          {CSuppense({}, Form_HideCondttion)}
        </WrapItem>
      </div>
    )
  }
}

//add page dev
Dev_Demo.initPageDev = () => {
  MyPageHelpers.addTypesFromListLazy({
    dev_myform: Dev_Demo,
    dev_myform_localstorage: Form_LocalStorage,
  })
  HUtils.runFuntion(HCF.CF, "addPublicRoutes", [
    [
      {
        Id: "dev_myform",
        UIUrl: "/dev/myform",
        UIType: "dev_myform",
      },
      {
        Id: "dev_myform_localstorage",
        UIUrl: "/dev/myform_localstorage",
        UIType: "dev_myform_localstorage",
      },
    ],
  ])
}

export default Dev_Demo
