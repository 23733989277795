import { fnListMyPage } from "src/helpers/fnList"
import {
  HLink,
  HLocalStorage,
  MyUI,
  MyUIHelpers,
  CErrorBoundary,
  HUtils,
} from "@macashipo/mlib"
import { KConfigPagePage, KOnePageMore } from "../K"
import { getUUID } from "../U"
import HI18, { loadExtLng } from "src/core/helpers/i18n"
//share query for options, list, query api, form in header button, query all in cell, query all for detail table, query for child content page
export const FlowGetSharedQueryOfPage = (
  configPage,
  {
    onePage,
    match,
    defaultQuery,
    fnListOfParent,
    moreQuery,
    queryFilter,
    querySort,
    from,
    withoutQueryFilter,
    withoutQuerySort,
  } = {}
) => {
  let _query = defaultQuery || {}
  // console.warn("query:", _query)
  //from url neu co
  if (match && match.params) {
    _query = { ..._query, ...match.params }
  }
  //Bo sung QuerySearch
  if (window.location.search) {
    _query.QuerySearch = HLink.getAllParamOnUrl()
  }

  //ke thua tu parent
  if (fnListOfParent) {
    let _queryFromParent = fnListMyPage.fnGetSharedRequestData(fnListOfParent, [
      { from },
    ])
    if (_queryFromParent) {
      _query = { ..._query, ..._queryFromParent }
    }
  }
  //from configPage.RequestData
  if (configPage && configPage.RequestData) {
    _query = { ..._query, ...configPage.RequestData }
  }
  //from filter, sort neu co
  if (queryFilter && withoutQueryFilter !== true) {
    _query = { ..._query, ...queryFilter }
  }
  if (querySort && withoutQuerySort !== true) {
    _query = { ..._query, ...querySort }
  }

  //from configPage
  if (configPage && configPage.Id) {
    _query.ScreenGUID = configPage.Id
  }
  //from moreQuery
  if (moreQuery) {
    _query = { ..._query, ...moreQuery }
  }
  // console.warn("FlowGetSharedQueryOfPage:", _query, from)
  return _query
}

export const FlowGetMoreQueryFilterOfTable = () => {
  let _query = {}
  //export FilterQuery
  return _query
}

export const FlowGetMoreQuerySortOfTable = () => {
  let _query = {}
  //export SortQuery
  return _query
}

export const FlowGetConfigInLocalStorageOfPage = (configPage = {}) => {
  let _keyLocal = `${configPage.ScreenCode}-config`
  let _config = HLocalStorage.getObj(_keyLocal)
  return _config
}

export const FlowUpdateConfigInLocalStorageOfPageWithKey = (
  configPage = {},
  key,
  value
) => {
  let _keyLocal = `${configPage.ScreenCode}-config`
  let _config = HLocalStorage.getObj(_keyLocal) || {}
  if (key) {
    _config[key] = value
    HLocalStorage.saveObj(_keyLocal, _config)
  }
}

export const FlowUpdateSizeOfPageFromLocal = onePage => {
  //check sizePerPage, get from local, 20 is default sizePerPage, chi lam voi phan trang client
  let _defaulPageSize = 20
  console.log("FlowUpdateSizeOfPageFromLocal", onePage)
  if (onePage && onePage._configPagination._pageSize == _defaulPageSize) {
    let _configPageInLocal = FlowGetConfigInLocalStorageOfPage(
      onePage._configPage._configPageServer
    )
    if (
      _configPageInLocal &&
      _configPageInLocal.sizePerPage != null &&
      typeof _configPageInLocal.sizePerPage == "number" &&
      _configPageInLocal.sizePerPage != _defaulPageSize &&
      _configPageInLocal.sizePerPage > 0
    ) {
      onePage._configPagination._pageSize = _configPageInLocal.sizePerPage
    }
  }
}

export const FlowParseOptions = (options, onePage) => {
  if (options) {
    let _UITypeContents = HUtils.get(options, "Configs.UITypeContents")
    if (_UITypeContents) {
      FlowParseUITypeContent(_UITypeContents)
    }
    let _configI18n = HUtils.get(onePage, "_oneScreenConfig._page.i18n")
    if (_configI18n) {
      let _screenCode = HUtils.get(
        onePage,
        "_configPage._configPageServer.ScreenCode"
      )
      if (_screenCode) {
        Object.keys(_configI18n).forEach((v, i) => {
          loadExtLng({
            lng: v,
            ns: _screenCode,
            resources: _configI18n[v],
          })
        })
      }
    }
  }
}

export const FlowParseUITypeContent = listObj => {
  if (listObj) {
    let _keys = Object.keys(listObj)
    for (let k of _keys) {
      if (MyUIHelpers.Types[k] == null) {
        MyUIHelpers.addTypes(k, props => {
          return (
            <CErrorBoundary configObj={listObj[k]}>
              <MyUI {...props} type="o" configObj={listObj[k]} />
            </CErrorBoundary>
          )
        })
      } else {
        console.warn("addListUI exist key:", k)
      }
    }
  }
}

export const FlowInitPageId = onePage => {
  if (onePage) {
    if (onePage[KOnePageMore._pageUUID] == null) {
      onePage[KOnePageMore._pageUUID] = getUUID()
    }
  }
}

export const FlowRenderUIWithConfig = (config = {}, fnList) => {
  // console.log("FlowRenderUIWithConfig:", config)
  if (config && config.show) {
    let _type = config.type
    let _style = config.style || {}
    let _className = config.className
    let _morePropsFromExtraData = {}
    if (config.morePropsFromExtraData) {
      let _extraData = fnListMyPage.fnGetExtraDataTable(fnList)
      if (_extraData) {
        for (let k of Object.keys(config.morePropsFromExtraData)) {
          _morePropsFromExtraData[k] = _extraData[k]
        }
      }
    }
    if (_type) {
      return (
        <MyUI
          type={_type}
          style={_style}
          className={_className}
          fnList={fnList}
          config={config}
          {...config.props}
          {..._morePropsFromExtraData}
        />
      )
    }
  }
  return null
}

export const FlowRenderUIFromConfigPage = (key, fnList = {}) => {
  if (key) {
    let _config = fnListMyPage.fnGetConfigPagePage(fnList, [key])
    if (Array.isArray(_config)) {
      return (
        <div className={`a-area-${key}}`}>
          {_config.map((v, i) => {
            return <div key={i}>{FlowRenderUIWithConfig(v, fnList)}</div>
          })}
        </div>
      )
    } else {
      return FlowRenderUIWithConfig(_config, fnList)
    }
  }
}

export const FlowParseConfigInConfigPage = (
  configInConfigPage = {},
  df = {},
  opts = {}
) => {
  let _config = { ...df, ...configInConfigPage }
  if (opts.isRootLevel) {
    if (_config.hideSideMenu) {
      window.document.body.classList.add("no-sidebar")
    } else {
      window.document.body.classList.remove("no-sidebar")
    }
  }
}
