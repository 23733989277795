import React from "react"
class MyUI_Tag extends React.Component {
  render() {
    const { style, className, children, m } = this.props
    const Tag = this.props.tag || "div"
    if (Tag) {
      return (
        <Tag style={style} className={className} {...m}>
          {children}
        </Tag>
      )
    }
    console.warn("Missing tag:", this.prop)
    return <div></div>
  }
}

export default MyUI_Tag
