import * as types from "./sidemenu.constants"
import produce from "immer"

const initialState = {
  textSearch: "",
  modeSearch: false,
}

export const SideMenuReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SIDEMENU_UPDATE_MODE_SEARCH:
        draft.modeSearch = action.mode
        break
      case types.SIDEMENU_UPDATE_TEXT_SEARCH:
        draft.textSearch = action.textSearch
        break
      default:
        return state
    }
  })
