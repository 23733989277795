import * as types from "./App.constants"

export const logout = () => ({
  type: types.LOGOUT,
})

export const toggleSideNav = () => ({
  type: types.SIDE_NAV_CLOSE,
})

export const sideNavUpdate = data => ({
  type: types.SIDE_NAV_UPDATE,
  items: data,
})

export const fetchFirstSettingPending = () => {
  return {
    type: types.FETCH_FS_PENDING,
  }
}

export const fetchFirstSettingSuccess = data => {
  return {
    type: types.FETCH_FS_SUCCESS,
    dataFirstSetting: data,
  }
}

export const fetchFirstSettingError = error => {
  return {
    type: types.FETCH_FS_ERROR,
    error: error,
  }
}

export const updateHeaderTitle = title => ({
  type: types.HEADER_TITLE_UPDATE,
  title: title,
})
