import React from "react"
import { MyTableFilterControlHelpers, HFormat } from "@macashipo/mlib"
import i18next from "i18next"
import moment from "moment"

const MTFCSelect2 = React.lazy(() => import("./Types/select2"))
const MTFCSelect2Content = React.lazy(() => import("./Types/select2content"))
const MTFCSwitch = React.lazy(() => import("./Types/switch"))
const MTFCCheckbox = React.lazy(() => import("./Types/checkbox"))
const MTFCDateFromTo = React.lazy(() => import("./Types/datefromto"))
const MTFCDateFromToText = React.lazy(() => import("./Types/datefromto_text"))
const MTFCText = React.lazy(() => import("./Types/text"))
const MTFC_select2_multi = React.lazy(() => import("./Types/select2_multi"))
const MTFC_select2_modal = React.lazy(() => import("./Types/select2_modal"))
const MTFC_select2_modal_api = React.lazy(() =>
  import("./Types/select2_modal_api")
)
const MTFC_select_month = React.lazy(() => import("./Types/select_month"))
const MTFC_month_year = React.lazy(() => import("./Types/month_year"))
const MTFC_select = React.lazy(() => import("./Types/select"))
const List = {
  select: MTFC_select,
  month_year: MTFC_month_year,
  select_month: MTFC_select_month,
  datefromto: MTFCDateFromTo,
  datefromto_text: MTFCDateFromToText,
  checkbox: MTFCCheckbox,
  select2: MTFCSelect2,
  select2_multi: MTFC_select2_multi,
  select2_modal: MTFC_select2_modal,
  select2_modal_api: MTFC_select2_modal_api,
  select2content: MTFCSelect2Content,
  switch: MTFCSwitch,
  text: MTFCText,
}
const MyTableFilterControlOne = {
  init() {
    MyTableFilterControlHelpers.addTypesFromListLazy(List)
    if (MyTableFilterControlHelpers.ConfigByType == null) {
      MyTableFilterControlHelpers.ConfigByType = {}
    }
    if (MyTableFilterControlHelpers.ConfigByType) {
      MyTableFilterControlHelpers.ConfigByType["_default"] = {
        getFilterOut: (value, oneFilter) => {
          // console.log("getFilterOut _default", value, oneFilter)
          if (value === null) {
            //neu value la null, set currentFilter = null
            return null
          }
          let _isServer = false
          if (oneFilter.more) {
            if (oneFilter.more.isServer === true) {
              _isServer = true
            }
          }
          return {
            t: oneFilter.type,
            fieldName: oneFilter.fieldName,
            isServer: _isServer,
            more: oneFilter.more,
            title: oneFilter.title,
            value: value,
            valueDisplay: value,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["select2"] = {
        getFilterOut: (value, oneFilter) => {
          console.warn("getFilterOut:", value, oneFilter)
          if (value === null) {
            //neu value la null, set currentFilter = null
            return null
          }
          //multi
          if (Array.isArray(value)) {
            if (value.length > 0) {
              let _charSplitArray = ","
              let _valueDisplay = value
                .map(v => {
                  return v.Text
                })
                .join(_charSplitArray)
              let _valueFilterServer = value
                .map(v => {
                  return v.Value
                })
                .join(_charSplitArray)
              let _valueFilterClient = value.map(v => {
                return v.Value
              })
              return {
                ...MyTableFilterControlHelpers.ConfigByType[
                  "_default"
                ].getFilterOut(value, oneFilter),
                type: "ArrayFilter",
                value: value,
                valueDisplay: _valueDisplay,
                valueFilterServer: _valueFilterServer,
                valueFilterClient: _valueFilterClient,
              }
            } else {
              return null
            }
          }
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            type: "SelectFilter",
            // cond: "eq",//an di vi se bo sung truc tiep vao type SelectFilter
            value: {
              Text: value.Text,
              Value: value.Value,
            },
            valueDisplay: value.Text,
            valueFilterServer: value.Value,
            valueFilterClient: value.Value,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["month_year"] = {
        getCustomFilter: filterOut => {
          return {
            type: "CustomFilter",
            value: {
              callbackParameters: filterOut,
              callback: (targetVal, itemP) => {
                if (
                  targetVal != null &&
                  typeof targetVal == "number" &&
                  itemP &&
                  typeof itemP.value == "number"
                ) {
                  let _startMili = itemP.value
                  let _endMili = moment(_startMili).endOf("month").format("x")
                  console.log("filter month_year", _startMili, _endMili)
                  if (targetVal >= _startMili && targetVal <= _endMili) {
                    return true
                  }
                }
                return false
              },
            },
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["select2_multi"] = {
        getFilterOut: (value, oneFilter) => {
          return MyTableFilterControlHelpers.ConfigByType[
            "select2"
          ].getFilterOut(value, oneFilter)
        },
      }
      MyTableFilterControlHelpers.ConfigByType["select2_modal"] = {
        getFilterOut: (value, oneFilter) => {
          return MyTableFilterControlHelpers.ConfigByType[
            "select2"
          ].getFilterOut(value, oneFilter)
        },
      }
      MyTableFilterControlHelpers.ConfigByType["select2_modal_api"] = {
        getFilterOut: (value, oneFilter) => {
          return MyTableFilterControlHelpers.ConfigByType[
            "select2"
          ].getFilterOut(value, oneFilter)
        },
      }
      MyTableFilterControlHelpers.ConfigByType["select_month"] = {
        getFilterOut: (value, oneFilter) => {
          return MyTableFilterControlHelpers.ConfigByType[
            "select2"
          ].getFilterOut(value, oneFilter)
        },
      }
      MyTableFilterControlHelpers.ConfigByType["select2content"] = {
        getFilterOut: (value, oneFilter) => {
          if (value === null) {
            //neu value la null, set currentFilter = null
            return null
          }
          console.warn("getFilterOut4554:", value, oneFilter)
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: {
              Text: value.Text,
              Value: value.Value,
            },
            type: value.Filter.type,
            valueDisplay: value.Text,
            valueFilterServer: value.Filter.value,
            valueFilterClient: value.Filter.value,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["switch"] = {
        getFilterOut: (value, oneFilter) => {
          // console.log("getFilterOut:", value, oneFilter)
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: value,
            valueDisplay: "Yes",
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["checkbox"] = {
        getFilterOut: (value, oneFilter) => {
          // console.log("getFilterOut:", value, oneFilter)
          if (value === null) {
            //neu value la null, set currentFilter = null
            return null
          }
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: value,
            valueDisplay: `${
              value === true
                ? i18next.t("Yes")
                : value === false
                ? i18next.t("No")
                : ""
            }`,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["datefromto"] = {
        getCustomFilter: itemOut => {
          return {
            type: "CustomFilter",
            value: {
              callbackParameters: itemOut,
              callback: (targetVal, itemP) => {
                if (
                  targetVal != null &&
                  typeof targetVal == "number" &&
                  itemP &&
                  Array.isArray(itemP.value) &&
                  itemP.value.length > 1
                ) {
                  let _startMili = new Date(itemP.value[0]).getTime()
                  let _endMili = new Date(itemP.value[1]).getTime()
                  if (targetVal >= _startMili && targetVal <= _endMili) {
                    return true
                  }
                }
                return false
              },
            },
          }
        },
        getFilterOut: (value, oneFilter) => {
          if (value === null) {
            //neu value la null, set currentFilter = null
            return null
          }
          console.warn("getFilterOut:", value, oneFilter)
          let _testDisplay = ""
          let _valueFilterServer = {}
          if (value && value.length > 1) {
            let _from = HFormat.format(value[0], {
              type: "date",
              format: "DD/MM/YYYY",
            })
            let _to = HFormat.format(value[1], {
              type: "date",
              format: "DD/MM/YYYY",
            })
            _valueFilterServer = {
              start: new Date(value[0]).getTime(),
              end: new Date(value[1]).getTime(),
            }
            _testDisplay = `${_from} - ${_to}`
          }
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: value,
            valueDisplay: _testDisplay,
            valueFilterServer: _valueFilterServer,
          }
        },
      }
      MyTableFilterControlHelpers.ConfigByType["datefromto_text"] = {
        getFilterOut: (value, oneFilter) => {
          if (value === null) {
            //neu value la null, set currentFilter = null
            return null
          }
          // console.log("getFilterOut:", value, oneFilter)
          let _testDisplay = ""
          let _valueFilterServer = {}
          if (value && value.length > 1) {
            let _from = HFormat.format(value[0], {
              type: "date",
              format: "DD/MM/YYYY",
            })
            let _to = HFormat.format(value[1], {
              type: "date",
              format: "DD/MM/YYYY",
            })
            _valueFilterServer = {
              start: HFormat.format(new Date(value[0]).getTime(), {
                type: "date",
                format: "YYYY-MM-DD",
              }),
              end: HFormat.format(new Date(value[1]).getTime(), {
                type: "date",
                format: "YYYY-MM-DD",
              }),
            }
            _testDisplay = `${_from} - ${_to}`
          }
          return {
            ...MyTableFilterControlHelpers.ConfigByType[
              "_default"
            ].getFilterOut(value, oneFilter),
            value: value,
            valueDisplay: _testDisplay,
            valueFilterServer: _valueFilterServer,
          }
        },
      }
    }
  },
}

export default MyTableFilterControlOne
