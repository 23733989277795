import { HFormat, HIs, HCF, HColor, HUtils } from "@macashipo/mlib"
import HC from "./c"
import { KConfigApp } from "src/helpers/K"
import moment from "moment"
import NumeralJs from "src/libs/NumeralJs/numeral"

const getValueOfFormat = (value: any, configFormat: any) => {
  if (configFormat && configFormat.fnList && configFormat.fnList.getValue) {
    return configFormat.fnList.getValue(configFormat)
  }
  return value
}

HFormat.Types["date"] = {
  format: (value: any, configFormat: any) => {
    // console.warn("format date:", value, configFormat)
    if (value != null && HIs.isDateValue(value)) {
      return moment(value).format(
        configFormat.format || HC.getDefaultFormatDate()
      )
    }
    return ""
  },
}

HFormat.Types["fromnow"] = {
  format: (value: any, configFormat: any) => {
    // console.warn("format date:", value, configFormat)
    if (value != null && HIs.isDateValue(value)) {
      return moment(value).fromNow()
    }
    return ""
  },
}

HFormat.Types["datetime"] = {
  format: (value: any, configFormat: any) => {
    // console.warn("format date:", value, configFormat)
    if (value != null && HIs.isDateValue(value)) {
      return moment(value).format(
        configFormat.format || HC.getDefaultFormatDateTime()
      )
    }
    return ""
  },
}

HFormat.Types["numeral"] = {
  format: (value: any, configFormat: any) => {
    let _format = configFormat.format || HCF.getCF(KConfigApp.numeralFormat)
    // console.warn("format numeral:", value, configFormat, _format)
    return NumeralJs(value).format(_format)
  },
}

HFormat.Types["currency"] = {
  format: (value: any, configFormat: any) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      let _configCurrencyLocaleOptions = HCF.getCF(
        KConfigApp.currencyLocaleOptions
      )
      let _s = _num.toLocaleString(HCF.getCF(KConfigApp.numberLocale), {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ..._configCurrencyLocaleOptions,
      })

      if (_configCurrencyLocaleOptions && _configCurrencyLocaleOptions.suffix) {
        return _s + _configCurrencyLocaleOptions.suffix
      } else if (
        _configCurrencyLocaleOptions &&
        _configCurrencyLocaleOptions.prefix
      ) {
        return _s + _configCurrencyLocaleOptions.prefix
      }
      return _s
    }
    return ""
  },
}

HFormat.Types["decimal"] = {
  format: (value: any, configFormat: any) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      let _s = _num.toLocaleString(HCF.getCF(KConfigApp.numberLocale), {
        style: "decimal",
      })
      return _s
    }
    return ""
  },
}

HFormat.Types["number"] = {
  format: (value: any, configFormat: any) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      return String(_num)
    }
    return ""
  },
}

HFormat.Types["numColByWidth"] = {
  format: (value: any, configFormat: any) => {
    // console.warn("format:", value, configFormat)
    if (HIs.isNumber(value)) {
      return value
    } else if (HIs.isObject(value)) {
      let _objRes = value
      if (value.default) {
        _objRes = value.default
      }
      const windowWidth = (window && window.innerWidth) || Infinity
      let matchedBreakpoint = Infinity
      for (let k of Object.keys(value)) {
        const optBreakpoint = parseInt(k)
        const isCurrentBreakpoint =
          optBreakpoint > 0 && windowWidth <= optBreakpoint
        if (isCurrentBreakpoint && optBreakpoint < matchedBreakpoint) {
          matchedBreakpoint = optBreakpoint
          _objRes = value[k]
        }
      }
      if (HIs.isNumber(_objRes)) {
        return _objRes
      }
    }
    return configFormat.defaultValue || 5
  },
}

//copy from v1
HFormat.Types["number_locale"] = {
  format: (value, configFormat) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      let _numberLocale = HCF.getCF(KConfigApp.numberLocale)
      if (configFormat.numberLocale) {
        _numberLocale = configFormat.numberLocale
      }
      let _s = _num.toLocaleString(_numberLocale, {
        style: configFormat.style != null ? configFormat.style : "decimal",
        currency: configFormat.currency != null ? configFormat.currency : "USD",
        minimumFractionDigits:
          configFormat.minimumFractionDigits != null
            ? configFormat.minimumFractionDigits
            : 0,
        maximumFractionDigits:
          configFormat.maximumFractionDigits != null
            ? configFormat.maximumFractionDigits
            : 2,
      })
      return _s
    }
    return ""
  },
}
HFormat.Types["configByValue"] = {
  format: (value, configFormat) => {
    let _configByValue = configFormat.configByValue
    if (_configByValue) {
      if (value != null && _configByValue[value]) {
        return _configByValue[value]
      } else if (value === null && _configByValue["_null"]) {
        return _configByValue["_null"]
      } else if ((value === "" || value === null) && _configByValue["_empty"]) {
        return _configByValue["_empty"]
      }
    }
  },
}
HFormat.Types["colorTextByBackground"] = {
  format: (value, configFormat) => {
    let _colorForDark = configFormat.colorForDark || "#ffffff"
    let _colorForLight = configFormat.colorForLight || "#000000"
    if (configFormat && configFormat.fix && configFormat.fix[value]) {
      //tra ve theo danh sach
      return configFormat.fix[value]
    }
    let _lumina = HColor.getLumina(value)
    if (_lumina < 128) {
      return _colorForDark
    }
    return _colorForLight
  },
}
HFormat.Types["number_percent"] = {
  format: (value, configFormat) => {
    let _num = Number(value)
    if (!isNaN(_num)) {
      //can nhan 100 cho TH: 0.5 => 50%
      if (configFormat.needMultip) {
        _num = _num * 100
      }
      //so le hien thi
      if (configFormat.numDecimal) {
        let _x = 10
        if (configFormat.numDecimal > 1 && configFormat.numDecimal <= 5) {
          _x = Math.pow(10, configFormat.numDecimal)
        }
        _num = Math.round(_num * _x) / _x
      }
      let _numberLocale = HCF.getCF(KConfigApp.numberLocale)
      if (configFormat.numberLocale) {
        _numberLocale = configFormat.numberLocale
      }
      let _s = _num.toLocaleString(_numberLocale, {
        style: "decimal",
        minimumFractionDigits:
          configFormat.minimumFractionDigits != null
            ? configFormat.minimumFractionDigits
            : 0,
        maximumFractionDigits:
          configFormat.maximumFractionDigits != null
            ? configFormat.maximumFractionDigits
            : 2,
      })
      let _suffix = "%"
      if (configFormat.suffix != null) {
        _suffix = configFormat.suffix
      }
      return `${_s}${_suffix}`
    }
    return ""
  },
}

HFormat.Types["eval"] = {
  format: (value: any, configFormat: any) => {
    console.warn("format eval:", value, configFormat)
    if (configFormat.eval) {
      // eslint-disable-next-line
      var result = window.eval(configFormat.eval)
      return result || ""
    }
    return ""
  },
}

//Format compare
HFormat.Types["hideCondition"] = {
  format: (value: any, configFormat: any) => {
    let _configHideCondition = configFormat.configHideCondition || {}
    let _values = value || {}
    let _obj = _configHideCondition.obj
    let _opr = _configHideCondition.opr
    let _valueCompare = _configHideCondition.value
    if (_obj && typeof _obj == "string" && _obj.indexOf("[") > -1) {
      _obj = HUtils.getTextWithData(_obj, _values)
    }
    if (
      _valueCompare &&
      typeof _valueCompare == "string" &&
      _valueCompare.indexOf("[") > -1
    ) {
      _valueCompare = HUtils.getTextWithData(_valueCompare, _values)
    }
    console.log(
      "hideCondition:",
      _values,
      _configHideCondition,
      _obj,
      _opr,
      _valueCompare
    )
    let _isHide = false
    if (_opr) {
      let _config: any = {
        type: "compare",
        operator: _opr,
        valueCompare: _valueCompare,
      }
      _isHide = HFormat.format(_obj, _config)
    }
    return _isHide
  },
}
HFormat.Types["compare"] = {
  format: (value: any, configFormat: any) => {
    let _value = getValueOfFormat(value, configFormat)
    let _operator = configFormat.operator
    let _valueCompare = configFormat.valueCompare
    console.log(
      "format compare:",
      `value: ${_value}`,
      `opr: ${_operator}`,
      `valueCompare: ${_valueCompare}`
    )
    if (_operator != null) {
      if (_valueCompare != null) {
        switch (_operator) {
          case ">":
            return _value > _valueCompare
          case ">=":
            return _value >= _valueCompare
          case "==":
            return _value == _valueCompare
          case "!=":
            return _value != _valueCompare
          case "<=":
            return _value <= _valueCompare
          case "<":
            return _value < _valueCompare
          default:
          // code block
        }
      }
      switch (_operator) {
        case "null":
          return _value == null
        case "notnull":
          return _value != null
        case "empty":
          return (
            _value == "" ||
            _value == 0 ||
            _value == null ||
            (_value != null && _value.length == 0)
          )
        case "notempty":
          return _value != "" && _value != 0 && _value != null
        case "false":
          return _value == false || _value == "true"
        case "true":
          return _value == true || _value == "false"
        default:
        // code block
      }
    }
    return false
  },
}

// compare or a =="2" or a=="3"
// configFormat = {type: "compare_or",list: [{type:"compare","operator":"==","valueCompare":"2"},{}]}
HFormat.Types["compare_or"] = {
  format: (value: any, configFormat: any) => {
    console.warn("format compare_or:", value, configFormat)
    let _value = getValueOfFormat(value, configFormat)
    let _list = configFormat.list || []
    if (_list && _list.length > 0) {
      for (let _c of _list) {
        if (_c && _c.type) {
          let _r = HFormat.format(_value, {
            ..._c,
            fnList: { ...configFormat.fnList },
          })
          if (_r === true) {
            return true
          }
        }
      }
    }
    return false
  },
}
HFormat.Types["compare_and"] = {
  format: (value: any, configFormat: any) => {
    console.warn("format compare_and:", value, configFormat)
    let _value = getValueOfFormat(value, configFormat)
    let _list = configFormat.list || []
    if (_list && _list.length > 0) {
      for (let _c of _list) {
        if (_c && _c.type) {
          let _r = HFormat.format(_value, {
            ..._c,
            fnList: { ...configFormat.fnList },
          })
          if (_r === false) {
            return false
          }
        }
      }
      return true
    }
    return false
  },
}

HFormat.Types["fiesize"] = {
  format: (value: any, configFormat: any) => {
    console.warn("format fiesize:", value, configFormat)
    if (value != null) {
      let _decimals = configFormat.decimals || 2
      if (!+value) return "0 Bytes"
      const k = 1024
      const dm = _decimals < 0 ? 0 : _decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      const i = Math.floor(Math.log(value) / Math.log(k))
      return `${parseFloat((value / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    return ""
  },
}

export default HFormat
