import RcTooltip from "rc-tooltip"
import "rc-tooltip/assets/bootstrap.css"
import "./styles.css"
// export default class Tooltip extends RcTooltip {
//   static displayName = "m-tooltip"
//   static propTypes = {
//     ...RcTooltip.propTypes,
//   }
//   static defaultProps = {
//     ...RcTooltip.defaultProps,
//     prefixCls: "m-tooltip",
//     transitionName: "tip-slide",
//   }
// }

export default RcTooltip
