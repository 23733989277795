import React, { useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Button, Input, InputGroup, InputGroupText } from "reactstrap"
import { MyIcon } from "src/core/components"
import { showToastError } from "src/core/helpers/ui"
import { PATH } from "src/helpers/paths"
import ApiGeneric from "src/services/apiGeneric"
import { ErrorMessage, Form, Title } from "./Login.styles"
import { login, loginSuccessWithResponse } from "./Login.thunks"
import Container from "./LoginContainer"

const mapStateToProps = state => ({
  loading: state.loading,
  isAuthenticated: state.app.isAuthenticated,
})

const mapDispatchToProps = {
  login,
  loginSuccessWithResponse,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

const Login = (props: Props) => {
  const history = useHistory()
  const params = useParams() || {}
  const pTokenKey = (params as any).tokenkey || ""
  const [tokenKey, setTokenkey] = useState(pTokenKey)
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [cfpassword, setCFPassword] = useState("")
  const [showCFPassword, setShowCFPassword] = useState(false)
  const [validToken, setValidToken] = useState(false)
  const [error, setError] = useState("")
  const [loadingRequestValid, setLoadingRequestValid] = useState(false)
  const [loadingRequestReset, setLoadingRequestReset] = useState(false)

  const requestValidToken = (data = {}) => {
    setLoadingRequestValid(true)
    ApiGeneric({
      method: "POST",
      url: "Account/ValidateToken",
      data: data,
    })
      .then(r => {
        setLoadingRequestValid(false)
        if (r && r.Data && r.Data.IsValid) {
          setValidToken(true)
        } else if (r && r.Data && r.Data.Msg) {
          showToastError(r.Data.Msg)
        }
      })
      .catch(err => {
        setLoadingRequestValid(false)
      })
  }
  const requestReset = (data = {}) => {
    setLoadingRequestReset(true)
    ApiGeneric({
      method: "POST",
      url: "Account/ResetPassword",
      data: data,
    })
      .then(r => {
        setLoadingRequestReset(false)
        history.push(PATH.HOME)
      })
      .catch(err => {
        setLoadingRequestReset(false)
        if (err && err.msg) {
          setError(err.msg)
        }
      })
  }

  const submitValidToken = async (
    event: React.FormEvent<HTMLFormElement> | null
  ) => {
    event && event.preventDefault()
    if (!loadingRequestValid) {
      const payload = {
        PasswordKey: tokenKey,
      }
      requestValidToken(payload)
    }
  }
  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!loadingRequestReset && checkConfirmPassword()) {
      const payload = {
        PasswordKey: tokenKey,
        Password: password,
        ConfirmPassword: cfpassword,
      }
      requestReset(payload)
    }
  }

  const checkConfirmPassword = () => {
    if (password.length === 0) {
      showToastError("Please fill your password")
      return false
    } else if (password.length > 0 && password !== cfpassword) {
      showToastError("Password and Confirm Password must be match")
      return false
    }
    return true
  }

  React.useEffect(() => {
    submitValidToken(null)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  console.warn("render :", params, tokenKey)
  if (!validToken) {
    return (
      <Container>
        <Form
          className="p-5 rounded-sm shadow text-center"
          onSubmit={submitValidToken}
          autoComplete="off"
        >
          <Title style={{ marginTop: "0px" }}>
            <div>Reset password</div>
          </Title>
          <InputGroup style={{ marginBottom: "1rem" }}>
            <InputGroupText>
              <MyIcon data="type:HiOutlineKey" />
            </InputGroupText>
            <Input
              type={"text"}
              placeholder={"Token key"}
              autoComplete="on"
              value={tokenKey}
              onChange={ev => {
                setTokenkey(ev.currentTarget.value)
              }}
            />
          </InputGroup>
          {error && (
            <ErrorMessage className="mb-3 text-danger text-xl-center">
              {error}
            </ErrorMessage>
          )}
          <Button type="submit" color="primary" style={{ width: "100%" }}>
            Valid Token
          </Button>
        </Form>
      </Container>
    )
  }
  return (
    <Container>
      <Form className="p-5 rounded-sm shadow text-center" onSubmit={submit}>
        <Title style={{ marginTop: "0px" }}>
          <div>Reset password</div>
        </Title>
        <InputGroup style={{ marginBottom: "1rem" }}>
          <InputGroupText>
            <MyIcon data="type:HiOutlineKey" />
          </InputGroupText>
          <Input
            type={showPassword === true ? "text" : "password"}
            placeholder={"Password"}
            autoComplete="on"
            value={password}
            onChange={ev => {
              setPassword(ev.currentTarget.value)
            }}
          />
          <InputGroupText
            onClick={() => {
              setShowPassword(!showPassword)
            }}
          >
            <MyIcon
              data={showPassword ? "type:HiOutlineEye" : "type:HiOutlineEyeOff"}
            />
          </InputGroupText>
        </InputGroup>
        <InputGroup style={{ marginBottom: "1rem" }}>
          <InputGroupText>
            <MyIcon data="type:HiOutlineKey" />
          </InputGroupText>
          <Input
            type={showCFPassword === true ? "text" : "password"}
            placeholder={"Confirm Password"}
            autoComplete="on"
            value={cfpassword}
            onChange={ev => {
              setCFPassword(ev.currentTarget.value)
            }}
          />
          <InputGroupText
            onClick={() => {
              setShowCFPassword(!showCFPassword)
            }}
          >
            <MyIcon
              data={
                showCFPassword ? "type:HiOutlineEye" : "type:HiOutlineEyeOff"
              }
            />
          </InputGroupText>
        </InputGroup>
        {error && (
          <ErrorMessage className="mb-3 text-danger text-xl-center">
            {error}
          </ErrorMessage>
        )}
        <Button type="submit" color="primary" style={{ width: "100%" }}>
          Reset password
        </Button>
      </Form>
    </Container>
  )
}

export default connector(Login)
