import { HModules, HCF, HLink, HUtils } from "@macashipo/mlib"
import MyPageOne from "./MyPage"
import MyUIOne from "./MyUI"
const ModuleQLProject = {
  _key: "qlproject",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "qlproject"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleQLProject, { MyUIOne, MyPageOne })
    HUtils.runFuntion(HConfig, "addPrivateRoutes", [
      [
        {
          Id: "pjdailytask",
          UIUrl: "/dailytasks/:taskid",
          UIType: "prj_dailytask_detail",
        },
        {
          Id: "pjdailytask",
          UIUrl: "/dailytasks",
          UIType: "prj_dailytask",
        },
        {
          Id: "pjmanager",
          UIUrl: "/pj/:pj",
          UIType: "prjmanager",
        },
        {
          Id: "pjmanager",
          UIUrl: "/pj",
          UIType: "prjmanager",
        },
      ],
    ])
  },
}

export default ModuleQLProject
