import classnames from "classnames"
import React from "react"
import MyModal from "src/core/components/MyModal/MyModal"
import MyLightbox from "src/core/components/MyModal/MyLightbox"
import Dropzone from "react-dropzone"
import HUpload from "src/helpers/Upload"
class WrapDropzone extends React.Component {
  state = {
    accept: "",
  }
  componentDidMount() {
    HUpload.fnUpdateAccept = (accept, cb) => {
      this.setState({ accept }, () => {
        if (cb) {
          cb()
        }
      })
    }
  }
  render() {
    // console.warn("render:", this.state.accept)
    return (
      <React.Fragment>
        <Dropzone
          accept={this.state.accept}
          // accept={".jpg,.png"}
          onDrop={(acceptedFiles, fileRejections) => {
            if (HUpload.onDrop) {
              HUpload.onDrop(acceptedFiles, fileRejections)
            }
          }}
          ref={r => {
            console.warn("refDropzone:", r)
            HUpload.globalDropzone = r
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section style={{ display: "none" }}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            </section>
          )}
        </Dropzone>
      </React.Fragment>
    )
  }
}
class MyModalWrap extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MyModal type={MyModal.Helpers.types.component} />
        <MyModal type={MyModal.Helpers.types.component} level={2} />
        <MyModal type={MyModal.Helpers.types.component} level={3} />
        <MyModal type={MyModal.Helpers.types.myform} />
        <MyModal type={MyModal.Helpers.types.mypage} />
        <MyModal type={MyModal.Helpers.types.myui} />
        <MyModal type={MyModal.Helpers.types.myui} level={2} />
        <MyModal type={MyModal.Helpers.types.myui} level={3} />
        <MyModal type={MyModal.Helpers.types.mydialog} />
        <MyModal type={MyModal.Helpers.types.cellinmodal} />
        <MyModal type={MyModal.Helpers.types.globalloading} />
        <MyLightbox type={MyModal.Helpers.types.lightbox} />
        <WrapDropzone />
      </React.Fragment>
    )
  }
}

export default MyModalWrap
