import React from "react"
import styled from "styled-components"
const Wrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    margin-right: 5px;
    cursor: pointer;
    font-weight: normal;
    padding: 0.25rem 0.75rem;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    position: relative;
    color: #7c7c7c;
    margin-bottom: 5px;
  }
  & > div.active {
    border: 1px solid var(--blue-primary, #2d95ee);
    color: var(--blue-primary, #2d95ee);
    // font-weight: bold;
  }
  & > div .tick {
    width: 0.9375rem;
    height: 0.9375rem;
    position: absolute;
    overflow: hidden;
    right: 0;
    bottom: 0;
  }
  & > div .tick:before {
    border: 0.9375rem solid transparent;
    border-bottom: 0.9375rem solid var(--blue-primary, #2d95ee);
    content: "";
    position: absolute;
    right: -0.9375rem;
    bottom: 0;
  }
  & > div svg {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    font-size: 8px;
    overflow: hidden;
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
`

const SvgTick = props => {
  return (
    <svg
      enableBackground="new 0 0 12 12"
      viewBox="0 0 12 12"
      x="0"
      y="0"
      className="shopee-svg-icon icon-tick-bold"
    >
      <g>
        <path d="m5.2 10.9c-.2 0-.5-.1-.7-.2l-4.2-3.7c-.4-.4-.5-1-.1-1.4s1-.5 1.4-.1l3.4 3 5.1-7c .3-.4 1-.5 1.4-.2s.5 1 .2 1.4l-5.7 7.9c-.2.2-.4.4-.7.4 0-.1 0-.1-.1-.1z"></path>
      </g>
    </svg>
  )
}
const BaseControl = props => {
  const options =
    (props.fnList &&
      props.fnList.getSourceData &&
      props.fnList.getSourceData({ withEmpty: false })) ||
    []
  return (
    <Wrap>
      {options &&
        options.length > 0 &&
        options.map((v, i) => {
          return (
            <div
              key={i}
              className={props.value === v.Value ? "active" : ""}
              onClick={() => {
                props.onChange &&
                  props.onChange(props.value === v.Value ? null : v.Value)
              }}
            >
              {v.Text}
              {props.value === v.Value && (
                <div className="tick">
                  <SvgTick />
                </div>
              )}
            </div>
          )
        })}
    </Wrap>
  )
}

export default BaseControl
