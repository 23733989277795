import React from "react"
import { MyPageHelpers } from "@macashipo/mlib"

const MP_Css_2React_Style = React.lazy(() => import("./Types/css_2react_style"))
const MP_API_Document = React.lazy(() => import("./Types/api_document"))
const MP_Demo_ButtonList = React.lazy(() =>
  import("./Types/demo_buttonlist/index")
)
const MP_Demo_FormControl = React.lazy(() =>
  import("./Types/demo_formcontrol/index")
)
const MP_Demo_Form = React.lazy(() => import("./Types/demo_form/index"))
const MP_Demo_TableCell = React.lazy(() =>
  import("./Types/demo_tablecell/index")
)
const MP_Demo_TableTotal = React.lazy(() =>
  import("./Types/demo_tablecell/_tableTotal")
)
const MP_Demo_TableFilter = React.lazy(() =>
  import("./Types/demo_tablefilter/index")
)
const MP_Demo_MyUI = React.lazy(() => import("./Types/demo_myui/index"))
const MP_Demo_Libs = React.lazy(() => import("./Types/demo_libs/index"))
const MP_Demo_Dashboard_one = React.lazy(() =>
  import("./Types/demo_dashboard_one/index")
)
const MP_Demo_ChartJS = React.lazy(() =>
  import("./Types/demo_chart/dc_chartjs")
)
const MP_Demo_GGChart = React.lazy(() =>
  import("./Types/demo_chart/dc_ggchart")
)
const MP_Test_InputRange = React.lazy(() => import("./Types/test/input_range"))
const MP_Test_Camera = React.lazy(() => import("./Types/test/camera"))
const MP_Demo_Table = React.lazy(() => import("./Types/demo_table/index"))
const MP_Demo_Table_Span = React.lazy(() =>
  import("./Types/demo_table/dt_span")
)
const MPD_dt_multi_table = React.lazy(() =>
  import("./Types/demo_table/dt_multi_table")
)
const MPD_dt_table_footer = React.lazy(() =>
  import("./Types/demo_table/dt_table_footer")
)
const MPD_demo_docs = React.lazy(() => import("./Types/docs"))

const List = {
  demo_docs: MPD_demo_docs,
  demo_table_footer: MPD_dt_table_footer,
  demo_multi_table: MPD_dt_multi_table,
  test_input_range: MP_Test_InputRange,
  test_camera: MP_Test_Camera,
  css_2react_style: MP_Css_2React_Style,
  api_document: MP_API_Document,
  demo_buttonlist: MP_Demo_ButtonList,
  demo_formcontrol: MP_Demo_FormControl,
  demo_form: MP_Demo_Form,
  demo_table: MP_Demo_Table,
  demo_table_span: MP_Demo_Table_Span,
  demo_tablecell: MP_Demo_TableCell,
  demo_tabletotal: MP_Demo_TableTotal,
  demo_tablefilter: MP_Demo_TableFilter,
  demo_myui: MP_Demo_MyUI,
  demo_libs: MP_Demo_Libs,
  demo_dashboard_one: MP_Demo_Dashboard_one,
  demo_chartjs: MP_Demo_ChartJS,
  demo_ggchart: MP_Demo_GGChart,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
