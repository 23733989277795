import { MyUI } from "@macashipo/mlib"
import React from "react"

class StateWrap extends React.Component {
  componentDidMount() {}

  render() {
    const { loading, error, configError, configLoading } = this.props
    // console.log("render wrap:", loading, error)
    if (loading == true) {
      return <MyUI type="state_loading" configLoading={configLoading} />
    }
    //bo sung chi show error khi loading===false
    if (loading == false && error != null) {
      return <MyUI type="state_error" configError={configError} error={error} />
    }
    return this.props.children
  }
}

export default StateWrap
