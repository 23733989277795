import React from "react"
import { MyBaseHelpers } from "@macashipo/mlib"
import MB_text from "./Types/text"
import MB_textarea from "./Types/textarea"
import MB_select from "./Types/select"

const MB_textareafull = React.lazy(() => import("./Types/textareafull"))
const MB_numeral = React.lazy(() => import("./Types/numeral"))
const MB_select2 = React.lazy(() => import("./Types/select2"))
const MB_select2_api = React.lazy(() => import("./Types/select2_api"))
const MB_switch = React.lazy(() => import("./Types/switch"))
const MB_checkbox = React.lazy(() => import("./Types/checkbox"))
const MB_checkbox_yesno = React.lazy(() => import("./Types/checkbox_yesno"))
const MB_checkbox_styled = React.lazy(() => import("./Types/checkbox_styled"))
const MB_date_flatpickr = React.lazy(() => import("./Types/date_flatpickr"))
const MB_date_flatpickr_selectmonth = React.lazy(() =>
  import("./Types/date_flatpickr_selectmonth")
)
const MB_datefromto_flatpickr = React.lazy(() =>
  import("./Types/datefromto_flatpickr")
)
const MB_daterangepicker = React.lazy(() => import("./Types/daterangepicker"))
const MB_monacoeditor = React.lazy(() => import("./Types/monacoeditor"))
const MB_range = React.lazy(() => import("./Types/range"))
const MB_select_tag = React.lazy(() => import("./Types/select_tag"))
const MB_select_tag_button = React.lazy(() =>
  import("./Types/select_tag_button")
)
const MB_selectlist = React.lazy(() => import("./Types/selectlist"))
const MB_selectlist_check = React.lazy(() => import("./Types/selectlist_check"))
const MB_date_native = React.lazy(() => import("./Types/date_native"))

const ListNoLazy = {
  text: MB_text,
  textarea: MB_textarea,
  select: MB_select,
}
const List = {
  date_flatpickr_selectmonth: MB_date_flatpickr_selectmonth,
  date_native: MB_date_native,
  selectlist: MB_selectlist,
  selectlist_check: MB_selectlist_check,
  monacoeditor: MB_monacoeditor,
  daterangepicker: MB_daterangepicker,
  textareafull: MB_textareafull,
  numeral: MB_numeral,
  select2: MB_select2,
  select2_api: MB_select2_api,
  switch: MB_switch,
  checkbox: MB_checkbox,
  checkbox_yesno: MB_checkbox_yesno,
  checkbox_styled: MB_checkbox_styled,
  date_flatpickr: MB_date_flatpickr,
  datefromto_flatpickr: MB_datefromto_flatpickr,
  range: MB_range,
  select_tag_button: MB_select_tag_button,
  select_tag: MB_select_tag,
}
const MyBaseOne = {
  init() {
    MyBaseHelpers.addTypesFromListWithoutLazy(ListNoLazy)
    MyBaseHelpers.addTypesFromListLazy(List)
  },
}

export default MyBaseOne
