import * as types from "./App.constants"
import { FETCH_LOGIN_SUCCESS } from "src/pages/Login/Login.constants"
import produce from "immer"
import {
  authLogout,
  authLoginWithRes,
  authCheckSavedAuthorized,
} from "../core/helpers/auth"
const initialState: any = {
  isAuthenticated: authCheckSavedAuthorized(),
  closeSideNav: true,
  sideNavItems: [],
  headerTitle: "",
  dataFirstSetting: {},
}

if (window.document.body.clientWidth <= 1024) {
  initialState.closeSideNav = true
}

export const AppReducer = (state = initialState, action) =>
  produce(state, draft => {
    // console.warn("AppReducer", state, draft, action)
    switch (action.type) {
      case types.LOGOUT:
        authLogout()
        draft.isAuthenticated = false
        break
      case FETCH_LOGIN_SUCCESS:
        draft.isAuthenticated = authLoginWithRes(action.res)
        break
      case types.SIDE_NAV_CLOSE:
        draft.closeSideNav = !state.closeSideNav
        break
      case types.SIDE_NAV_UPDATE:
        draft.sideNavItems = [...draft.sideNavItems, ...action.items]
        break
      case types.HEADER_TITLE_UPDATE:
        draft.headerTitle = action.title
        break
      case types.FETCH_FS_ERROR:
        break
      case types.FETCH_FS_PENDING:
        break
      case types.FETCH_FS_SUCCESS:
        draft.dataFirstSetting = action.data
        break
      default:
        return state
    }
  })
