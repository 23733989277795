import { useCallback, useState } from "react"
export default function useForceUpdate() {
  const [, setVF] = useState(Object.create(null))
  const memoizedDispatch = useCallback(() => {
    setVF(Object.create(null))
  }, [setVF])
  return memoizedDispatch
}

//Luu y co truong hop goi forceUpdate nay se bi loi reactjs
