import { fnListMyPage } from "src/helpers/fnList"
export const FlowClientBuildOptions = ({
  buttonList,
  configHeader,
  configTable,
  moreSourceList,
} = {}) => {
  let _screenConfig = {
    Header: { ...configHeader },
    Table: { ...configTable },
  }
  return {
    ButtonList: buttonList || [],
    ChartConfig: null,
    Configs: {
      ScreenConfig: JSON.stringify(_screenConfig),
      UITypeContents: {},
    },
    ...moreSourceList,
  }
}

export const FlowClientClearLocalCache = (opts = {}) => {
  console.warn("clear local cache")
  let _keys = Object.keys(localStorage)
  console.warn("clear local cache:", _keys)
  for (let k of _keys) {
    if (k.indexOf("_ad-filter") > -1) {
      localStorage.removeItem(k)
    }
  }
  if (opts.withoutReload !== true) {
    window.location.reload()
  }
}
