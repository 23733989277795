import React from "react"
import HelperMore from "./_helperMore"
const BaseText = props => {
  // const more = HUtils.runFuntion(props.fnList, "getMore", [])
  return (
    <textarea
      spellCheck="false"
      // value={props.value}
      {...HelperMore.getPropsValues(props, { defaultValueWhenNull: "" })}
      onChange={ev => {
        props.onChange && props.onChange(ev.currentTarget.value)
      }}
      onBlur={ev => {
        props.onBlur && props.onBlur(ev.currentTarget.value)
      }}
      className={props.className}
      style={props.style}
      {...props.attrs}
    />
  )
}

export default BaseText
