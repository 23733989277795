import { HModules, HCF, HLink, HLocalStorage } from "@macashipo/mlib"
import { KConfigApp } from "src/helpers/K"
import ReactDOM from "react-dom"
import React from "react"
import { Animated } from "react-animated-css"
import styled from "styled-components"
import { Button } from "reactstrap"
import i18next from "i18next"
import "src/assets/scss/animate.css"
const kDivId = "chromenotify_question"
const kLocalShowedChromeNotify = "chromenotify_question_showed"
const kPathSWNotify = "sw-notify.js"
const WrapDiv = styled.div`
  font-size: 16px;
  position: fixed;
  z-index: 2258594000;
  top: 0;
  left: 0;
  right: 0;
  -webkit-font-smoothing: initial;
  &.hide {
    display: none;
  }
  & div.a-content {
    width: 450px;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
    box-shadow: 0 1px 6px rgba(5, 27, 44, 0.06),
      0 2px 32px rgba(5, 27, 44, 0.16) !important;
    background: #fff !important;
    padding: 20px 20px 15px 20px;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    & .a-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      & i {
        font-size: 3em;
        margin-right: 10px;
        color: #0081ff;
      }
    }
    & .a-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > button:first-child {
        margin-right: 5px;
      }
    }
  }
`
const NotifyQuestion = props => {
  return (
    <WrapDiv className="a-notifyquestion">
      <Animated
        animationIn="slideInDown"
        animationInDuration={500}
        animationOut="fadeOut"
        isVisible={true}
      >
        <div className="a-content">
          <div className="a-body">
            <div>
              <i className="fa fa-bell-o" />
            </div>
            {i18next.t(
              "We'd like to show you notifications for lastest news and updates."
            )}
          </div>
          <div className="a-btns">
            <Button
              color="secondary"
              onClick={() => {
                var _div = document.querySelector(`#${kDivId}>div`)
                _div.classList.add("hide")
                HLocalStorage.saveString(kLocalShowedChromeNotify, "2")
              }}
            >
              {i18next.t("Maybe Later")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                var _div = document.querySelector(`#${kDivId}>div`)
                _div.classList.add("hide")
                ModuleChromeNotification._fnList.requestPermission({
                  cbSuccess: () => {
                    ModuleChromeNotification._fnList.subscribe()
                  },
                })
              }}
            >
              {i18next.t("Allow")}
            </Button>
          </div>
        </div>
      </Animated>
    </WrapDiv>
  )
}

//gen from: https://vapidkeys.com/
// {
//   "subject": "mailto: <macashipo@yahoo.com>",
//   "publicKey": "BF4SZXN6S2qMGkWzZ7HSXx2-OQ2Y1wfiEZ4aA1QIzQxZmv5btlQwwGgIW5ffRS5wzNFB6gxnralCJAmFQxbdTUE",
//   "privateKey": "7PLays62IEqnaBaL_a_08cdQyDtRq0mGGXb81XOPOTc"
// }
const ModuleChromeNotification = {
  _key: "chrome_notification",
  _fnList: {
    requestPermission: ({ cbSuccess } = {}) => {
      if (!("Notification" in window)) {
        console.warn("This browser does not support desktop notification")
        HLocalStorage.saveString(kLocalShowedChromeNotify, "-2")
      } else {
        Notification.requestPermission().then(result => {
          if (result === "granted") {
            console.warn("granted")
            if (cbSuccess) {
              cbSuccess()
            }
          } else {
            HLocalStorage.saveString(kLocalShowedChromeNotify, "-3")
          }
        })
      }
    },
    checkExist: async () => {
      let swregister = await navigator.serviceWorker.register(kPathSWNotify)
      navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        console.log("serviceWorkerRegistration", serviceWorkerRegistration)
        serviceWorkerRegistration.pushManager.getSubscription().then(sub => {
          console.warn("sub:", sub)
          console.warn(
            "sub p256dh:",
            sub.getKey("p256dh"),
            ModuleChromeNotification._fnList.encode(sub.getKey("p256dh"))
          )
          console.warn(
            "sub auth:",
            sub.getKey("auth"),
            ModuleChromeNotification._fnList.encode(sub.getKey("auth"))
          )
        })
      })
    },
    encode: buffer => {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "")
    },
    subscribe: async () => {
      let swregister = await navigator.serviceWorker.register(kPathSWNotify)
      console.log("swregister", swregister)
      // let sw = await navigator.serviceWorker.ready
      // console.log("sw", sw)
      // let push = await sw.pushManager.subscribe({
      //   userVisibleOnly: true,
      //   applicationServerKey:
      //     "BF4SZXN6S2qMGkWzZ7HSXx2-OQ2Y1wfiEZ4aA1QIzQxZmv5btlQwwGgIW5ffRS5wzNFB6gxnralCJAmFQxbdTUE",
      // })
      // console.warn(JSON.stringify(push))

      navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        console.log("serviceWorkerRegistration", serviceWorkerRegistration)
        var options = {
          userVisibleOnly: true,
          applicationServerKey:
            "BF4SZXN6S2qMGkWzZ7HSXx2-OQ2Y1wfiEZ4aA1QIzQxZmv5btlQwwGgIW5ffRS5wzNFB6gxnralCJAmFQxbdTUE",
        }
        serviceWorkerRegistration.pushManager.subscribe(options).then(
          function (pushSubscription) {
            console.log("pushSubscription", pushSubscription.endpoint)
            HLocalStorage.saveString(kLocalShowedChromeNotify, "1")
            // The push subscription details needed by the application
            // server are now available, and can be sent to it using,
            // for example, an XMLHttpRequest.
          },
          function (error) {
            // During development it often helps to log errors to the
            // console. In a production environment it might make sense to
            // also report information about errors back to the
            // application server.
            console.log("error:", error)
            HLocalStorage.saveString(kLocalShowedChromeNotify, "-1")
          }
        )
      })
    },
  },
  _config: {},
  getMyKey: () => {
    return "chrome_notification"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleChromeNotification, {})
    // navigator.serviceWorker.ready.then(registration => {
    //   if ("pushManager" in registration) {
    //     registration.pushManager.getSubscription().then(sub => {
    //       if (sub) {
    //         console.log("sub", sub)
    //       }
    //     })
    //   }
    // })
    // navigator.serviceWorker.register(kPathSWNotify)
    //
    let _currentLocalShowed = HLocalStorage.getString(kLocalShowedChromeNotify)
    if (HCF.getCF(KConfigApp.hasPushNotify)) {
      var _divNotify = document.getElementById(kDivId)
      if (!_divNotify && _currentLocalShowed != "1") {
        _divNotify = document.createElement("div")
        _divNotify.setAttribute("id", kDivId)
        document.body.appendChild(_divNotify)
        setTimeout(() => {
          console.warn("add portal")
          let _contain = document.getElementById(kDivId)
          if (_contain) {
            ReactDOM.render(
              ReactDOM.createPortal(<NotifyQuestion />, _contain),
              document.createElement("div")
            )
          }
        }, 2000)
      } else {
        ModuleChromeNotification._fnList.checkExist()
      }
    }
  },
}

export default ModuleChromeNotification
