import MyUIOne from "./MyUI"
import MyLayoutOne from "./MyLayout"
import MyPageOne from "./MyPage"
import MyCardOne from "./MyCard"
import MyLoadingOne from "./MyLoading"
interface IModuleCorePage extends IModuleOne {
  runAfterInit: () => void
}
const ModuleCorePage: IModuleCorePage = {
  runAfterInit: () => {
    MyLayoutOne.init()
    MyUIOne.init()
    MyPageOne.init()
    MyCardOne.init()
    MyLoadingOne.init()
  },
}

export default ModuleCorePage
