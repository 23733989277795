import { HUtils, HJson } from "@macashipo/mlib"
export const FlowGetColumnFromExtraData = (extraData, fieldName) => {
  return HUtils.get(extraData, `Columns.${fieldName}`)
}

export const FlowGetMoreFilterOfCellFromExtraData = (extraData, fieldName) => {
  let _colExtra = FlowGetColumnFromExtraData(extraData, fieldName)
  if (_colExtra) {
    if (_colExtra._moreFilterObj) {
      return _colExtra._moreFilterObj
    } else {
      if (_colExtra.Filter && _colExtra.Filter.more) {
        let _moreFilterObj = HJson.getObj(_colExtra.Filter.more) || {}
        _colExtra._moreFilterObj = _moreFilterObj
        return _moreFilterObj
      }
    }
  }
  return {}
}

export const FlowGetMoreOfCellFromExtraData = (extraData, fieldName) => {
  let _colExtra = FlowGetColumnFromExtraData(extraData, fieldName)
  return FlowGetMoreOfCellFromExtraColumn(_colExtra)
}
export const FlowGetMoreOfCellFromExtraColumn = _colExtra => {
  if (_colExtra) {
    if (_colExtra._moreObj) {
      return _colExtra._moreObj
    } else {
      if (_colExtra.Type && _colExtra.Type.more) {
        let _moreObj = HJson.getObj(_colExtra.Type.more) || {}
        _colExtra._moreObj = _moreObj
        return _moreObj
      }
    }
  }
  return {}
}
