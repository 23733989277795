//require version
//auth: 20221207
// import LodashDebounce from "lodash/debounce"
import LodashThrottle from "lodash/throttle"
import { MyModal } from "src/core/components"
import { HCF, HLocalStorage, HUtils, HModules, MyUI } from "@macashipo/mlib"
import {
  authLogoutWithNothing,
  authIsLogged,
  authCheckLoginAgain,
  registerAfterLogout,
} from "src/core/helpers/auth"
import { HConstant } from "src/core/helpers"
import MyUIOne from "./MyUI"

const CheckIdleStatus = {
  Check: "Check", //dang dem
  Countdown: "Countdown", //dang dem nguoc signout
  Expired: "Expired", //da logout
}
const KCheckIdle = {
  // kCheckIdleLocal: "kCheckIdleLocal",
  checkIdleLocalTimeIdle: "checkIdleLocalTimeIdle",
  hasCheckIdle: "hasCheckIdle", //co check idle hay khong
  checkIdleSecond: "checkIdleSecond", //thoi gian se hien popup thong bao het han
  checkIdleInterval: "checkIdleInterval", //thoi gian kiem tra lien tuc sau x giay
  checkIdleCountdown: "checkIdleCountdown", //thoi gian popup thong bao het han se dem de logout
  checkIdleCountdownRemainSecond: "checkIdleCountdownRemainSecond", //thoi gian dem nguoc con lai
  checkIdleStatus: "checkIdleStatus",
  _noCheckIdle: "_noCheckIdle",
}
const ModuleCheckIdle = {
  _key: "check_idle",
  _fnList: {
    resetTimerDebounce: null,
    start: () => {
      // ModuleCheckIdle._config.currentIdle = 0
      //remove all current
      HLocalStorage.removeItem(KCheckIdle.checkIdleCountdownRemainSecond)
      HLocalStorage.saveString(
        KCheckIdle.checkIdleStatus,
        CheckIdleStatus.Check
      )
      ModuleCheckIdle._fnList.updateTimeIdle()
      if (ModuleCheckIdle._config.timer) {
        clearInterval(ModuleCheckIdle._config.timer)
      }
      ModuleCheckIdle._config.timer = setInterval(
        ModuleCheckIdle._fnList.timerIncrement,
        ModuleCheckIdle._config.checkAfterSecond * 1000
      )
      document.addEventListener(
        "mousemove",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      document.addEventListener(
        "touchmove",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      document.addEventListener(
        "keydown",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
    },
    onIdle: ({ isExpired } = {}) => {
      console.log("on Idle")
      if (isExpired !== true) {
        HLocalStorage.saveString(
          KCheckIdle.checkIdleStatus,
          CheckIdleStatus.Countdown
        )
      }
      clearInterval(ModuleCheckIdle._config.timer)
      document.removeEventListener(
        "mousemove",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      document.removeEventListener(
        "touchmove",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      document.removeEventListener(
        "keydown",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      HLocalStorage.removeItem(KCheckIdle.checkIdleLocalTimeIdle)
      MyModal.showFromComponent(
        props => {
          return (
            <MyUI
              {...props}
              type="checkidle_countdown"
              isExpired={isExpired}
              countSeconds={ModuleCheckIdle._config.countdown}
            />
          )
        },
        {
          size: "xs",
          showHeader: false,
          modalCentered: true,
          backdrop: "static",
          fnList: {
            onTimeout: () => {
              console.log("timeout")
              HLocalStorage.saveString(
                KCheckIdle.checkIdleStatus,
                CheckIdleStatus.Expired
              )
              authLogoutWithNothing()
              document.addEventListener(
                "visibilitychange",
                ModuleCheckIdle._fnList.ee_onfocus_after_signout
              )
            },
            updateRemainSecond: (totalSecondCountdown, updateExpired) => {
              let _current = HLocalStorage.getString(
                KCheckIdle.checkIdleCountdownRemainSecond
              )
              let _currentStatus = ModuleCheckIdle._fnList.getCurrentStatus()
              if (_currentStatus == CheckIdleStatus.Expired) {
                //update modal expired
                if (updateExpired) {
                  updateExpired()
                }
                return
              } else if (_currentStatus == CheckIdleStatus.Check) {
                var _currentTime = HLocalStorage.getString(
                  KCheckIdle.checkIdleLocalTimeIdle
                )
                if (_currentTime) {
                  console.log("other tab is check time again")
                  ModuleCheckIdle._fnList.start()
                  //hide modal countdown
                  MyModal.hideModalComponent()
                }
              }
              if (totalSecondCountdown < _current) {
                HLocalStorage.saveString(
                  KCheckIdle.checkIdleCountdownRemainSecond,
                  totalSecondCountdown
                )
              } else {
                console.log("no save countdown", totalSecondCountdown, _current)
              }
            },
            onSignOut: () => {
              console.log("onSignOut")
              authLogoutWithNothing()
              window.location.reload()
            },
            onKeepSignIn: () => {
              console.log("onKeepSignIn")
              ModuleCheckIdle._fnList.start()
            },
            onBackToSignIn: () => {
              console.log("onBackToSignInonBackToSignIn")
              window.location.reload()
            },
          },
        }
      )
      // MyModal.showMyDialog({
      //   title: "Session Expire Warning",
      //   msg: `You're being timed out due to inactivity. \nYou will logged off automatically`,
      //   size: "xs",
      //   modalCentered: true,
      //   backdrop: "static",
      //   showHeader: false,
      //   showFooter: true,
      //   showFooterOK: true,
      //   classNameBody: "text-pre-line",
      //   onClickOK: () => {
      //     console.log("ok")
      //     window.location.reload()
      //   },
      // })
    },
    ee_onfocus_after_signout: () => {
      if (document.visibilityState == "visible") {
        console.log("just focus tab:", new Date().toISOString())
        if (authCheckLoginAgain()) {
          document.removeEventListener(
            "visibilitychange",
            ModuleCheckIdle._fnList.ee_onfocus_after_signout
          )
          console.log("login again")
          ModuleCheckIdle._fnList.start()
          //hide modal expired
          MyModal.hideModalComponent()
        }
      }
    },
    updateTimeIdle: () => {
      let _current = new Date().getTime()
      _current += ModuleCheckIdle._config.idleAfterSecond * 1000
      HLocalStorage.saveString(KCheckIdle.checkIdleLocalTimeIdle, _current)
      // HLocalStorage.saveString(
      //   KCheckIdle.checkIdleStatus,
      //   CheckIdleStatus.Check
      // )
    },
    getCurrentStatus: () => {
      return HLocalStorage.getString(KCheckIdle.checkIdleStatus)
    },
    resetTimer: () => {
      // console.log("resetTimer")
      // ModuleCheckIdle._config.currentIdle = 0
      // HLocalStorage.removeItem(KCheckIdle.kCheckIdleLocal)
      ModuleCheckIdle._fnList.updateTimeIdle()
      //
    },
    timerIncrement: () => {
      // ModuleCheckIdle._config.currentIdle +=
      let _currentTime = new Date().getTime()
      let _currentStatus = HLocalStorage.getString(KCheckIdle.checkIdleStatus)
      if (_currentStatus == CheckIdleStatus.Countdown) {
        ModuleCheckIdle._fnList.onIdle()
        return
      } else if (_currentStatus == CheckIdleStatus.Expired) {
        ModuleCheckIdle._fnList.onIdle({ isExpired: true })
        return
      }
      //still check
      let _currentTimeIdle = Number(
        HLocalStorage.getString(KCheckIdle.checkIdleLocalTimeIdle)
      )
      if (_currentTime >= _currentTimeIdle) {
        ModuleCheckIdle._fnList.onIdle()
      }
      console.log(
        "timer:",
        _currentTime,
        _currentTimeIdle,
        _currentTimeIdle - _currentTime
      )
    },
  },
  _config: {
    // currentIdle: 0,
    timer: null,
    countdown: 60,
    idleAfterSecond: 60 * 10, //10p
    checkAfterSecond: 5, //5s
  },
  getMyKey: () => {
    return "check_idle"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleCheckIdle, { MyUIOne })
    console.log("run check_idle")
    //
    // ModuleCheckIdle._fnList.resetTimerDebounce = LodashDebounce(
    //   ModuleCheckIdle._fnList.resetTimer,
    //   500
    // )
    ModuleCheckIdle._fnList.resetTimerDebounce = LodashThrottle(
      ModuleCheckIdle._fnList.resetTimer,
      500
    )
    //
    let _hasCheckIdle = HCF.getCF(KCheckIdle.hasCheckIdle)
    let _checkSecond = HCF.getCF(KCheckIdle.checkIdleSecond)
    let _checkCountdown = HCF.getCF(KCheckIdle.checkIdleCountdown)
    let _checkIdleInterval = HCF.getCF(KCheckIdle.checkIdleInterval)
    if (_checkSecond > 0) {
      ModuleCheckIdle._config.idleAfterSecond = _checkSecond
    }
    if (_checkIdleInterval > 0) {
      ModuleCheckIdle._config.checkAfterSecond = _checkIdleInterval
    }
    if (_checkCountdown > 0) {
      ModuleCheckIdle._config.countdown = _checkCountdown
    }
    if (_hasCheckIdle === true) {
      console.log("check_idle run start")
      if (authIsLogged()) {
        let _noCheck = HLocalStorage.getString(KCheckIdle._noCheckIdle)
        if (_noCheck === "1") {
          return
        }
        ModuleCheckIdle._fnList.start()
      } else {
        //waiting login
        HUtils.runFuntion(
          HConfig,
          HConstant.HConfig.FnList.registerAfterLogin,
          [
            () => {
              console.log("check idle: call request when after login ")
              ModuleCheckIdle._fnList.start()
            },
          ]
        )
      }
    }
    //after logout
    registerAfterLogout("afterlogout_checkidle", () => {
      let _currentTimeIdle = HLocalStorage.getString(
        KCheckIdle.checkIdleLocalTimeIdle
      )
      if (_currentTimeIdle != null) {
        HLocalStorage.removeItem(KCheckIdle.checkIdleLocalTimeIdle)
        window.location.reload()
      }
    })
  },
}

// document.addEventListener("visibilitychange", () => {
//   console.log("visibilitychange", document.visibilityState)
//   if (document.visibilityState == "visible") {
//     //focus tab browser
//   }
// })

export default ModuleCheckIdle
