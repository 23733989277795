import { apiGeneric } from "src/core/helpers/api"
const ApiGeneric = ({
  request,
  data,
  successCallBack,
  errorCallBack,
  customHeader,
  opts,
}) => {
  apiGeneric({
    method: request.method || "POST",
    url: request.url || `${request.path}/${request.name}` || "NoApiUrl",
    data,
    customHeader: customHeader,
    ...opts,
  })
    .then(v => {
      if (successCallBack) {
        successCallBack(v)
      }
    })
    .catch(e => {
      if (errorCallBack) {
        errorCallBack(e)
      }
    })
}
export { ApiGeneric }
