import React from "react"
import { MyUI } from "@macashipo/mlib"
import classnames from "classnames"
class MyUI_TagCard extends React.Component {
  render() {
    const { style, className, children, h, f, hSt, fSt, hCn, fCn, fnList } =
      this.props
    return (
      <div style={style} className={classnames("card", className)}>
        {h != null && (
          <div className={classnames("card-header", hCn)} style={hSt}>
            <MyUI type="o" configObj={h} fnList={fnList} />
          </div>
        )}
        <div className="card-body">{children}</div>
        {f != null && (
          <div className={classnames("card-footer", fCn)} style={fSt}>
            <MyUI type="o" configObj={f} fnList={fnList} />
          </div>
        )}
      </div>
    )
  }
}

export default MyUI_TagCard
