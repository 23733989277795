import React from "react"
class MyUI_TagImg extends React.Component {
  render() {
    const { style, className, src, w, h, m, alt } = this.props
    const Tag = this.props.tag || "img"
    let _obj = {}
    if (w) {
      _obj.width = w
    }
    if (h) {
      _obj.height = h
    }
    if (alt) {
      _obj.alt = alt
    }
    let _src = src
    if (m && m.src) {
      _src = m.src
    }
    if (Tag && _src) {
      return <Tag style={style} className={className} src={_src} {...m} />
    }
    console.warn("Missing tag or src:", this.prop, _src)
    return <div></div>
  }
}

export default MyUI_TagImg
