import i18next from "i18next"
import axios from "axios"
import { HCF, HLocalStorage } from "@macashipo/mlib"
import { apiGeneric } from "./api"
import { KConfigApp } from "src/helpers/K"
import { FlowForceUpdateRoutes } from "src/helpers/Flows/Routes"
import { HUtils } from "."
const KLocalLanguage = "Language"
const HI18: any = {
  currentLng: "",
  currentLngLocalStorage: localStorage.getItem(KLocalLanguage),
  defaultLng: "en",
  listLng: null,
  getCurrentLng: () => {
    return HI18.currentLng
  },
  listPathLoadedExtLng: [],
  getCurrentLngFlagUrl: code => {
    let _code = code || HI18.getCurrentLng()
    if (_code == "en") {
      _code = "gb"
    }
    if (HI18.hasLngInList(code)) {
      return `/css/flags/1x1/${_code}.svg`
    }
    return `/css/imgs/globe.png`
  },
  hasLngInList: lng => {
    if (lng && Array.isArray(HI18.listLng)) {
      for (let i = 0; i < HI18.listLng.length; i++) {
        if (HI18.listLng[i].Value == lng) {
          return true
        }
      }
    }
    console.log("hasLngInList", HI18.listLng)
    return false
  },
  changeLanguage: (lng, saveLocal) => {
    // console.log("changeLanguage:", lng, saveLocal)
    if (lng) {
      HI18.currentLng = lng
      if (saveLocal === true) {
        // console.log("changeLanguage saveLocal:", lng)
        localStorage.setItem(KLocalLanguage, lng)
        HI18.currentLngLocalStorage = lng
      }
      i18next.changeLanguage(lng)
    }
  },
  changeLanguageOnUI: (
    lng,
    saveLocal,
    { needReload, needCallApi, cbLoading, cbSuccess, cbError }
  ) => {
    if (needCallApi) {
      cbLoading && cbLoading()
      apiGeneric({
        method: "POST",
        path: "Account",
        name: "UpdateFields",
        silent: true, //khong show msg
        data: {
          Values: [
            {
              FieldName: "LanguageCode",
              NewValue: lng,
            },
          ],
        },
      })
        .then(response => {
          HI18.changeLanguage(lng, saveLocal)
          //update local first setting
          HUtils.runFuntion(
            HCF.getCF("fnModuleFirstSetting"),
            "fnRemoveUserCodeInLocalFirstSetting"
          )
          HLocalStorage.removeItem("CategoryScreen")
          HLocalStorage.removeItem("SideMenu")
          cbSuccess && cbSuccess(response)
          if (needReload) {
            window.location.reload()
          }
        })
        .catch(e => {
          cbError && cbError(e)
        })
    } else {
      if (needReload) {
        window.location.reload()
      }
    }
  },
  checkLanguageFromServer: (lng, opts: any = {}) => {
    // console.log(
    //   "checkLanguageFromServer:",
    //   `current: ${HI18.currentLng},`,
    //   lng,
    //   opts
    // )
    if (
      opts["fromDefaultLanguage"] === true &&
      HI18.currentLng == HI18.defaultLng
    ) {
      //neu thay doi tu defaultLanguage thi neu hien tai la en thi moi doi, neu khac roi thi ko can doi default
      return
    }
    //nếu chưa có ngôn ngữ ở local mà truyền vào lng -> đổi qua lng
    if (HI18.currentLngLocalStorage == null && lng) {
      if (lng != HI18.currentLng) {
        HI18.changeLanguage(lng, false)
      }
    } else if (HI18.currentLngLocalStorage != HI18.currentLng) {
      //nếu có ở local và nó đang khác với current -> đổi qua lng local
      // console.log(
      //   "prepare changeLanguage:",
      //   `current: ${HI18.currentLng},`,
      //   `local: ${HI18.currentLngLocalStorage},`,
      //   lng,
      //   opts
      // )
      HI18.changeLanguage(HI18.currentLngLocalStorage, false)
    }

    //nếu user lgn từ server khác hiện tại
    if (lng && opts.fromUserLanguageCode && HI18.currentLng != lng) {
      // console.log(
      //   "need reload",
      //   window.sendGGNotifyLog,
      //   lng,
      //   HI18.currentLng,
      //   HI18.currentLng != lng,
      //   HI18.currentLngLocalStorage,
      //   opts.fromUserLanguageCode
      // )
      HI18.changeLanguage(lng, true)
      // fetch(
      //   "https://chat.googleapis.com/v1/spaces/AAAAuWV60uo/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=LiuSwZCwrNkyff5CnzR3rSiRVQXJG9WuerziVUl2Zvk",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json; charset=UTF-8",
      //     },
      //     body: JSON.stringify({
      //       text: "reload",
      //     }),
      //   }
      // )
      // window.location.reload()
      return
    }
  },
}
export const initI18n = (config?: any) => {
  let _lngInLocalStorage = localStorage.getItem(KLocalLanguage)
  console.log("initI18n:", _lngInLocalStorage)
  if (
    _lngInLocalStorage &&
    config &&
    config["resources"] &&
    config["resources"][`${_lngInLocalStorage}`]
  ) {
    config["lng"] = _lngInLocalStorage
  }
  HI18.currentLng = config?.lng
  i18next.init(config)
  initLanguageList({ listExtLng: null })
}

export const initLanguageList = ({ listExtLng }) => {
  HI18.listLng = HCF.getCF(KConfigApp.LanguageList)
  console.warn("LanguageList:", HI18.listLng, listExtLng)
  if (listExtLng && listExtLng.length > 0) {
    for (let i = 0; i < listExtLng.length; i++) {
      loadExtLngFromFile(listExtLng[i])
    }
  }
}

export const loadExtLng = (config: any = {}) => {
  const { lng, ns, resources } = config
  console.log("loadExtLng:", config)
  i18next.addResourceBundle(lng || "en", ns || "common", resources || {})
}

const getPathFromCodeInListLng = lng => {
  if (HI18.listLng && HI18.listLng.length > 0) {
    for (let k of HI18.listLng) {
      if (k && k.Value == lng) {
        return k.Path
      }
    }
  }
}
export const loadExtLngFromFile = (config: any = {}) => {
  const { lng, ns, path, isDefault, v } = config
  console.log("loadExtLngFromFile:", config)
  let _path = path || getPathFromCodeInListLng(lng)
  if (_path) {
    if (
      HI18.listPathLoadedExtLng &&
      HI18.listPathLoadedExtLng.indexOf(_path) > -1
    ) {
      //chỉ load ext lng 1 lần
      return
    }
    //add version for path
    HI18.listPathLoadedExtLng.push(_path)
    if (_path.indexOf("?") == -1) {
      if (v) {
        _path += `?v=${v}`
      } else if (window._M && window._M.AppBuildVersion) {
        _path += `?v=${window._M.AppBuildVersion}`
      }
    }
    axios.get(_path).then(rs => {
      console.log("rs:", rs)
      if (rs && rs.data) {
        i18next.addResourceBundle(lng || "en", ns || "common", rs.data)
        if (isDefault === true) {
          HI18.defaultLng = lng
          HI18.checkLanguageFromServer(lng)
          FlowForceUpdateRoutes()
        }
      }
    })
  }
}

export default HI18
