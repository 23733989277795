import React from "react"
import { MyPageHelpers } from "@macashipo/mlib"

const MUI_simulator_sender_email = React.lazy(() =>
  import("./Types/simulator_sender_email")
)

const List = {
  simulator_sender_email: MUI_simulator_sender_email,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
