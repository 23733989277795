import React from "react"
import PropTypes from "prop-types"

class MyUI_ExtControl extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (props.debug) {
      console.warn("getDerivedStateFromProps", props, state)
    }
    if (props.hasOwnProperty("v")) {
      if (props.v !== state.value) {
        return {
          value: props.v != null ? props.v : props.dfe,
        }
      }
    } else {
      if (props.df !== state.defaultValue) {
        //bo sung props.defaultValue !== state.value vi truong hop truyen vao giong default truoc, nhung khac value thi no ko update
        //nhung ma defaultValue luon luon khac state.value => luon update value lai = defaultValue => ko xai duoc => phai xai value
        return {
          defaultValue: props.df,
          value: props.df != null ? props.df : props.dfe,
        }
      }
    }
    return null
  }
  constructor(props) {
    super(props)
    this.initState()
  }
  initState = () => {
    if (this.props.debug) {
      console.warn("initState:", this.props, this)
    }
    this._controlled = false //ko control boi value ben ngoai truyen vao
    if (this.props.hasOwnProperty("v") && this.props.hasOwnProperty("df")) {
      console.warn("Only value using. value and defaultValue cannot using both")
      this._controlled = true
    } else if (this.props.hasOwnProperty("v")) {
      this._controlled = true
    }
    // eslint-disable-next-line
    this.state = {
      value:
        this.props.df != null ? this.props.df : this.getDefaultValueWhenEmpty(),
      defaultValue: this.props.df,
    }
  }
  getDefaultValueWhenEmpty = () => {
    const { dfe } = this.props
    return dfe || ""
  }
  getDelimiter = () => {
    return this.props.delimiter
  }
  getValue = () => {
    return this.state.value
  }
  getValueInOptionItem = item => {
    return item.Value || item.value
  }
  getTextInOptionItem = item => {
    return item.Text || item.label || item.text
  }
  onChange = (value, opts) => {
    const { onChange, typeDataOnChange } = this.props
    if (this._controlled == true) {
      if (onChange) {
        onChange(value, opts)
      }
    } else {
      this.setState(
        {
          value: value,
        },
        () => {
          // console.warn("onChange:",this.state.value,"<=>",value)
          let _valueOnChange = value
          if (
            typeDataOnChange != null &&
            opts &&
            opts.typeData &&
            opts.typeData[typeDataOnChange] != null
          ) {
            _valueOnChange = opts.typeData[typeDataOnChange]
          }
          if (onChange) {
            onChange(_valueOnChange, opts)
          }
        }
      )
    }
  }
}
export default MyUI_ExtControl
