import React from "react"
import styled from "styled-components"
import i18next from "i18next"
class StateError extends React.Component {
  componentDidMount() {}

  render() {
    const configError = this.props.configError || {}
    const error = this.props.error
    let msgError = String(error)
    if (error && typeof error == "object") {
      if (error["msg"]) {
        msgError = error["msg"]
      }
    }
    console.log("error:", error)
    return (
      <WrapUI>
        <div className="a-title">Error!</div>
        <div>{msgError || i18next.t("Something went wrong")}</div>
        {configError.onTryAgain && (
          <div style={{ marginTop: "10px" }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                configError.onTryAgain()
              }}
            >
              {i18next.t("Try again")}
            </button>
          </div>
        )}
      </WrapUI>
    )
  }
}

const WrapUI = styled.div`
  text-align: center;
  & .a-title {
    font-weight: bold;
  }
`
export default StateError
