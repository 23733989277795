import React from "react"
import { MyUI } from "@macashipo/mlib"
import { Tooltip as CTooltip, Popover as cPopover } from "./_helpers"
class MyUI_Tooltip extends React.Component {
  renderOverlay = () => {
    const { ov, fnList } = this.props
    if (ov) {
      return <MyUI type="o" configObj={ov} fnList={fnList} />
    } else {
      return <div></div>
    }
  }
  render() {
    const { style, className, children, pl, tg } = this.props
    // console.warn("MyUI_Tooltip:",this.props);
    return (
      <CTooltip
        style={style}
        className={className}
        placement={pl || "top"}
        trigger={tg || "hover"}
        overlay={this.renderOverlay()}
      >
        <div>{children}</div>
      </CTooltip>
    )
  }
}

export default MyUI_Tooltip
