import React from "react"
import MyUIHelpers from "src/core/components/MyUI/MyUIHelpers"
import { CSuppense } from "src/core/components"

const MUI_dev_simulator = React.lazy(() => import("./Types/dev_simulator"))
const MUI_dev_jsonconverter = React.lazy(
  () => import("./Types/dev_jsonconverter")
)

const MyUIOne = {
  init() {
    MyUIHelpers.addTypes("dev_simulator", props => {
      return CSuppense(props, MUI_dev_simulator)
    })
    MyUIHelpers.addTypes("dev_jsonconverter", props => {
      return CSuppense(props, MUI_dev_jsonconverter)
    })
  },
}

export default MyUIOne
