export const KConfigPageTable = {
  addExtraColumns: "addExtraColumns", //extra columns
  showDetails: "showDetails", //co expand ko
  showDetailsMulti: "showDetailsMulti", //co expand ko
  hideColDelete: "hideColDelete", //an cot delete
  hideColDeleteOnSmallWidth: "hideColDeleteOnSmallWidth", //an cot delete neu width nho ko
  customDelete: "customDelete", //custom delete column
  canDelete: "canDelete", //cho phep delete ko
  showColRemoveRow: "showColRemoveRow", //show col remove cho table form
  showHeader: "showHeader", //co show header table ko
  show: "show", //co show table ko
  hasCustomHeader: "hasCustomHeader", //co custom header ko, thay doi class table
  hasWrapHeader: "hasWrapHeader", //co wrap header ko, thay doi class table
  noBorder: "noBorder",
  noBorderCol: "noBorderCol",
  borderLight: "borderLight",
  hasBigBorderBottom: "hasBigBorderBottom",
  classNameTable: "classNameTable", //bo sung class name cho table
  forceFullExpandDetail: "forceFullExpandDetail",
  showLoadingWhenFilter: "showLoadingWhenFilter",
  showID: "showID", //show col ID
  colsActions: "colsActions", //cols actions
  customProps: "customProps", //custom props table build cols
  needRebuildColAfterGetList: "needRebuildColAfterGetList",
  buildColsAfterReloadList: "buildColsAfterReloadList", //do quên needRebuildColAfterGetList bên v1 nên tạo mới bên v5
  hasExpand: "hasExpand",
  sizePerPage: "sizePerPage",
  hideSizePerPage: "hideSizePerPage",
  optionsTable: "optionsTable", //options cho table
  hasPagingRemote: "hasPagingRemote", // paging server
  tableProps: "tableProps", //bo sung props cho tabled
  deleteTitle: "deleteTitle",
  deleteContent: "deleteContent",
  deleteContentHtml: "deleteContentHtml",
  deleteWithNote: "deleteWithNote",
  fDelete: "fDelete", //dung o delete
  styleHeader: "styleHeader", //style chung cho header neu custom style header
  belowTable: "belowTable", //config MyUI show below table array item: type, groupByFn, groupByFnArgs
  needReloadAfterUpdateFields: "needReloadAfterUpdateFields", //array field khi goi update field, se goi reload list
  needReloadChartAfterUpdateFields: "needReloadChartAfterUpdateFields", //array field khi goi update field se reload chart
  hideTableWhenEmpty: "hideTableWhenEmpty", //an luon vung table khi data rong
  configSelectRow: "configSelectRow", //show select row o table
  canSort: "canSort", //default can sort column
  canSortByField: "canSortByField",
  fixHeightScrollTable: "fixHeightScrollTable", //bool: gom table vao vung co the scroll duoc
  fixHeightStickyNumColumn: "fixHeightStickyNumColumn", //num: freeze column khi gom vung
  fixHeightStickyFooter: "fixHeightStickyFooter", //bool: sticky footer
  showTotal: "showTotal", //show footer
  configTotal: "configTotal", //configTotal
  needReloadAfterDelete: "needReloadAfterDelete",
  noCheckFullPath: "noCheckFullPath", //khong check fullpath cua api
  fieldId: "fieldId", //thay the field Id
  showLoadMore: "showLoadMore", //show nut load more thay vi pagination
  autoLoadMore: "autoLoadMore",
  autoLoadMoreMode: "autoLoadMoreMode",
  autoLoadMoreOffset: "autoLoadMoreOffset",
  noWrap: "noWrap", //bỏ style của thẻ card
  hasAddNewAtBottom: "hasAddNewAtBottom", //bổ sung nút addnew cuối bảng
  btnAddNewAtBottom: "btnAddNewAtBottom", // config btn add new at bottom
  defaultHeaderAlign: "defaultHeaderAlign",
  headerAlignByField: "headerAlignByField",
  defaultHeaderStyle: "defaultHeaderStyle",
  headerStyleByField: "headerStyleByField",
  headerColPropsByField: "headerColPropsByField",
  usingOrderNo: "usingOrderNo", //su dung orderNo cho viec sap xep
  defaultOrderNo: "defaultOrderNo", //gia tri defaultOrderNo mac dinh//chua xai
  showLoadingRowWhenRequestUpdateRow: "showLoadingRowWhenRequestUpdateRow", //show loading
  isRenderFooterByField: "isRenderFooterByField",
  loadMoreSelectorScroll: "loadMoreSelectorScroll",
  isAvoidBreakPrint: "isAvoidBreakPrint", //tránh chia đôi row khi in
  hideHeaderWhenNoData: "hideHeaderWhenNoData",
  styleWrap: "styleWrap",
  maxWidthByColumnWidth: "maxWidthByColumnWidth",
  maxWidthPercent100: "maxWidthPercent100", //dugn de tinh toan maxWidthByColumnWidth, mac dinh la 200
  loadMoreAutoSwitchScrollWindow: "loadMoreAutoSwitchScrollWindow",
}

export const KConfigPageTableDefault = {
  showHeader: true,
  showID: false,
}

export const KConfigPageFilter = {
  type: "type",
  customQueryFromSever: "customQueryFromSever",
  position: "position",
  searchServer: "searchServer",
  showSearch: "showSearch",
  showFilterAsPopover: "showFilterAsPopover",
  needCheckLastItemQueryServer: "needCheckLastItemQueryServer", //kiem tra last item filter co isServer ko de quyet dinh co requestList hay ko, mac dinh la false
}

export const KConfigPageDetail = {
  multi: "multi",
  needReloadMaster: "needReloadMaster",
  needReloadMasterAfterDeleteItem: "needReloadMasterAfterDeleteItem",
  needReloadMasterAfterUpdateField: "needReloadMasterAfterUpdateField",
  needReloadMasterAfterAddNew: "needReloadMasterAfterAddNew",
  needReloadMasterAfterSubmitModal: "needReloadMasterAfterSubmitModal",
  needReExpandAfterReloadMasterFromDetail:
    "needReExpandAfterReloadMasterFromDetail", //need re expand after reload master
  needReExpandAfterAddNewFromDetail: "needReExpandAfterAddNewFromDetail",
}

export const KConfigPageHeader = {
  show: "show",
  title: "title", //custom title on header
  hideTitle: "hideTitle", //hide title
  showAddNew: "showAddNew",
  showProjectHeader: "showProjectHeader", //show project header for solid
  hideButtons: "hideButtons",
  usingButtonTypeInMore: "usingButtonTypeInMore", //using type from more, getButtonsList ko hỗ trợ
  preButtons: "preButtons", //bo sung button tu ScreenConfig, them phia truoc
  sufButtons: "sufButtons", //bo sung button tu ScreenConfig, them phia sau
  onlyShowButtonInSmallWidth: "onlyShowButtonInSmallWidth",
}

export const KConfigPagePage = {
  dashboard: "dashboard",
  topTable: "topTable",
  topHeader: "topHeader",
  belowHeader: "belowHeader",
  belowFilter: "belowFilter",
  belowTable: "belowTable", //bên dưới table
  topPage: "topPage", //đầu trang
  footerPage: "footerPage", //cuối trang
  configFlow: "configFlow",
  configCard: "configCard", //dung de config card, dung cho cac page su dung card
  hasSwitchView: "hasSwitchView", //switch view
  listSwitchView: "listSwitchView", //switch view
  defaultSwitchView: "defaultSwitchView", //defautl switch view
  prefixId: "prefixId", //prefixIdfor chart, date picker v.v...
  configChart: "configChart", //config chart thay the Options, trong truong hop Options config chart null
  configMultiTable: "configMultiTable", //config cho man hinh multi_table
  listChart: "listChart", //list chart thay the Options
  headerChart: "headerChart", //trong page chart
  footerChart: "footerChart", //trong page chart
  i18n: "i18n",
}

export const KConfigPageExpander = {
  defaultExpand: "defaultExpand",
  hasPageExpand: "hasPageExpand",
}

export const KEventsApp = {
  ee_sidemenu_forceupdate: "ee_sidemenu_forceupdate",
}

export const KConfigPage = {
  hideLoadingReport: "hideLoadingReport",
  hasEditMode: "hasEditMode", //cho man hinh report
}

export const KConfigApp = {
  baseUrl: "baseUrl",
  defaultTypeHubFilter: "defaultTypeHubFilter",
  defaultPositionFilter: "defaultPositionFilter",
  GoogleClientId: "GoogleClientId",
  GooglePlaceApiKey: "GooglePlaceApiKey",
  hasRegisterAccount: "hasRegisterAccount",
  hasForgotPassword: "hasForgotPassword",
  hasLoginSocialFacebook: "hasLoginSocialFacebook",
  hasLoginSocialGoogle: "hasLoginSocialGoogle",
  widthMobile: "widthMobile",
  isDevMode: "isDevMode", //from v5
  home_url: "home_url",
  DefaultHomeURL: "DefaultHomeURL",
  AuthInfo: "AuthInfo",
  appLogo_url: "appLogo_url",
  verAdmin: "verAdmin", //version admin, v1,v3,v5
  defaultTagInputCell: "defaultTagInputCell", //default tag, input or textarea for cell using input
  hasSetting2FA: "hasSetting2FA", //co setting 2FA ko
  showClassFieldOnCell: "showClassFieldOnCell",
  title: "title",
  serviceWorkersRun: "serviceWorkersRun",
  hasSignalR: "hasSignalR", //for signalR
  signalRHub: "signalRHub", //signalR hub link
  signalRKey: "signalRKey", //signalR key
  hasPushNotify: "hasPushNotify",
  fileUploadSupport: "fileUploadSupport",
  numberLocale: "numberLocale",
  currencyLocaleOptions: "currencyLocaleOptions",
  expandIconHideColor: "expandIconHideColor", //
  autoRedirectLoginPageNotFound: "autoRedirectLoginPageNotFound", //auto redirect login when show page not found
  mtf_datefromto_showRange: "mtf_datefromto_showRange", //default showRange filter datefromto
  styleDefaultCell: "styleDefaultCell", //style chung cho cell
  charSplitArray: "charSplitArray", //charSplitArray default ,
  showClearLocalCache: "showClearLocalCache", //show clear local Cache o avatar menu
  dfShowFilterAsPopover: "dfShowFilterAsPopover",
  dateFormat: "dateFormat",
  datetimeFormat: "datetimeFormat",
  dateFPFormat: "dateFPFormat",
  datetimeFPFormat: "datetimeFPFormat",
  numeralFormat: "numeralFormat",
  autoRedirectHomePage: "autoRedirectHomePage", //tu dong chuyen redirect / -> homepage
  defaultLanguage: "defaultLanguage",
  hideEffectDelete: "hideEffectDelete",
  hideEffectAddNew: "hideEffectAddNew",
  hasChangeLanguage: "hasChangeLanguage",
  hasSettingPage: "hasSettingPage",
  LanguageList: "LanguageList", //danh sach ngon ngu tu firstsetting
  defaultStyledTab: "defaultStyledTab",
  hasSwitchTheme: "hasSwitchTheme",
  blockURLNeedChangePW: "blockURLNeedChangePW",
}

export const KConfigModal = {
  key: "key", //
  title: "title", //
  fTitle: "fTitle", //use for cell, get title from row
  className: "className",
  showFooter: "showFooter",
  showHeader: "showHeader",
  type: "type", //type of modal
  typeMyPage: "typeMyPage", //type mypage if type is mypage
  backdrop: "backdrop", //static or false
  size: "size", //md lg xl
}

export const KUI = {
  fnshowDialog: "fnshowDialog",
  fnShowToast: "fnShowToast",
  fnshowConfirm: "fnshowConfirm",
  fnShowLoadingScreen: "fnShowLoadingScreen",
  fnForceUpdateRoutes: "fnForceUpdateRoutes",
}

export const KConstant = {
  _NullOrEmpty_: "_NullOrEmpty_",
}

export const KApiName = {
  List: "List",
  Options: "Options",
  UpdateFields: "UpdateFields",
}

export const KOnePageMore = {
  _currentSearchText: "_currentSearchText", //co text dang search khong
  _componentTable: "_componentTable", //component Table
  _componentTableLoading: "_componentTableLoading",
  _forceUpdateTable: "_forceUpdateTable",
  _waitingComponentTableForFilter: "_waitingComponentTableForFilter",
  _configRowById: "_configRowById",
  _isShowLoadMore: "_isShowLoadMore",
  _configLoadMore: "_configLoadMore",
  _pageUUID: "_pageUUID",
  _noLocalFilter: "_noLocalFilter", //khong load local/save filter
  _loadedList: "_loadedList",
  _dataTotal: "_dataTotal",
  _isPanel: "_isPanel", //trang là 1 panel
  _isInPanel: "_isInPanel", //trang nằm trong 1 panel
  _isLoadedDefaultScreenConfig: "_isLoadedDefaultScreenConfig",
  UI_LoadOptionError: "UI_LoadOptionError",
}

//when using json on table header
export const KConfigTableHeader = {
  type: "type",
  header: "header",
  style: "style",
  title: "title",
  tooltip: "tooltip",
  html: "html",
}

export const KConfigInConfigPage = {
  type: "type",
  needWaitingOptions: "needWaitingOptions",
  configGrid: "configGrid",
  wrapLayout: "wrapLayout",
  wrapType: "wrapType", //type child page cho type wrap
  noLocalFilter: "noLocalFilter",
  wrapStyle: "wrapStyle", //style bao ben ngoai page, danh cho con cua grid
  classNameWrap: "classNameWrap",
  styleTab: "styleTab",
  styleTabActive: "styleTabActive",
  styleItem: "styleItem",
  styleWrap: "styleWrap", //tuong tu wrapStyle
  styled: "styled",
  hideTabOnlyOneTab: "hideTabOnlyOneTab",
}

export const KTypeCellControl = {
  textarea: "textarea",
  input: "input",
}

export const KMyUITypeCommon = {
  html: "html",
}

export const kRequestFromClient = {
  UI_TextSearch: "UI_TextSearch",
  UI_Sorts: "UI_Sorts",
}

export const KRowMore = {
  _UI_NeedUpdateUIByFields: "_UI_NeedUpdateUIByFields",
  UI_MergeRowByField: "UI_MergeRowByField", //dùng sai tên, đáng lẽ là merge col
  UI_Span: "UI_Span",
  UI_StyleRow: "UI_StyleRow",
  UI_StyleRowByField: "UI_StyleRowByField",
  UI_ClassTableRow: "UI_ClassTableRow",
  UI_ClassTableCell: "UI_ClassTableCell",
  UI_ComponentAppendCell: "UI_ComponentAppendCell",
  UI_DefaultShowExpand: "UI_DefaultShowExpand", //default expand
  UI_ShowRemove: "UI_ShowRemove", //defautl field check show remove
  _fnForceUpdateDetail: "_fnForceUpdateDetail", //fn forceupdate detail of row
}

export const kClientKeyUI = {
  _UI_Missing: "_UI_Missing", //boolean for option source item, missing from source
  _RelativeByField: "_RelativeByField",
}

export const KStorage = {
  _localConfig: "_localConfig",
  _localBaseUrl: "_localBaseUrl",
  _usingLocalBaseUrl: "_usingLocalBaseUrl",
  redirectAfterLogin: "redirectAfterLogin",
  HomeURL: "HomeURL",
  AuthInfo: "Auth-Info",
}

export const KClassBody = {
  appFullContainer: "app-full-container", //remove padding container of page
}

export const kMyUICore = {
  //fnFrom MyUI Core
  myData: "myData", //get data from this.props.data
  myChange: "myChange", //call function from onChange
  myBlur: "myBlur", //call function from onBlur
}

export const KObserver = {
  beforeSearchTable: "beforeSearchTable",
  beforeFilterTable: "beforeFilterTable",
  window_resize: "window_resize",
  after_login: "after_login",
  after_request_list: "after_request_list",
  after_requestFirstSetting: "after_requestFirstSetting",
}

export const KLocalStorage = {
  useTheme: "useTheme",
  themeSeason: "themeSeason",
  lastFirstSetting: "lastFirstSetting",
}

const K = {
  fnGetListExtraData: "fnGetListExtraData",
  fnGetConfigFilter: "fnGetConfigFilter",
  fnGetOptions: "fnGetOptions",
  KConfigApp,
  KConfigPageTable,
  KConfigPageTableDefault,
  KConfigPageFilter,
  KConfigPagePage,
  KConfigPage,
  KUI,
  KConstant,
  KApiName,
  KMyUITypeCommon,
  kMyUICore,
}
export default K
