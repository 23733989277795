import axios, { AxiosRequestConfig } from "axios"
import i18next from "i18next"
import { authGetAuthorizationHeader, authLogout } from "./auth"
import { mergeObjIfExist, get, xorCrypt, debounce } from "./utils"
import { logApi } from "./log"
import { showApiMsg, showDialog } from "./ui"
import { showToastError } from "./ui"
import { HUtils, HC, HConstant } from "."
import { HCF, HLocalStorage } from "@macashipo/mlib"
import HI18 from "./i18n"
const ApiMethod = {
  post: "POST",
  get: "GET",
}
const ApiContentType = {
  json: "application/json",
  form: "application/x-www-form-urlencoded",
  multipart: "multipart/form-data",
}

const ApiConfig: IConfigApi = {
  baseUrl: "",
  timeout: 5 * 60 * 1000,
  fnLogout: () => {},
  appName: "",
  firstloadID: "",
}

const ApiTemp: any = {
  countErrorCheckServerMaintenance: 0,
}

export const initApi = (configApi?: IConfigApi) => {
  mergeObjIfExist(ApiConfig, configApi)
  ApiConfig.firstloadID = `${Math.random()
    .toString(36)
    .slice(2, 6)}_${new Date().getTime()}`
}

const checkServerMaintenance = debounce(
  (error, count) => {
    if (count > 3) {
      showDialog({
        title: i18next.t("api.Network error"),
        msg: i18next.t("api.Server under maintenance", {
          interpolation: { escapeValue: false },
        }),
      })
    }
    ApiTemp.countErrorCheckServerMaintenance = 0
    let _msgError =
      i18next.t("api.Network error") || get(error.toJSON(), "message")
    if (_msgError) {
      showToastError(_msgError)
    }
  },
  500,
  {}
)
const apiShareError = (config: IApiRequestConfig, error, resolve, reject) => {
  logApi("apiShareError:", error, config, error.response)
  const statusCode = get(error, "response.status")
  console.warn("statusCode", statusCode)
  let _msgError = "Error!"
  let _url = config.url || `${config.path}/${config.name}`
  if (statusCode === 401) {
    let _authorization = authGetAuthorizationHeader()
    if (_authorization) {
      HLocalStorage.saveString("Just401", "1")
    }
    if (ApiConfig.fnLogout) {
      ApiConfig.fnLogout()
    } else {
      authLogout()
    }
  } else if (statusCode === 404) {
    showToastError(
      i18next.t("api.Api not found", {
        name: _url,
        interpolation: { escapeValue: false },
      })
    )
  } else if (statusCode === 400) {
    _msgError = get(error, "response.data.error_description")
    if (_msgError && config.hideMsgError !== true) {
      showToastError(_msgError)
    }
  } else if (statusCode === undefined) {
    //CORS, ko the lay statusCode
    ApiTemp.countErrorCheckServerMaintenance++
    checkServerMaintenance(error, ApiTemp.countErrorCheckServerMaintenance)
  } else if (error.response === undefined && error.toJSON) {
    _msgError = get(error.toJSON(), "message")
    if (_msgError) {
      showToastError(_msgError)
    }
  } else {
    let _status = get(error, "response.data.status")
    let _title = get(error, "response.data.title")
    if (_status && _title) {
      showToastError(`${_status} - ${_title}`)
    }
  }
  logException({
    AIPUrl: _url,
    Data: config.data || {},
    Error: {
      status: statusCode,
    },
  })
  reject(error, get(error, "response.data"))
}
const apiShareSuccess = (
  config: IApiRequestConfig,
  response,
  resolve,
  reject
) => {
  logApi("apiShareSuccess:", response)
  if (response && response.data) {
    if (response.data.TotalMili) {
      logWaitLongTime(config, response.data.TotalMili, response)
    }
    if (
      response.data.StatusCode === 1 ||
      response.data.StatusCode === 200 ||
      config["noCheckStatusCode"] === true ||
      HUtils.objHasKey(response.data, "user_id") //token
    ) {
      if (config.silent !== true && config.hideMsgSuccess !== true) {
        showApiMsg(response.data.Msg)
      }
      resolve(response.data)
    } else {
      if (config.hideMsgError !== true && config.silent !== true) {
        showToastError(response.data.Msg)
      }
      reject(
        { code: "2", msg: response.data.Msg || "Error from server", response },
        response
      )
    }
  } else {
    reject({ code: "1", msg: "No response data", response }, response)
  }
}
const apiBuildAxiosRequestHeader = function (config: IApiRequestConfig): any {
  let _config: any = {
    Accept: ApiContentType.json,
    "Content-Type": config.customContentType || ApiContentType.json,
    ...config.customHeader,
  }
  let _authorization = authGetAuthorizationHeader()
  if (_authorization) {
    _config.Authorization = _authorization
  }
  return _config
}
const apiBuildAxiosRequestUrl = function (config: IApiRequestConfig) {
  let _url = ""
  if (config.url) {
    if (config.url.startsWith("http")) {
      _url = config.url
    } else if (config.url.startsWith("/")) {
      _url = `${ApiConfig.baseUrl}${config.url}`
    } else if (config.url.indexOf("api/v1") > -1) {
      _url = `${ApiConfig.baseUrl}/${config.url}`
    } else {
      _url = `${ApiConfig.baseUrl}/api/v1/${config.url}`
    }
  } else if (config.path || config.name) {
    _url = `${ApiConfig.baseUrl}/api/v1/${config.path}/${config.name}`
  }
  return _url
}
const buildRequestDataForPost = (requestData: any) => {
  // console.warn("buildRequestDataForPost:", requestData)
  let _request = {
    UI_FirstID: ApiConfig.firstloadID,
    AppName: ApiConfig.appName,
    Url: window.location.href,
    DocumentWidth: window.screen.width,
    ...requestData,
    //de o sau requestData de tranh server truyen xuong overide lai khi update
    UI_StartAt: new Date().getTime(),
    // UI_Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,//phu thuoc trinh duyet
    UI_TimezoneOffset: new Date().getTimezoneOffset(),
    UI_Language: HI18.getCurrentLng(),
  }
  return _request
}
const buildRequestDataForForm = (requestData: any) => {
  return Object.keys(requestData)
    .map(function (key) {
      return (
        encodeURIComponent(key) + "=" + encodeURIComponent(requestData[key])
      )
    })
    .join("&")
}
const buildRequestData = (config: IApiRequestConfig) => {
  var data = config.data
  if (config.method && config.method.toLowerCase() === "post") {
    if (data instanceof FormData) {
      return data
    }
    if (data && typeof data === "object") {
      data = buildRequestDataForPost(config.data)
    }
  }
  return data
}
const apiDefaultAxiosRequestConfig = function (config: IApiRequestConfig) {
  return {
    timeout: config.timeout || ApiConfig.timeout, //5 minute
  }
}
const apiBuildAxiosRequestConfig = function (
  config: IApiRequestConfig
): AxiosRequestConfig {
  let _config: AxiosRequestConfig = {
    ...apiDefaultAxiosRequestConfig(config),
    method: config.method,
    url: apiBuildAxiosRequestUrl(config),
    headers: apiBuildAxiosRequestHeader(config),
    data: buildRequestData(config),
  }
  if (config["responseType"]) {
    _config["responseType"] = config["responseType"]
  }
  return _config
}
export const apiGeneric = (config: IApiRequestConfig | any) => {
  let _config = apiBuildAxiosRequestConfig(config)
  logApi("apiGeneric:", _config)
  return new Promise(
    (resolve: (response: IServer.IApiResponse) => void, reject) => {
      return axios(_config)
        .then(response => {
          apiShareSuccess(config, response, resolve, reject)
        })
        .catch(error => {
          apiShareError(config, error, resolve, reject)
        })
    }
  )
}

export const apiGet = (config: IApiRequestConfig) => {
  return apiGeneric({
    ...config,
    method: ApiMethod.get,
    customContentType: ApiContentType.json,
  })
}

export const apiPost = (config: IApiRequestConfig) => {
  return apiGeneric({
    ...config,
    method: ApiMethod.post,
    customContentType: ApiContentType.json,
    data: config.data,
  })
}

export const apiUpload = (config: IApiRequestConfig) => {
  var _formData = new FormData()
  let _files = HUtils.get(config, "files")
  if (_files && _files.length > 0) {
    for (let i = 0; i < _files.length; i++) {
      _formData.append("file[]", _files[i])
    }
  }
  return apiGeneric({
    ...config,
    method: ApiMethod.post,
    data: _files ? _formData : config.data,
    customContentType: ApiContentType.multipart,
  })
}

export const apiPostForm = (config: IApiRequestConfig) => {
  return apiGeneric({
    ...config,
    method: ApiMethod.post,
    customContentType: ApiContentType.form,
    data: buildRequestDataForForm(config.data),
  })
}

export const apiWithConfigApi = (configApi: any) => {
  let _config: any = {
    method: configApi.method,
    url: configApi.url,
  }
  if (configApi.query) {
    _config.data = configApi.query
    console.warn("data:", _config.data)
  }
  if (configApi.path && configApi.name) {
    _config.path = configApi.path
    _config.name = configApi.name
  }
  if (configApi.timeout) {
    _config.timeout = configApi.timeout
  }
  return apiGeneric({
    ..._config,
    method: ApiMethod.post,
    customContentType: ApiContentType.json,
    data: buildRequestDataForPost(_config.data),
  })
}

function logException({
  AIPUrl,
  Data,
  Error,
}: {
  AIPUrl: any
  Data: any
  Error: any
}) {
  //Bo sung ko save log khi dev
  if (window.location.href.indexOf("localhost") > -1) {
    return null
  }
  let _url =
    HC.getConfig(HConstant.ConfigAppExt.urlForLogException) ||
    `${ApiConfig.baseUrl}/api/v1/Exception/SaveLog`
  let _userId = HC.getAuthUserId() || ""
  let _userName = HC.getAuthUserName() || ""
  let _data = {
    LogBy: `[${_userId}] ${_userName}`,
    AIPUrl: AIPUrl,
    Url: window.location.href,
    Error: Error,
    Data: Data,
  }
  console.warn("logException:", _data)
  if (
    _data.Data &&
    typeof _data.Data == "string" &&
    _data.Data.startsWith("grant_type")
  ) {
    let _rg = new RegExp("&password=([^&]*)&")
    let _match = _rg.exec(_data.Data)
    if (_match && _match[1]) {
      let _encrypt = encodeURIComponent(xorCrypt(_match[1]))
      let _newData = _data.Data.replace(
        `password=${_match[1]}`,
        `password=${_encrypt}`
      )
      _data.Data = _newData
      // console.log("_newData:", _newData)
    }
  }
  axios({
    method: "POST",
    url: _url,
    data: _data,
  })
    .then(function (response) {
      console.warn("axios response:", response)
    })
    .catch(function (error) {
      console.warn("axios error:", error)
    })
}

function logWaitLongTime(config, time, response) {
  try {
    let _timeWaitNeedLog = HCF.getCF("logWaitLongTimeMilisecond") || 5000
    let _sizeWaitLog = HCF.getCF("logWaitSizeByte") || 2000000 //2MB
    let _contentLenth = 0
    if (response && response.headers) {
      _contentLenth = response.headers["content-length"]
    }
    if (time > _timeWaitNeedLog || _contentLenth > _sizeWaitLog) {
      if (window.sendGGNotifyLog) {
        let _apiExcept = HCF.getCF("logWaitLongTimeExceptApi")
        let _urlAPI = config.url || `${config.path}/${config.name}`
        if (_apiExcept && _apiExcept.indexOf(_urlAPI) > -1) {
          return
        }
        let _url =
          HCF.getCF("GGNotifyWebHookWaitLongTime") ||
          "https://chat.googleapis.com/v1/spaces/AAAA8PM-YLM/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=UXMyVt1pNHE8zfuqCL-NT4vrF4dWLHkiLEdlgkq9nKc"
        let _msg = `Base: ${ApiConfig.baseUrl}\nAPI: ${_urlAPI}\nTime: ${time}`
        if (_msg.indexOf("localhost") > -1) {
          console.warn("logWaitLongTime no log localhost", config, time)
          return
        }
        if (_contentLenth > 100000) {
          _msg += `\nSize: ${_contentLenth} Bytes = ${parseFloat(
            String(_contentLenth / 1000000)
          ).toFixed(2)} MB`
        }
        let _user = HLocalStorage.getObj("Auth-Info", {}).user_name
        if (_user) {
          _msg += `\nUser: ${_user}`
        }
        if (navigator && navigator.userAgent) {
          _msg += `\nAgent: ${navigator.userAgent}`
        }

        window.sendGGNotifyLog({
          webhookURL: _url,
          msg: _msg,
        })
      }
    }
  } catch (error) {
    console.warn("error logWaitLongTime", error)
  }
}
