import React from "react"
import { MyCardHelpers } from "@macashipo/mlib"

const MC_card_row_email_template = React.lazy(() =>
  import("./Types/card_row_email_template")
)
const List = {
  card_row_email_template: MC_card_row_email_template,
}
const MyLibOne = {
  init() {
    MyCardHelpers.addTypesFromListLazy(List)
  },
}

export default MyLibOne
