import { HCF } from "@macashipo/mlib"
import { KConfigApp } from "src/helpers/K"
const ServiceWorker = {
  init() {
    if (ServiceWorker.isSupported()) {
      let _arrSW = HCF.getCF(KConfigApp.serviceWorkersRun)
      if (_arrSW) {
        for (let sw of _arrSW) {
          ServiceWorker.enableNotification(sw)
        }
      }
    } else {
      console.warn("Service worker not supported")
    }
  },
  isSupported() {
    if (!("serviceWorker" in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      return false
    }
    if (!("PushManager" in window)) {
      // Push isn't supported on this browser, disable or hide UI.
      return false
    }
    return true
  },
  registerSW(link) {
    //check exist
    console.warn("check exist before registrer")
    ServiceWorker.getSW(link).then(sw => {
      console.warn("sw", sw)
    })
    return navigator.serviceWorker.register(link)
  },
  getSW(link) {
    return navigator.serviceWorker.getRegistration(link)
  },
  enableNotification(linkSW) {
    Notification.requestPermission().then(result => {
      if (result === "granted") {
        return ServiceWorker.registerSW(linkSW)
          .then(reg => {
            console.warn("Registered service worker")
            var serviceWorker
            if (reg.installing) {
              serviceWorker = reg.installing
            } else if (reg.waiting) {
              serviceWorker = reg.waiting
            } else if (reg.active) {
              serviceWorker = reg.active
            }

            if (serviceWorker) {
              console.log("ServiceWorker phase:", serviceWorker.state)

              serviceWorker.addEventListener("statechange", function (e) {
                console.log("ServiceWorker phase:", e.target.state)
              })
            }
          })
          .catch(err => {
            console.warn("Register service worker failed", err)
          })
      }
    })
  },
  disableNotification(linkSW) {
    ServiceWorker.getSW(linkSW).then(sw => {
      if (sw) {
        sw.unregister()
      }
    })
  },
  showNotification(linkSW, body, tag, data) {
    ServiceWorker.getSW(linkSW).then(sw => {
      if (sw) {
        const title = "Notification with Data"
        const options = {
          body:
            body ||
            "This notification has data attached to it that is printed " +
              "to the console when it's clicked.",
          tag: tag || "data-notification",
          data: data || {
            createdAt: new Date(Date.now()).toString(),
            message: "Hello, World!",
          },
        }
        sw.showNotification(title, options)
      } else {
        console.warn("Missing service worker", sw)
      }
    })
  },
  showNotificationWithoutServiceWorker({
    title,
    body,
    tag,
    icon,
    lang,
    dir,
  }: any = {}) {
    var n = new Notification(title || "Notification", {
      body: body || "Message\nPretty cool, huh?",
      tag: new Date().getTime().toString(),
      // icon: "images/Sexy_Ben.jpeg",
      lang: "",
      dir: "auto",
    })
    n.onclick = function () {
      console.log("clicked")
    }
    n.onshow = function () {
      console.log("showed")
    }
    n.onerror = function () {
      console.log("errored")
    }
    n.onclose = function () {
      console.log("closed")
    }
  },
}
window["HServiceWorker"] = ServiceWorker
export default ServiceWorker
