import ReactGA from "react-ga"
import ReactGA4 from "react-ga4"
import { HModules, HCF, HLink, HUtils } from "@macashipo/mlib"
const trackPageView = (url = "") => {
  console.log("trackPageView", url, window.gtag)
  if (ModuleReactGA._config.isUniversal) {
    ReactGA.send({
      hitType: "pageview",
      page: url,
    })
  } else {
    ReactGA4.send({
      hitType: "pageview",
      page: url,
    })
  }
}
const initGA = GAId => {
  if (ModuleReactGA._config.isUniversal) {
    ReactGA.initialize(GAId)
    ModuleReactGA._config.initGAdone = true
  } else {
    ReactGA4.initialize(GAId)
    ModuleReactGA._config.initGAdone = true
  }
  if (GAId === ModuleReactGA._config.GAIdDev) {
    ModuleReactGA._config.isUsingDev = true
  }
}
const ModuleReactGA = {
  _key: "reactga",
  _fnList: {
    gaTrackCurrentPageView: () => {
      if (ModuleReactGA._config.initGAdone) {
        if (ModuleReactGA._config.isUsingDev) {
          trackPageView(`/${HCF.getCF("appName")}/${window.location.href}`)
        } else {
          trackPageView(
            window.location.pathname +
              window.location.search +
              window.location.hash
          )
        }
      }
    },
    gaTrackPageView: ({ page } = {}) => {
      if (ModuleReactGA._config.initGAdone) {
        trackPageView(page)
      }
    },
    gaTrackEvent: ({ category, action }) => {
      if (ModuleReactGA._config.initGAdone) {
        ReactGA.event({
          category: category,
          action: action,
        })
      }
    },
  },
  _config: {
    initGAdone: false,
    isUsingDev: false,
    isUniversal: true,
    GAIdDev: "UA-119777650-2",
  },
  getMyKey: () => {
    return "reactga"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleReactGA, {})
    var GAId = HCF.getCF("GAId") || ModuleReactGA._config.GAIdDev
    if (HUtils.isLocalDev()) {
      GAId = ModuleReactGA._config.GAIdDev
    }
    if (GAId && GAId.indexOf("UA") > -1) {
      ModuleReactGA._config.isUniversal = true
    } else {
      ModuleReactGA._config.isUniversal = false
    }
    if (GAId) {
      initGA(GAId)
      //settimeout de lay title cho dung
      setTimeout(() => {
        HModules.runFunctionInModuleFnList(
          "reactga",
          "gaTrackCurrentPageView",
          []
        )
      }, 500)
      const history = HLink.getHistory()
      if (history && history.listen) {
        history.listen(h => {
          console.log("change history")
          HModules.runFunctionInModuleFnList(
            "reactga",
            "gaTrackCurrentPageView",
            []
          )
        })
      }
    }
  },
}

export default ModuleReactGA
