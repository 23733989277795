import { MyUI, MyUIHelpers } from "@macashipo/mlib"

import O from "./UIContentsTypes/o"
import T from "./UIContentsTypes/t"
import TCard from "./UIContentsTypes/t_card"
import TTable from "./UIContentsTypes/t_table"
import TTooltip from "./UIContentsTypes/t_tooltip"
import TInputCheck from "./UIContentsTypes/t_input_check"
import TInputText from "./UIContentsTypes/t_input_text"
import TImg from "./UIContentsTypes/t_img"
import THtml from "./UIContentsTypes/t_html"

const ListNotLazy = {
  o: O,
  t: T,
  t_div: T,
  t_card: TCard,
  t_table: TTable,
  t_tooltip: TTooltip,
  t_input_check: TInputCheck,
  t_input_text: TInputText,
  t_img: TImg,
  t_html: THtml,
}

MyUIHelpers.addTypesFromListWithoutLazy(ListNotLazy)
export default MyUI
