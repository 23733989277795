import React from "react"

export default function ScreenLoading() {
  return (
    <div className="zz-fullcenter">
      <svg className="zz-spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
  )
}
