import React from "react"
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap"

function PopoverBoostrap(props) {
  const [open, setOpen] = React.useState(false)
  const { targetId, overlay, styleWrap } = props
  const toggle = () => {
    setOpen(!open)
  }
  return (
    <div style={{ textAlign: "center" }}>
      {props.children ? (
        <div id={targetId || "Popover1"} style={styleWrap} onClick={toggle}>
          {props.children}
        </div>
      ) : (
        <Button id="Popover1" onClick={toggle}>
          Popover
        </Button>
      )}
      <Popover
        placement="bottom-start"
        isOpen={open}
        target={targetId || "Popover1"}
        toggle={toggle}
        fade={false}
      >
        {props.title && <PopoverHeader>{props.title}</PopoverHeader>}
        <PopoverBody>
          {typeof overlay == "function"
            ? overlay({
                hidePopover: () => {
                  setOpen(false)
                },
              })
            : overlay}
        </PopoverBody>
      </Popover>
    </div>
  )
}

export default PopoverBoostrap
