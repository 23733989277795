import React from "react"
import MyFormControlHelpers from "src/core/components/MyForm/MyFormControlHelpers"
const MFCOnlyLabel = React.lazy(() => import("./Types/only_label"))
const V1PageMFCGroupRow = React.lazy(
  () => import("src/core/modules/v1page/MyForm/Types/v1_mfc_group_row")
)
const MFCArrayForm = React.lazy(() => import("./Types/array_form"))
const List = {
  only_label: MFCOnlyLabel,
  v1_mfc_group_row: V1PageMFCGroupRow,
  array_form: MFCArrayForm,
}
const MyControlForm = {
  init() {
    MyFormControlHelpers.addTypesFromListLazy(List)
  },
}

export default MyControlForm
