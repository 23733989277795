import React from "react"
import { MyLibHelpers } from "@macashipo/mlib"

const MLSelect2 = React.lazy(() => import("./Types/select2"))
const ML_select2_required = React.lazy(() => import("./Types/select2_required"))
const MLMonacoEditor = React.lazy(() => import("./Types/monacoeditor"))
const ML_chromepicker = React.lazy(() => import("./Types/chromepicker"))
const ML_code_mirror = React.lazy(() => import("./Types/code_mirror"))
const ML_diff_viewer = React.lazy(() => import("./Types/diff_viewer"))
const ML_draft_wysiwyg = React.lazy(() => import("./Types/draft_wysiwyg"))
const ML_react_rte = React.lazy(() => import("./Types/react_rte"))
const ML_ckeditor5 = React.lazy(() => import("./Types/ckeditor5"))
const ML_ckeditor5_min = React.lazy(() => import("./Types/ckeditor5_min"))
const ML_ckeditor5_email = React.lazy(() => import("./Types/ckeditor5_email"))
const ML_pdfexpress = React.lazy(() => import("./Types/pdfexpress"))
const ML_dearflip = React.lazy(() => import("./Types/dearflip"))
const ML_big_calendar = React.lazy(() => import("./Types/big_calendar"))
const List = {
  big_calendar: ML_big_calendar,
  dearflip: ML_dearflip,
  pdfexpress: ML_pdfexpress,
  ckeditor5: ML_ckeditor5,
  ckeditor5_min: ML_ckeditor5_min,
  ckeditor5_email: ML_ckeditor5_email,
  draft_wysiwyg: ML_draft_wysiwyg,
  react_rte: ML_react_rte,
  diff_viewer: ML_diff_viewer,
  code_mirror: ML_code_mirror,
  chromepicker: ML_chromepicker,
  select2: MLSelect2,
  select2_required: ML_select2_required,
  monacoeditor: MLMonacoEditor,
}
const MyLibOne = {
  init() {
    MyLibHelpers.addTypesFromListLazy(List)
  },
}

export default MyLibOne
