import React from "react"
import { HUtils } from "./_helpers"
class MyUI_HTML extends React.Component {
  render() {
    const { html, className, style, data, onClick, fnList } = this.props
    let _data = data
    if (_data == null && fnList && fnList.myData) {
      _data = fnList.myData()
      console.warn("_data:", _data)
    }
    // console.warn("MyUI_HTML:",this.props);
    if (_data && html && typeof _data == "object") {
      let _html = HUtils.SquareBracket.replace(html, _data)
      return (
        <div
          className={className}
          style={style}
          onClick={onClick}
          dangerouslySetInnerHTML={{ __html: _html }}
        />
      )
    } else if (html) {
      return (
        <div
          className={className}
          style={style}
          onClick={onClick}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )
    }
    return <div></div>
  }
}

export default MyUI_HTML
