import React from "react"
import { MyCardHelpers } from "@macashipo/mlib"
import MC_with_myui from "./Types/with_myui"
const ListNoLazy = {
  with_myui: MC_with_myui,
}
const MyCardOne = {
  init() {
    MyCardHelpers.addTypesFromListWithoutLazy(ListNoLazy)
  },
}

export default MyCardOne
