import { ApiGeneric } from "src/services/apiServices"
import { fnListUI } from "src/helpers/fnList"
import { HCF, HLink, HUtils } from "@macashipo/mlib"
import { isIphone } from "../U"

export const FlowApiPost = () => {}

export const FlowApiGet = () => {}

export const FlowApiGeneric = ({
  request,
  cbSuccess,
  cbError,
  cbFinal,
  ...rest
} = {}) => {
  //v1
  ApiGeneric({
    request,
    successCallBack: response => {
      let _verAdmin = HCF.getCF("verAdmin")
      if (_verAdmin != "v5") {
        fnListUI.fnShowToast(response.Msg)
      }
      if (cbSuccess) {
        cbSuccess(response)
      }
      if (cbFinal) {
        cbFinal({ response })
      }
    },
    errorCallBack: (error, response) => {
      if (cbError) {
        cbError(error, response)
      }
      if (cbFinal) {
        cbFinal({ error, response })
      }
    },
    ...rest,
  })
}

export const FlowApiUpdateFields = ({
  row,
  apiController,
  apiName = "UpdateFields",
  multiObjUpdate,
  sharedRequestData = {},
  cbSuccess,
  cbError,
} = {}) => {
  //v1
  ApiGeneric({
    request: {
      method: "POST",
      path: apiController,
      name: apiName,
    },
    data: {
      Id: row.Id,
      ProjectId: row.ProjectId,
      RequestData: sharedRequestData,
      Values: Object.keys(multiObjUpdate).map(v => {
        return {
          FieldName: v,
          NewValue: multiObjUpdate[v],
        }
      }),
    },
    successCallBack: response => {
      if (cbSuccess) {
        cbSuccess(response)
      }
    },
    errorCallBack: (error, response) => {
      if (cbError) {
        cbError(error, response)
      }
    },
  })
}

export const FlowApiRefreshCache = () => {
  FlowApiGeneric({
    request: {
      method: "POST",
      path: "Cache",
      name: "Refresh",
    },
    data: {
      Id: "ALL",
    },
    cbSuccess: () => {
      // an di vì v1 ko reload
      // window.location.reload()
    },
  })
}

// 18/11/2023: Bo sung FullPathListName, FullPathName
export const FlowApiShowFullPath = ({
  response,
  configFullPath,
  target,
  autoOpenLink,
  linkOpen,
} = {}) => {
  if (configFullPath) {
    let _target = target || "_blank"
    let _autoOpenLink = autoOpenLink != null ? autoOpenLink : false
    if (configFullPath.FullPathNewWindow === false) {
      _target = "_self"
    }
    let _isDownload = false
    if (configFullPath.FullPathDownload === true) {
      _isDownload = true
    }
    let _linkOpen = configFullPath.FullPath || linkOpen
    if (
      _linkOpen &&
      _linkOpen.indexOf("[") > -1 &&
      _linkOpen.indexOf("]") > -1
    ) {
      _linkOpen = HUtils.getTextWithData(_linkOpen, configFullPath)
    }
    if (_linkOpen) {
      if (_autoOpenLink === true) {
        if (isIphone()) {
          setTimeout(() => {
            HLink.openUrl(_linkOpen, _target)
          })
        } else {
          HLink.openUrl(_linkOpen, _target)
        }
      } else {
        fnListUI.fnshowDialog({
          title: configFullPath.FullPathTitle || "Link",
          msg: (
            <div style={{ wordWrap: "break-word" }}>
              <div>{configFullPath.FullPathInfo}</div>
              <a href={_linkOpen} target={_target} download={_isDownload}>
                {configFullPath.FullPathName || _linkOpen}
              </a>
            </div>
          ),
        })
      }
    } else if (
      configFullPath.FullPathList &&
      configFullPath.FullPathList.length > 0
    ) {
      fnListUI.fnshowDialog({
        title: configFullPath.FullPathTitle || "Links",
        msg: (
          <div style={{ wordWrap: "break-word" }}>
            <div>{configFullPath.FullPathInfo}</div>
            {configFullPath.FullPathList.map(v => {
              return (
                <div>
                  <a href={v} target={_target} download={_isDownload}>
                    {v}
                  </a>
                </div>
              )
            })}
          </div>
        ),
      })
    } else if (
      configFullPath.FullPathListName &&
      configFullPath.FullPathListName.length > 0
    ) {
      fnListUI.fnshowDialog({
        title: configFullPath.FullPathTitle || "Links",
        msg: (
          <div style={{ wordWrap: "break-word" }}>
            {configFullPath.FullPathListName.map(v => {
              return (
                <div>
                  <div>{v.FullPathInfo}</div>
                  <a href={v.FullPath} target={_target} download={_isDownload}>
                    {v.FullPathName || v.FullPath}
                  </a>
                </div>
              )
            })}
          </div>
        ),
      })
    }
  }
}
