import React from "react"
import { useHistory } from "react-router-dom"
import { fnListConfigApp } from "src/helpers/fnList"
import LoginSocialGoogle from "./LoginSocialGoogleNew"
import styled from "styled-components"
import i18next from "i18next"
const Wrap = styled.div`
  & .a-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
      flex: 1;
      border: 1px solid #777;
      border-color: rgba(0, 0, 0, 0.2);
      border-bottom-color: #333;
      border-bottom-color: rgba(0, 0, 0, 0.4);
      color: #fff;
      -moz-box-shadow: inset 0 0.08em 0 rgba(255, 255, 255, 0.4),
        inset 0 0 0.1em rgba(255, 255, 255, 0.9);
      -webkit-box-shadow: inset 0 0.08em 0 rgb(255 255 255 / 40%),
        inset 0 0 0.1em rgb(255 255 255 / 90%);
      box-shadow: inset 0 0.08em 0 rgb(255 255 255 / 40%),
        inset 0 0 0.1em rgb(255 255 255 / 90%);
      cursor: pointer;
      display: inline-block;
      font: bold 100%/2.1 "Lucida Grande", Tahoma, sans-serif;
      padding: 0 0.95em 0 0;
      text-align: center;
      text-decoration: none;
      text-shadow: 0 1px 0 rgb(0 0 0 / 50%);
      white-space: nowrap;
      vertical-align: middle;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      position: relative;
      -moz-border-radius: 0.3em;
      -webkit-border-radius: 0.3em;
      border-radius: 0.3em;
      background-image: -moz-linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.05) 49%,
        rgba(0, 0, 0, 0.05) 51%,
        rgba(0, 0, 0, 0.1)
      );
      background-image: -ms-linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.05) 49%,
        rgba(0, 0, 0, 0.05) 51%,
        rgba(0, 0, 0, 0.1)
      );
      background-image: -o-linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.05) 49%,
        rgba(0, 0, 0, 0.05) 51%,
        rgba(0, 0, 0, 0.1)
      );
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.1)),
        color-stop(49%, rgba(255, 255, 255, 0.05)),
        color-stop(51%, rgba(0, 0, 0, 0.05)),
        to(rgba(0, 0, 0, 0.1))
      );
      background-image: -webkit-linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.05) 49%,
        rgba(0, 0, 0, 0.05) 51%,
        rgba(0, 0, 0, 0.1)
      );
      background-image: linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.05) 49%,
        rgba(0, 0, 0, 0.05) 51%,
        rgba(0, 0, 0, 0.1)
      );
      &:hover {
        background-image: -moz-linear-gradient(
          rgba(255, 255, 255, 0.15) 49%,
          rgba(0, 0, 0, 0.1) 51%,
          rgba(0, 0, 0, 0.15)
        );
        background-image: -ms-linear-gradient(
          rgba(255, 255, 255, 0.15) 49%,
          rgba(0, 0, 0, 0.1) 51%,
          rgba(0, 0, 0, 0.15)
        );
        background-image: -o-linear-gradient(
          rgba(255, 255, 255, 0.15) 49%,
          rgba(0, 0, 0, 0.1) 51%,
          rgba(0, 0, 0, 0.15)
        );
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(255, 255, 255, 0.15)),
          color-stop(49%, rgba(255, 255, 255, 0.15)),
          color-stop(51%, rgba(0, 0, 0, 0.1)),
          to(rgba(0, 0, 0, 0.15))
        );
        background-image: -webkit-linear-gradient(
          rgba(255, 255, 255, 0.15) 49%,
          rgba(0, 0, 0, 0.1) 51%,
          rgba(0, 0, 0, 0.15)
        );
        background-image: linear-gradient(
          rgba(255, 255, 255, 0.15) 49%,
          rgba(0, 0, 0, 0.1) 51%,
          rgba(0, 0, 0, 0.15)
        );
      }
    }
  }
`
const WrapSep = styled.div`
  float: left;
  width: 100%;
  border-top: 1px solid #edf2f9;
  text-align: center;
  margin: 30px 0 0;
  & b {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #fff;
    display: inline-block;
    border: 1px solid #edf2f9;
    border-radius: 50%;
    position: relative;
    top: -22px;
    z-index: 1;
    color: #8d9399;
  }
`
const LoginSocial = props => {
  const history = useHistory()
  const hasLoginGoogle = fnListConfigApp.getHasLoginSocialGoogle()
  const hasLoginFacebook = fnListConfigApp.getHasLoginSocialFacebook()
  if (hasLoginGoogle || hasLoginFacebook) {
    return (
      <Wrap>
        <div className="a-list">
          {hasLoginGoogle && <LoginSocialGoogle {...props} />}
          {hasLoginFacebook && <div>Facebook</div>}
        </div>
        <WrapSep>
          <b>{i18next.t("login.or")}</b>
        </WrapSep>
      </Wrap>
    )
  }
  return <div></div>
}

export default LoginSocial
