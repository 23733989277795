import React from "react"
import { MyTableCellHelpers, HUtils, HJson, HCF } from "@macashipo/mlib"
import { FlowGetCanShow, KMore } from "src/helpers/Flows/TableCell"
import { KConfigApp } from "src/helpers/K"

const MTCReadonly = React.lazy(() => import("./Types/readonly"))
// const MTCText = React.lazy(() => import("./Types/text"))
const MTCTextarea = React.lazy(() => import("./Types/textarea"))
const MTC_textarea_with_count = React.lazy(() =>
  import("./Types/textarea_with_count")
)
const MTCApi = React.lazy(() => import("./Types/api"))
const MTCJsonEditor = React.lazy(() => import("./Types/json_editor"))
const MTCCheckbox = React.lazy(() => import("./Types/checkbox"))
const MTCCheckboxNull = React.lazy(() => import("./Types/checkbox_null"))
const MTCExpandMulti = React.lazy(() => import("./Types/expand_multi"))
// const MTCSelect = React.lazy(() => import("./Types/select"))
const MTCSelect2Modal = React.lazy(() => import("./Types/select2_modal"))
const MTCSelect2ModalApi = React.lazy(() => import("./Types/select2_modal_api"))
const MTCSelectModern = React.lazy(() => import("./Types/select_modern"))
const MTCDelete = React.lazy(() => import("./Types/delete"))
const MTCTextareafull = React.lazy(() => import("./Types/textareafull"))
const MTCLink = React.lazy(() => import("./Types/link"))
const MTCNotexist = React.lazy(() => import("./Types/notexist"))
const MTCFiles = React.lazy(() => import("./Types/files"))
const MTCDate = React.lazy(() => import("./Types/date"))
const MTCDateTime = React.lazy(() => import("./Types/datetime"))
const MTCDateText = React.lazy(() => import("./Types/date_text"))
const MTC_datetime_text = React.lazy(() => import("./Types/datetime_text"))
const MTCArrayEditableModal = React.lazy(() =>
  import("./Types/array_editable_modal")
)
const MTCActionsMenu = React.lazy(() => import("./Types/actions_menu"))
const MTCActionsMenuMulti = React.lazy(() =>
  import("./Types/actions_menu_multi")
)
const MTCForm = React.lazy(() => import("./Types/form"))
const MTCReadOnlyArray = React.lazy(() => import("./Types/readonly_array"))
const MTCurrency = React.lazy(() => import("./Types/currency"))
const MTCNumberDecimal = React.lazy(() => import("./Types/number_decimal"))
const MTCNumberNatural = React.lazy(() => import("./Types/number_natural"))
const MTCNumber = React.lazy(() => import("./Types/number"))
const MTCListText = React.lazy(() => import("./Types/list_text"))
const MTCWithMyUI = React.lazy(() => import("./Types/with_myui"))
const MTCActions = React.lazy(() => import("./Types/actions"))
const MTCCopy = React.lazy(() => import("./Types/copy"))
const MTC_readonly_date = React.lazy(() => import("./Types/readonly_date"))
const MTC_pickcolor = React.lazy(() => import("./Types/pickcolor"))
const MTC_chromecolor = React.lazy(() => import("./Types/chromecolor"))
const MTC_readonly_html = React.lazy(() => import("./Types/readonly_html"))
const MTCJsonEditorValue = React.lazy(() => import("./Types/json_editor_value"))
// const MTCText = React.lazy(() => import("./Types/text"))
const MTCSuperMan = React.lazy(() => import("./Types/superman"))
const MTCReadonlyWithTree = React.lazy(() =>
  import("./Types/readonly_with_tree")
)
const MTC_modal_table_extra = React.lazy(() =>
  import("./Types/modal_table_extra")
)
const MTC_quality = React.lazy(() => import("./Types/quality"))
const MTC_readonly_status = React.lazy(() => import("./Types/readonly_status"))
const MTC__barTopRight = React.lazy(() => import("./Types/_barTopRight"))
const MTCSelect2ModalTable = React.lazy(() =>
  import("./Types/select2_modal_table")
)
const MTC__SelectOnModal = React.lazy(() => import("./Types/_select_on_modal"))
const MTC_html_editor = React.lazy(() => import("./Types/html_editor"))
const MTCFormModalTable = React.lazy(() => import("./Types/form_modal_table"))
const MTCDevTest = React.lazy(() => import("./Types/dev_test"))
const MTCSelect2 = React.lazy(() => import("./Types/select2"))
const MTC_cs_email_tp_action = React.lazy(() =>
  import("./Types/cs_email_tp_action")
)
const MTC_readonly_datetime = React.lazy(() =>
  import("./Types/readonly_datetime")
)
const MTC_readonly_percent = React.lazy(() =>
  import("./Types/readonly_percent")
)
const MTC_readonly_progress = React.lazy(() =>
  import("./Types/readonly_progress")
)
const MTC_percent = React.lazy(() => import("./Types/percent"))
//custom
const MTC_checkbox_disabled = React.lazy(() =>
  import("./Types/checkbox_disabled")
)

const MTCObjEditor = React.lazy(() => import("./Types/obj_editor"))
const MTCTestCanEdit = React.lazy(() => import("./Types/test_canedit"))
const MTC_quantity = React.lazy(() => import("./Types/quantity"))
const MTC_modal = React.lazy(() => import("./Types/modal"))
const MTC_select_icon = React.lazy(() => import("./Types/select_icon"))
const MTC_select_tag = React.lazy(() => import("./Types/select_tag"))
const MTC_text_select = React.lazy(() => import("./Types/text_select"))
const MTC_array_editable = React.lazy(() => import("./Types/array_editable"))
const MTC_upload_one_photo = React.lazy(() =>
  import("./Types/upload_one_photo")
)
const MTC_date_native = React.lazy(() => import("./Types/date_native"))
const MTC_remove_row = React.lazy(() => import("./Types/remove_row"))
const MTC_suggest_address = React.lazy(() => import("./Types/suggest_address"))
const MTC_pos_printbill = React.lazy(() => import("./Types/pos_printbill"))

const List = {
  pos_printbill: MTC_pos_printbill,
  suggest_address: MTC_suggest_address,
  actions_menu_multi: MTCActionsMenuMulti,
  remove_row: MTC_remove_row,
  date_native: MTC_date_native,
  textarea_with_count: MTC_textarea_with_count,
  upload_one_photo: MTC_upload_one_photo,
  array_editable: MTC_array_editable,
  text_select: MTC_text_select,
  select_tag: MTC_select_tag,
  _select_on_modal: MTC__SelectOnModal,
  _barTopRight: MTC__barTopRight,
  cs_email_tp_action: MTC_cs_email_tp_action,
  dev_test: MTCDevTest,
  form_modal_table: MTCFormModalTable,
  html_editor: MTC_html_editor,
  select2_modal_table: MTCSelect2ModalTable,
  readonly_status: MTC_readonly_status,
  quality: MTC_quality,
  modal_table_extra: MTC_modal_table_extra,
  readonly_with_tree: MTCReadonlyWithTree,
  // text: MTCText,
  superman: MTCSuperMan,
  readonly_html: MTC_readonly_html,
  chromecolor: MTC_chromecolor,
  pickcolor: MTC_pickcolor,
  readonly_date: MTC_readonly_date,
  with_myui: MTCWithMyUI,
  list_text: MTCListText,
  number: MTCNumber,
  number_decimal: MTCNumberDecimal,
  number_natural: MTCNumberNatural,
  input_format_numeral_money: MTCurrency,
  currency: MTCurrency,
  readonly_array: MTCReadOnlyArray,
  form: MTCForm,
  actions: MTCActions,
  action: MTCActions,
  actions_menu: MTCActionsMenu,
  array_editable_modal: MTCArrayEditableModal,
  date: MTCDate,
  datepicker: MTCDate,
  datetime: MTCDateTime,
  date_text: MTCDateText,
  datetime_text: MTC_datetime_text,
  link: MTCLink,
  readonly: MTCReadonly,
  text: MTCTextarea, //xai chung voi textarea
  select: MTCSelectModern,
  select2: MTCSelect2,
  // select2_addable: MTCSelectModern,
  select2_modal: MTCSelect2Modal,
  select2_modal_api: MTCSelect2ModalApi,
  select_modern: MTCSelectModern,
  textarea: MTCTextarea,
  textareafull: MTCTextareafull,
  api: MTCApi,
  json_editor: MTCJsonEditor,
  json_editor_value: MTCJsonEditorValue,
  checkbox: MTCCheckbox,
  checkbox_null: MTCCheckboxNull,
  expand_multi: MTCExpandMulti,
  expand_api: MTCExpandMulti,
  delete: MTCDelete,
  notexist: MTCNotexist,
  files: MTCFiles,
  readonly_datetime: MTC_readonly_datetime,
  readonly_percent: MTC_readonly_percent,
  readonly_progress: MTC_readonly_progress,
  // copy: MTCCopy,
  //custom
  checkbox_disabled: MTC_checkbox_disabled,
  test_canedit: MTCTestCanEdit,
  quantity: MTC_quantity,
  modal: MTC_modal,
  percent: MTC_percent,
  select_icon: MTC_select_icon,
}
const MyTableCellOne = {
  init() {
    MyTableCellHelpers.addTypesFromListLazy(List)
    MyTableCellHelpers.Types["_auto"] = (cell, row, extra, index) => {
      let _type = HUtils.get(extra, "Type.type")
      let _more = HUtils.get(extra, "Type.more")
      // console.log("_more:", _more)
      if (
        _more &&
        typeof _more == "string" &&
        _more.indexOf(KMore.typeAdmin) > -1 &&
        HCF.getCF(KConfigApp.verAdmin) == "v5"
      ) {
        let _moreObj = HJson.getObj(_more)
        if (_moreObj && _moreObj[KMore.typeAdmin]) {
          _type = _moreObj[KMore.typeAdmin]
        }

        //check canshow chung
        // let _canShow = FlowGetCanShow({ extra, row, more: _moreObj })
        // if (_canShow === false) {
        //   return MyTableCellHelpers.Types._empty({ cell, row, extra, index })
        // }
      }
      if (MyTableCellHelpers.Types[_type]) {
        let TagCell = MyTableCellHelpers.Types[_type]
        //phai tra ve dang tag moi de nhan dien trong TableColumn
        return <TagCell cell={cell} row={row} extra={extra} index={index} />
      } else {
        if (MyTableCellHelpers.Types.notexist) {
          return MyTableCellHelpers.Types.notexist({ cell, row, extra, index })
        }
        return MyTableCellHelpers.Types._empty({ cell, row, extra, index })
      }
    }
    MyTableCellHelpers["ConfigByTypes"] = {
      api: {
        isHide: oneTableCell => {
          let _isHide = null
          if (oneTableCell) {
            let _valueCell = oneTableCell.getValue()
            // console.log("check is Hide")
            if (_valueCell === "" || _valueCell === null) {
              _isHide = true
            }
          }
          if (
            _isHide &&
            oneTableCell.more &&
            oneTableCell.more[KMore.alwayShow] === true
          ) {
            return false
          }
          return _isHide
        },
      },
      // datetime: {
      //   moreAttrs: {
      //     moreOptions: {
      //       enableTime: true,
      //       dateFormat: "d/m/Y H:i",
      //     },
      //   },
      // },
    }
  },
}

export default MyTableCellOne
