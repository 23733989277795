import * as types from "./Login.constants"
import produce from "immer"

const initialState = {
  loading: false,
  countRefresh: 1,
}

export const loginReducer = (state = initialState, action) =>
  produce(state, draft => {
    // console.warn("loginReducer", state, draft, action)
    switch (action.type) {
      case types.FETCH_LOGIN_PENDING:
        draft.loading = true
        break
      case types.FETCH_LOGIN_SUCCESS:
        draft.loading = false
        draft.countRefresh = draft.countRefresh + 1
        break
      case types.FETCH_LOGIN_ERROR:
        draft.loading = false
        break
      default:
        return state
    }
  })
