import React from "react"

class StateLoading extends React.Component {
  componentDidMount() {}

  render() {
    return <div>Loading...</div>
  }
}

export default StateLoading
