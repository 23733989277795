import { HFormat, MyUI } from "@macashipo/mlib"
import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  width: 100%;
  display: grid;
  gap: 10px 1%;
`
export default function MUIGrid(props) {
  const { data, typeItem, numCol } = props
  let _templateColumns = ""
  let _numCol = HFormat.format(numCol, {
    type: "numColByWidth",
    defaultValue: 5,
  })
  for (let i = 0; i < _numCol; i++) {
    _templateColumns += "1fr"
    if (i < _numCol - 1) {
      _templateColumns += " "
    }
  }
  if (props.minmaxAutoFit) {
    _templateColumns = `repeat(auto-fit, minmax(${props.minmaxAutoFit}px, 1fr))`
  } else if (props.minmaxAutoFill) {
    _templateColumns = `repeat(auto-fill, minmax(${props.minmaxAutoFit}px, 1fr))`
  }
  // console.warn("render grid:", _numCol, _templateColumns)
  return (
    <Wrap
      className="mui-grid"
      style={{ gridTemplateColumns: _templateColumns }}
    >
      {data &&
        data.length > 0 &&
        data.map((v, i) => {
          if (props.renderItem) {
            return props.renderItem(v, i)
          }
          return (
            <MyUI
              key={i}
              index={i}
              type={typeItem}
              data={v}
              allData={data}
              configItem={props.configItem}
              fnList={props.fnList}
              {...props.morePropsItem}
            />
          )
        })}
    </Wrap>
  )
}
