import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import { HUtils, HConstant } from "./_helpers"
import MyUI_InputExt from "./t_input_ext"
class MyUI_InputCheck extends MyUI_InputExt {
  _onChange = ev => {
    const { fnList, f } = this.props
    let _v = ev.currentTarget.checked
    this.setState(
      {
        value: _v,
      },
      () => {
        HUtils.runFnList(fnList, HConstant.UIFnCore.myChange, this, [
          f,
          _v,
          { ...this.props },
        ])
      }
    )
  }
  parseMore = () => {
    let _more = {}
    return _more
  }
  render() {
    let {
      className,
      //
      innerRef,
      valid,
      invalid,
      custom,
      disabled,
      m,
      ...attributes
    } = this.props

    // render
    const classes = classnames(
      custom ? "custom-control-input" : "form-check-input",
      invalid && "is-invalid",
      valid && "is-valid",
      className
    )

    return (
      <input
        className={classes}
        type="checkbox"
        disabled={disabled}
        checked={this.getValue()}
        onChange={this._onChange}
        {...m}
        {...this.parseMore()}
        ref={innerRef}
      />
    )
  }
}
MyUI_InputCheck.propTypes = {
  m: PropTypes.any, //more props
  f: PropTypes.string, //field Name
  dfe: PropTypes.bool, //default empty
  df: PropTypes.bool, //default value
  v: PropTypes.bool, // value
}
MyUI_InputCheck.defaultProps = {
  dfe: false,
}
export default MyUI_InputCheck
