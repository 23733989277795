import { MyChartHelpers } from "@macashipo/mlib"
import React from "react"

const MCH_gg_column = React.lazy(() => import("./Types/gg_column"))
const MCH_gg_line = React.lazy(() => import("./Types/gg_line"))
const MCH_gg_pie = React.lazy(() => import("./Types/gg_pie"))
const MCH_gg_area = React.lazy(() => import("./Types/gg_area"))
const MCH_gg_bar = React.lazy(() => import("./Types/gg_bar"))
const MCH_gg_bubble = React.lazy(() => import("./Types/gg_bubble"))
const MCH_gg_combo = React.lazy(() => import("./Types/gg_combo"))
const MCH_gg_timeline = React.lazy(() => import("./Types/gg_timeline"))
const MCH_gg_orgchart = React.lazy(() => import("./Types/gg_orgchart"))
const MCH_gg_material_bar = React.lazy(() => import("./Types/gg_material_bar"))
//chartjs
const MCH_chartjs_line = React.lazy(() => import("./Types/chartjs_line"))
const MCH_chartjs_bar = React.lazy(() => import("./Types/chartjs_bar"))
const MCH_chartjs_pie = React.lazy(() => import("./Types/chartjs_pie"))

const List = {
  gg_column: MCH_gg_column,
  gg_line: MCH_gg_line,
  gg_pie: MCH_gg_pie,
  gg_area: MCH_gg_area,
  gg_bar: MCH_gg_bar,
  gg_bubble: MCH_gg_bubble,
  gg_combo: MCH_gg_combo,
  gg_timeline: MCH_gg_timeline,
  gg_orgchart: MCH_gg_orgchart,
  //
  gg_material_bar: MCH_gg_material_bar,
  //chartjs
  chartjs_line: MCH_chartjs_line,
  chartjs_bar: MCH_chartjs_bar,
  chartjs_pie: MCH_chartjs_pie,
}
const MyChartOne = {
  init() {
    MyChartHelpers.addTypesFromListLazy(List)
  },
}

export default MyChartOne
