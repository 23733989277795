import { HCF, HEE, HLink, HLocalStorage, HUtils } from "@macashipo/mlib"
import { HApi, HConfig, HConstant } from "../helpers"
import { KStorage, KConfigApp, KLocalStorage, KObserver } from "src/helpers/K"
import HI18, {
  initLanguageList,
  loadExtLngFromFile,
} from "src/core/helpers/i18n"
import { FlowClientClearLocalCache } from "src/helpers/Flows/Client"
import { registerAfterLogout } from "../helpers/auth"
import { FlowParseDataRoutes } from "src/helpers/Flows/Routes"
import HObserver from "src/helpers/Observer"
interface IModuleFirstSetting extends ICore.IOneModule {
  requestFirstSetting: () => void
  parseFirstSetting: (data: object, opts?: any) => void
}
const ModuleFirstSetting: IModuleFirstSetting = {
  _key: "first_setting",
  _fnList: {
    fnRemoveUserCodeInLocalFirstSetting: () => {
      let _lastFirstSetting = HLocalStorage.getObj(
        KLocalStorage.lastFirstSetting,
        {}
      )
      if (_lastFirstSetting) {
        delete _lastFirstSetting["UserLanguageCode"]
      }
      HLocalStorage.saveObj(KLocalStorage.lastFirstSetting, _lastFirstSetting)
    },
  },
  _config: {},
  getMyKey: () => {
    return "first_setting"
  },
  runAfterInit: (HConfig: any) => {
    let _lastFirstSetting = HLocalStorage.getObj(
      KLocalStorage.lastFirstSetting,
      {}
    )
    if (_lastFirstSetting) {
      ModuleFirstSetting.parseFirstSetting(_lastFirstSetting, {
        fromLocal: true,
      })
    }
    ModuleFirstSetting.requestFirstSetting()
    registerAfterLogout("firstsetting", () => {
      HLocalStorage.removeItem(KLocalStorage.lastFirstSetting)
      HLocalStorage.removeItem(KStorage.HomeURL)
    })
    HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.registerAfterLogin, [
      () => {
        console.warn("ModuleFirstSetting: call request when after login ")
        ModuleFirstSetting.requestFirstSetting()
      },
    ])
    HCF.setCF("fnModuleFirstSetting", ModuleFirstSetting._fnList)
  },

  requestFirstSetting: () => {
    let _uiAppConfig =
      HUtils.runFuntion(HCF.CF, "fnGetRequestUIAppConfigFirstSetting") || {}
    HApi.apiPost({
      path: "GlobalAppSetting",
      name: "FisrtSetting",
      data: {
        UIAppConfig: _uiAppConfig,
      },
    })
      .then(response => {
        if (response.Data) {
          ModuleFirstSetting.parseFirstSetting(response.Data, {
            fromServer: true,
          })
          HLocalStorage.saveObj(KLocalStorage.lastFirstSetting, response.Data)
          HObserver.trigger(KObserver.after_requestFirstSetting, [{}])
        }
      })
      .catch(error => {})
  },
  parseFirstSetting: (data, { fromServer, fromLocal }: any = {}) => {
    if (data) {
      HCF.setCFMultiObj(data)
      if (data["DefaultValues"]) {
        HCF.setCFMultiObj(data["DefaultValues"])
        //chuyen qua ham initLanguageList
        // if (data["DefaultValues"]["listExtLng"]) {
        //   let _listExtLng = data["DefaultValues"]["listExtLng"]
        //   if (Array.isArray(_listExtLng)) {
        //     for (let i = 0; i < _listExtLng.length; i++) {
        //       loadExtLngFromFile(_listExtLng[i])
        //     }
        //   }
        // }
        let _needClearLocalCacheBefore = true
        if (data["DefaultValues"]["needClearLocalCacheBefore"] != null) {
          let _needClearLocalCacheBeforeServer =
            data["DefaultValues"]["needClearLocalCacheBefore"]
          if (_needClearLocalCacheBeforeServer > new Date().getTime()) {
            _needClearLocalCacheBefore = false
          }
        }
        if (
          _needClearLocalCacheBefore === true &&
          data["DefaultValues"]["needClearLocalCache"] != null
        ) {
          let _needClearLocalCache =
            data["DefaultValues"]["needClearLocalCache"]
          let _needClear = false
          if (Array.isArray(_needClearLocalCache)) {
            let _user_id = HCF.getCFMultiLevel("AuthInfo.user_id")
            if (
              _user_id != null &&
              _needClearLocalCache.indexOf(_user_id) > -1
            ) {
              _needClear = true
            }
          } else if (_needClearLocalCache === true) {
            _needClear = true
          }
          if (_needClear) {
            FlowClientClearLocalCache({ withoutReload: true })
          }
        }
      }
      if (data["DefaultHomeURL"]) {
        HCF.setCF(KConfigApp.home_url, data["DefaultHomeURL"])
        HLocalStorage.saveString(KStorage.HomeURL, data["DefaultHomeURL"])
      }
      if (data["MediaAcceptedType"]) {
        HCF.setCF(KConfigApp.fileUploadSupport, data["MediaAcceptedType"])
      }
      if (data["UIAppConfig"]) {
        HCF.setCFMultiObj(data["UIAppConfig"])
      }

      if (data["UserLanguageCode"]) {
        HI18.checkLanguageFromServer(data["UserLanguageCode"], {
          fromUserLanguageCode: true,
        })
      } else {
        HI18.checkLanguageFromServer(HCF.getCF("defaultLanguage"), {
          fromDefaultLanguage: true,
        })
      }

      //test
      // loadExtLng({
      //   a: 1,
      // })

      initLanguageList({
        listExtLng: HUtils.get(data, "DefaultValues.listExtLng"),
      })

      //public routes
      if (data["PublicApiScreenList"]) {
        let _dataRoutes = FlowParseDataRoutes(data["PublicApiScreenList"])
        HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.addPublicRoutes, [
          _dataRoutes,
        ])
      }

      //IsNeedCheckChangePass
      if (fromServer && data["IsNeedCheckChangePass"] === true) {
        window._IsNeedCheckChangePass = true
        window._Message_IsNeedChangePass = data["Message_IsNeedChangePass"]
        HLink.push("/my-profile?tab=changepw")
      }
    }
  },
}

export default ModuleFirstSetting
