import React from "react"
import { HText, HUtils, MyUI } from "@macashipo/mlib"
import { getData, getMoreProps, getStyle, getTypeMyUI } from "./_helpers"
import { HUtils_getTextWithData } from "src/helpers/Flows/MNeedUpdateMLib"
class MyUI_Data_Tag extends React.Component {
  constructor(props) {
    super(props)
    this._fontSize = 1
  }
  render() {
    const config = this.props.config || {}
    const data = getData(this.props)
    let _configChild = config.configChild
    // console.error("data_tag", this.props)
    let Tag = config.tag || "span"
    let _moreProps = getMoreProps(this.props)
    let _childIsMyUI = true
    let _childIsText = false
    let _textDisplay = ""
    if (typeof _configChild == "string" || _configChild == null) {
      _childIsMyUI = false
      _childIsText = true
      if (_configChild) {
        if (data && data[_configChild]) {
          _textDisplay = data[_configChild]
        } else {
          _textDisplay = HUtils_getTextWithData(_configChild, data)
        }
      }
      //neu ko phai myui thi chuyen quanh children
      _moreProps.children = _textDisplay
    }
    let _style = getStyle(this.props)
    if (_style.fontSize) {
      let _fontSizeInStyle = Number(_style.fontSize.replace(/[^0-9]/g, ""))
      if (_fontSizeInStyle != this._fontSize && this._fontSize == 1) {
        this._fontSize = _fontSizeInStyle
      }
    }
    let _minFontSize = config.minFontSize || 6
    _style.fontSize = `${this._fontSize}px`
    return (
      <Tag
        className={config.className}
        style={_style}
        {..._moreProps}
        ref={r => {
          if (r) {
            // console.warn("refff", r, r.clientHeight, r.scrollHeight)
            if (
              r.clientHeight < r.scrollHeight &&
              this._fontSize > _minFontSize
            ) {
              this._fontSize -= 1
              this.forceUpdate()
            } else if (
              r.clientWidth < r.scrollWidth &&
              this._fontSize > _minFontSize
            ) {
              this._fontSize -= 1
              this.forceUpdate()
            }
          }
        }}
      />
    )
  }
}

export default MyUI_Data_Tag
