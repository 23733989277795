import React from "react"
import {
  MyLib,
  MyPageHelpers,
  HCF,
  HUtils,
  HColor,
  MyLoading,
  CSuppense,
  MyBase,
} from "@macashipo/mlib"
import { MyModal } from "src/core/components"
import styled from "styled-components"

const WrapItem = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  &.a-nocard {
    padding: 0px;
  }
`
class Dev_Demo extends React.Component {
  render() {
    return (
      <div>
        <WrapItem className="a-nocard">Hello MyBase</WrapItem>
        <WrapItem className="card">
          select2_api
          <MyBase
            type="select2_api"
            value={null}
            more={{
              pathData: "Data.Data",
              configApi: {
                method: "POST",
                url: "Supplier/GetCustomerNotInValid",
              },
            }}
            fnList={{
              getSourceData: () => {
                return new Array(10).fill(1).map((v, i) => {
                  return {
                    Text: "a" + i,
                    Value: i,
                  }
                })
              },
            }}
            onChange={() => {}}
          />
        </WrapItem>
      </div>
    )
  }
}

//add page dev
Dev_Demo.initPageDev = () => {
  MyPageHelpers.addTypesFromListLazy({
    dev_mybase: Dev_Demo,
  })
  HUtils.runFuntion(HCF.CF, "addPublicRoutes", [
    [
      {
        Id: "dev_mybase",
        UIUrl: "/dev/mybase",
        UIType: "dev_mybase",
      },
    ],
  ])
}

export default Dev_Demo
