import { FlowApiShowFullPath } from "src/helpers/Flows/Api"

export const parseResponseButtonApi = function (
  res: any,
  {
    target = "_blank",
    autoOpenLink = false,
    linkOpen = null,
    noCheckFullPath = false,
  } = {}
) {
  if (res && res.Data && noCheckFullPath !== true) {
    FlowApiShowFullPath({
      response: res,
      configFullPath: res.Data,
      target,
      autoOpenLink,
      linkOpen,
    })
  }
}
