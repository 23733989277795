import MLG from "./Dev_MyLayout_grid"
const MyLayoutAll = {
  MLG,
}
const DevMyLayout = {
  init: () => {
    console.log("init dev mylayout")
    for (let k of Object.keys(MyLayoutAll)) {
      let _lib = MyLayoutAll[k]
      if (_lib && _lib.initPageDev) {
        _lib.initPageDev()
      }
    }
  },
}

export default DevMyLayout
