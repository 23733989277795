import React from "react"
import { MyFormControlHelpers, CSuppense } from "@macashipo/mlib"

const V1_mfc_mycontrol = React.lazy(
  () => import("src/core/modules/v1page/MyForm/Types/v1_mfc_mycontrol")
)

const ListControl = {
  input_currency: "input_currency",
  input_percentage: "input_percentage",
  input_text: "input_text",
  input_number: "input_number",
  group_controls: "group_controls",
  input_date: "input_date",
  input_date_string: "input_date_string",
  input_address: "input_address",
  checkbox_custom: "checkbox_custom",
  readonly_text: "readonly_text",
  button: "button",
  check_inbox: "check_inbox",
  check_yes_no: "check_yes_no",
  check_option_radio: "check_option_radio",
  v1_mfc_select2: "v1_mfc_select2",
  select2_icon_option: "select2_icon_option",
  link: "link",
  input_textarea: "input_textarea",
  readonly_html2: "readonly_html2",
  input_textarea_autosize: "input_textarea_autosize",
  mc_files: "mc_files",
}
const MyFormControlSMSFOne = {
  init() {
    for (let k of Object.keys(ListControl)) {
      MyFormControlHelpers.addTypes(k, props => {
        return CSuppense({ ...props, typeControl: k }, V1_mfc_mycontrol)
      })
    }
  },
}

export default MyFormControlSMSFOne
