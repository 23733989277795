import { apiPostForm, apiPost } from "src/core/helpers/api"
import * as actions from "./Login.actions"
import { HCF } from "@macashipo/mlib"
// import { HUtils, HConfig } from "src/core/helpers"
export const login = (payload: any) => dispatch => {
  dispatch(actions.fetchLoginPending())
  return apiPostForm({
    url: "/oauth2/token",
    data: {
      grant_type: "password",
      username: payload.username,
      password: payload.password,
      app_name: HCF.getCF("appName"),
    },
  })
    .then(response => {
      return dispatch(actions.fetchLoginSuccess(response))
    })
    .catch(err => {
      return Promise.reject(dispatch(actions.fetchLoginError(err)))
    })
}

export const loginSocial =
  (payload: any = {}) =>
  dispatch => {
    dispatch(actions.fetchLoginPending())
    return apiPost({
      url: "Account/SocialLogin",
      data: {
        SocialId: payload.SocialId || "nosocialid",
        SocialToken: payload.SocialToken,
        SocialUsername: payload.SocialUsername,
      },
    })
      .then(response => {
        console.warn("login social:", response)
        if (
          response.Data &&
          response.Data.Token
          // && response.Data.Token.access_token
        ) {
          return dispatch(actions.fetchLoginSuccess(response.Data.Token))
        }
      })
      .catch(err => {
        return Promise.reject(dispatch(actions.fetchLoginError(err)))
      })
  }

export const loginSuccessWithResponse = (response: any) => dispatch => {
  dispatch(actions.fetchLoginSuccess(response))
}
