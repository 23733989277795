import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"

const MUICard_Task = React.lazy(() => import("./card_task"))
const MUINewDailyTask = React.lazy(() => import("./prj_dailytask"))
const MUINewProject = React.lazy(() => import("./prj_new_project"))
const MUITaskItem = React.lazy(() => import("./prj_task_item"))
const MUINewTask = React.lazy(() => import("./prj_new_task"))
const MUIUserSetting = React.lazy(() => import("./prj_task_user_setting"))
const MUIProjectItem = React.lazy(() => import("./prj_pj_item"))
const MUIAPITestCase = React.lazy(() => import("./prj_api_testcases"))
const MUIAPISetting = React.lazy(() => import("./prj_api_setting"))
const MUINewRelease = React.lazy(() => import("./prj_new_release"))
const MUISET = React.lazy(() => import("./prj_set"))
const List = {
  card_task: MUICard_Task,
  prj_dailytask: MUINewDailyTask,
  prj_new_project: MUINewProject,
  prj_task_item: MUITaskItem,
  prj_new_task: MUINewTask,
  prj_new_release: MUINewRelease,
  prj_task_user_setting: MUIUserSetting,
  prj_pj_item: MUIProjectItem,
  prj_api_testcases: MUIAPITestCase,
  prj_api_setting: MUIAPISetting,
  prj_set: MUISET,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
  },
}

export default MyUIOne
