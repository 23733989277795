import MyIconHelpers from "src/core/components/MyIcon/MyIconHelpers"
import * as IconHi from "react-icons/hi"
const MyTableCellOne = {
  init() {
    console.warn("init icon:")
    for (let k of Object.keys(IconHi)) {
      MyIconHelpers.addTypes(k.toLocaleLowerCase(), IconHi[k])
    }
    // import("react-icons/hi").then(v => {
    //   console.warn("import:", v)
    //   if (v) {
    //     let _keys = Object.keys(v)
    //     for (let k of _keys) {
    //       MyIconHelpers.addTypes(k, v[k])
    //     }
    //   }
    // })
  },
}

export default MyTableCellOne
