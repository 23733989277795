import { HCF } from "@macashipo/mlib"

export const sendWebhook = ({ webhookURL, msg } = {}) => {
  if (webhookURL && msg) {
    const data = JSON.stringify({
      text: msg,
    })
    let resp
    fetch(webhookURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      body: data,
    }).then(response => {
      resp = response
      console.log(response)
    })
    return resp
  }
}

export const sendGGNotifyLog = ({ webhookURL, msg, force } = {}) => {
  let _webHook = webhookURL || HCF.getCF("GGNotifyWebHook")
  if (force !== true && window.location.href.indexOf("localhost") > -1) {
    return null
  }
  if (_webHook) {
    let _msg = `From: ${window.location.href}\n` + "```" + msg + "```"
    sendWebhook({
      webhookURL: _webHook,
      msg: _msg,
    })
  }
}
